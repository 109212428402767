
import React from 'react';
import './comment.css';
const Comment = ({  comment,
    onReply,
    onEdit,
    onSaveEdit,
    onCancelEdit,
    isReplying,
    replyText,
    isEditing,
    editedText, }) => {
  const { id, user,user_name, created, text} = comment;
  const dateObject = new Date(created).toLocaleString();
  
console.log("jfkjs",comment);
  return (
    <div className="comment-item">
      <div className="comment-header">
        <span className="user-info">{user_name} - {dateObject}</span>
        <p className="comment-text">{comment.comment}</p>
        <div className="comment-actions">
        {/* <button onClick={() => onReply(id)}>Reply</button>
         <button className='comment-button' onClick={() => onEdit(id)}>Edit</button> */}
          {/* <button onClick={() => onDelete(id)}>Delete</button> */}
          {/* {isReplying === id  && (
        <div>
          <textarea
            placeholder='Write your reply...'
            value={replyText}
            onChange={(e) => onReply(e.target.value)}
          />
           <button onClick={() => onReply(id)}>Post Reply</button>
        </div>
      )} */}

   {/* Edit input box */}
      {/* {isEditing === id  && (
        <div>
          <textarea
            value={editedText}
            onChange={(e) => onSaveEdit(e.target.value)}
          />
           <button onClick={() => onSaveEdit(id)}>Save Edit</button>
          <button onClick={onCancelEdit}>Cancel Edit</button>
        </div>
      )} */}
    
        </div>
      </div> 
    </div>
  );
};

export default Comment;
