import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import Table from "react-bootstrap/Table";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faPlus,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import "./requirement-list.css";
import { AddCompanyModal } from "../add-company-modal";
import { AddRequirementModal } from "../action-model/AddRequirementModal";
import Sidebar from "../../sidebar/Sidebar";
import useSession from "../../session/useSession";
import AddIcon from "../../assets/AddIcon.svg"
import LeftArrow from "../../assets/LeftArrow.svg"
import RightArrow from "../../assets/RightArrow.svg"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";



export const RequirementList = () => {
  useSession();
  const [modalShow, setModalShow] = useState(false);
  const [actionModalShow, setActionModalShow] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const jsonData = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [endIndex, setEndIndex] = useState(0);
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState({});
  const itemsPerPage = 10;

  const history = useHistory();
  const location=useLocation();
  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false, 
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: false,
    openRequirements: true,
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if(isMounted) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/marketing/lead/`;
      if (jsonData?.result.token) {
        axios
          .get(apiUrl, {
            params: {
              page : currentPage,
              page_size : itemsPerPage,
              start_date:location.state?.start_date,
              end_date:location.state?.end_date
            },
            headers: {
              Authorization: `Token ${jsonData?.result.token}`,
            },
          })
          .then((response) => {
            setLeads(response.data.results); 
            setEndIndex(response.data.total_count);
          })
          .catch((error) => console.error("Error fetching lead data:", error));
      }
    }
  }, [jsonData, currentPage, isMounted, actionModalShow]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleActionClick = (item) => {
    setSelectedLead(item);
    setActionModalShow(true);
  };
  const handleEditClick = (item) => {
    const lead_id=item.id;
    history.push(`/requirements/LeadUpdation/${lead_id}`);
  };

  const handleAddRequirement = () => {
    history.push("/requirements/create-lead");
  };
  return (
    <div className="requirements-pagee bg-neutral-900">
      <div className="sidebar-requirements">
        <Sidebar set_map={set_map} />
      </div>
      <div className="container">
      <header className="flex flex-col font-bold tracking-widest uppercase bg-neutral-900 text-zinc-100 max-w-full pl-5">
                <h1 className="z-10 justify-center items-start px-4 py-2 bg-indigo-950 max-w-full text-white mt-4">
                  Requirements
                </h1>
              </header>
        <div
          className="d-flex justify-content-between align-items-center ml-7 my-2"
          style={{ fontFamily: "Signika Negative" }}
        >
          <div className="d-flex gap-3">
            <button 
            type="button"
            className="flex gap-1 px-4 py-2.5 text-white rounded-xl bg-indigo-950"
            onClick={handleAddRequirement}
            style={{ width: "200px" }}
            disabled={!jsonData?.result.roles.some(role => ["admin", "leads", "superadmin", "marketer"].includes(role))}>
              <img className="px-2" src={AddIcon} />
              <span>Add Requirement</span>
            </button>
            <button 
            type="button"
            className="flex gap-1 px-4 py-2.5 text-white rounded-xl bg-indigo-950"
            onClick={() => setModalShow(true)}
            style={{ width: "190px" }}
            disabled={!jsonData?.result.roles.some(role => ["admin", "leads", "superadmin"].includes(role))}>
            <img className="px-2" src={AddIcon} />
              <span>Add Company</span>
            </button>
            <AddCompanyModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
        <div className="logstable bg-neutral-900 font-bold my-3">
        <p className="requirements-warning">
          <span className="mt-4">
            *
          </span>
           <span>
            Click on job title to take action
            </span>
        </p>
        <Table borderless="1" className="text-white bg-opacity-95 borderprob"> 
          <thead className="bg-indigo-500">
            <tr>
              <th>Job Title</th>
              <th>Location</th>
              <th>Company</th>
              <th>Status</th>
              <th>Submission</th>
              <th>Action</th>
              {/* <th>Edit</th> */}
            </tr>
          </thead>
          <tbody className="bg-indigo-950">
            {leads.map((item, i) => (
              <tr key={i}>
                <td>{!(jsonData?.result.roles.some(role => ["admin", "leads", "superadmin"].includes(role)) 
                                || item.marketer === jsonData?.result.id) ? null : (
                    <div 
                      className="div3" 
                      onClick={() => handleActionClick(item)}
                    >
                      {item.job_title}
                    </div>
                  )}
                  </td>
                <td>{item.location}</td>
                <td>{item.company_name}</td>
                <td>{item.status}</td>
                <td className="px-5">{item.submission_count}</td>
                <td>
                  {!(jsonData?.result.roles.some(role => ["admin", "leads", "superadmin"].includes(role)) 
                                || item.marketer === jsonData?.result.id) ? null : (
                    <div 
                      className="editbutton" 
                      onClick={() => handleEditClick(item)}
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ color: "#fff" }}
                      />
                    </div>
                  )}
                </td>
              </tr>
              // <tr key={i}>
              //   <td>{item.job_title}</td>
              //   <td>{item.location}</td>
              //   <td>{item.company_name}</td>
              //   <td>
              //     <div className="div">{item.status}</div>
              //   </td>
              //   <td>
              //     <div className="div2">{item.submission_count}</div>
              //   </td>
              //   <td>
              //     {!(jsonData?.result.roles.some(role => ["admin", "leads", "superadmin"].includes(role)) 
              //                   || item.marketer === jsonData?.result.id) ? null : (
              //       <div 
              //         className="div3" 
              //         onClick={() => handleActionClick(item)}
              //       >
              //         <FontAwesomeIcon
              //           icon={faArrowRight}
              //           style={{ color: "#fff" }}
              //         />
              //       </div>
              //     )}
              //   </td>
              //   <td>
              //     {!(jsonData?.result.roles.some(role => ["admin", "leads", "superadmin"].includes(role)) 
              //                   || item.marketer === jsonData?.result.id) ? null : (
              //       <div 
              //         className="div4" 
              //         onClick={() => handleEditClick(item)}
              //       >
              //         <FontAwesomeIcon
              //           icon={faEdit}
              //           style={{ color: "#fff" }}
              //         />
              //       </div>
              //     )}
              //   </td>
              // </tr>
            ))}
          </tbody>
        </Table>
        </div>
        <AddRequirementModal
          show={actionModalShow}
          onHide={() => setActionModalShow(false)}
          lead = {selectedLead}
        />
        
        {/* <div className="lead_pagination">
          <button className="paginationbuttons"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
          <img
          src={LeftArrow}
          alt="<"
          className="h-5"/>
          </button>
          <span className="text-white spanss"><b>{currentPage}</b></span>
          <button className="paginationbuttons"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={endIndex <= (itemsPerPage * (currentPage-1)) + leads.length}
          >
          <img
          src={RightArrow}
          alt=">"
          className="h-5"
        />
          </button>
        </div> */}
        <div className="flex flex-row justify-end items-center space-x-2 mb-4">
              <button
                className="bg-[#171111] text-white hover:bg-[#171111] disabled:bg-[#171111] text-xl"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
            <img
              src={LeftArrow}
              alt=">"
              className="h-5"
            />              
        </button>
              <span>
                <p className="text-white text-2xl pt-2">{currentPage}</p>
              </span>
              <button
                className="bg-[#171111] hover:bg-[#171111] disabled:bg-[#171111] text-white text-xl"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={endIndex <= (itemsPerPage * (currentPage - 1)) + leads.length}
              >
              <img
                  src={RightArrow}
                  alt=">"
                  className="h-5"/>
         </button>
            </div>
      </div>
    </div>
    
  );
  
};
export default RequirementList;

