import React, { useState, useEffect, } from 'react';
import './SubmissionUpdation.css';
import Sidebar from '../sidebar/Sidebar';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FlashMessage } from '../flashmessage/FlashMessage';
import useSession from '../session/useSession';

const SubmissionUpdation = () => {
    useSession();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sub_id = searchParams?.get('sub_id');
    const phone = searchParams?.get('phone');
    const email = searchParams?.get('email');
    const client = searchParams?.get('client');
    const rate = searchParams?.get('rate');
    const vnum=searchParams?.get('vcontact')
    const vname=searchParams?.get('vname')
    const vemail=searchParams?.get('vemail')
    const docs=searchParams?.get('docs')
    const comments = searchParams?.get('comments');
    const [flashMessageType, setFlashMessageType] = useState(null);
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [clienterror, setclientError] = useState("");
    const [rateerror, setrateError] = useState("");
    const [flashMessage, setFlashMessage] = useState(null);
    const [updateDisable, setupdateDisable] = useState(false);
    const [generalError, setGeneralError] = useState("");

    const [formData, setFormData] = useState({
        marketing_email: email,
        marketing_phone: phone,
        client: client,
        rate: rate,
        vnum: vnum,
        vname,
        vemail,
        docs,
        comments: comments,
    });
    const displayFlashMessage = (message, type) => {
        setFlashMessage(message);
        setFlashMessageType(type);
        setTimeout(() => {
            setFlashMessage(null);
            setFlashMessageType(null);
        }, 1000);
    };
    const set_map = {
        showDashboard: false,
        showCandidate: false,
        showMarketing: true,
        showResume: false,
        openMy: false,
        openGlobal: false,
        openLogs: false,
        openAdmin: false,
        openCandidateTable: false,
        openSubmission: true,
        openInterview: false,
        openTest: false,
        openRequirements: false,
    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
        switch (name) {
            case 'marketing_email':
                setEmailError("");
                break;
            default:
                break;
        }
        setGeneralError("");
     
    };
    const handlePhoneChange = (e) => {
        const enteredPhoneNumber = e.target.value;
        const { name, value } = e.target;
        const cleanedPhoneNumber = enteredPhoneNumber.replace(/\D/g, '');
        const formattedPhoneNumber = formatPhoneNumber(cleanedPhoneNumber);
        setFormData({
            ...formData,
            [name]: formattedPhoneNumber,
        });
        if (!isValidPhoneNumber(cleanedPhoneNumber)) {
          setPhoneError("Invalid phone number.");
        } else {
          setPhoneError("");
        }
      };
      
      const formatPhoneNumber = (phoneNumber) => {
        const country = phoneNumber.slice(0, 3);
        const firstPart = phoneNumber.slice(3, 6);
        const secondPart = phoneNumber.slice(6, 10);
      
        return `(${country})-${firstPart}-${secondPart}`;
      };
    const jsonData = useSelector((state) => state.user);
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        setIsMounted(true); 
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;
        const enteredPhoneNumber = formData.marketing_phone;
        const vendor_contact=formData.vnum
        if (enteredPhoneNumber === ''|| vendor_contact==='') {
            isValid = false;
            setPhoneError('*required');
        } 
        const enteredEmail = formData.marketing_email;
        const vendoremail=formData.vemail
        if (enteredEmail === ''|| vendoremail===''){
            isValid = false;
            setEmailError('*required')
        }
        else if (!isValidEmail(enteredEmail)) {
            setEmailError("Invalid email address.");
        }else if(!isValidEmail(vendoremail)){
            setEmailError("Invalid email address.");
        }else if(!isValidEmail(vendoremail)){
            setEmailError("Invalid email address.");
        } else if(!isValidEmail(vendoremail)) {
            setEmailError("Invalid email address.");
        }else{
            setEmailError("");
        }
     
        if (!isValid) {
            setGeneralError("Please enter the details in correct format .");
            return;
        }
        setGeneralError("");
        const apiUrlUsers = `${process.env.REACT_APP_API_URL}/marketing/submission/${sub_id}/`;
        axios.put(apiUrlUsers, {
            marketing_email: formData.marketing_email,
            marketing_phone: formData.marketing_phone,
            client: formData.client,
            rate: formData.rate,
            vendor_number:formData.vnum,
            vendor_email:formData.vemail,
            vendor_name:formData.vname,
            documents_shared:formData.docs,
            comments: formData.comments,
        }, {
            headers: {
                Authorization: `Token ${jsonData?.result.token}`,
            }
        }).then(response => {
            displayFlashMessage('Submission Updated Successfully', 'success');
            setTimeout(() => {
                const id = sub_id;
                history.push(`/SubmissionUpdation/submission/${id}`);
            }, 1000);
        }).catch(error => {
            console.error('Error fetching data:', error);
        });
    };
    const isValidPhoneNumber = (phoneNumber) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phoneNumber);
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    
    return (
        <div className="submission-update-page">
            <div className="sidebar-submissionupdate">
                <Sidebar set_map={set_map} />
            </div>
            <div className="justify-center items-start px-3 py-2 text-2xl font-bold tracking-widest leading-9 uppercase whitespace-nowrap color rounded-l-[43px] text-zinc-100 max-md:px-5 mt-5 mb-4  ml-[23%] ">
                Submission Details
                </div>
            <div className="submissionupdate-container">
                {flashMessage && (
                    <FlashMessage message={flashMessage} type={flashMessageType} />
                )}
                {generalError && (
                    <div
                        style={{ color: "red", fontSize: "14px" }}
                        className="error-message"
                    >
                        {generalError}
                    </div>
                )}
                
                
                
                <form onSubmit={handleSubmit}>
                    <div className="form-grid">
                        <div className="form-group">
                            <label>Marketing Email:</label>
                            <input type="text" name="marketing_email" value={formData.marketing_email} onChange={handleChange} />

                            {emailError && (
                                <div
                                    style={{ color: "red", fontSize: "14px" }}
                                    className="error-message"
                                >
                                    {emailError}
                                </div>
                            )}
                        </div>

                        <div className="form-group">
                            <label>Marketing Phone:</label>
                            <input type="text" name="marketing_phone" value={formData.marketing_phone}  onChange={handlePhoneChange}
                                    placeholder="(XXX)-XXX-XXXX" />
                            {phoneError && (
                                <div
                                    style={{ color: "red", fontSize: "14px" }}
                                    className="error-message"
                                >
                                    {phoneError}
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label>Client:</label>
                            <input type="text" name="client" value={formData.client} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Rate:</label>
                            <input type="text" name="rate" value={formData.rate} onChange={handleChange} />

                        </div>
                        <div className="form-group">
                            <label>Documents Shared:</label>
                            <input type="text" name="docs" value={formData.docs} onChange={handleChange} />

                        </div>
                        <div className="form-group">
                            <label>Vendor Phone:</label>
                            <input type="text" name="vnum" value={formData.vnum} onChange={handlePhoneChange} />
                            {phoneError && (
                                <div
                                    style={{ color: "red", fontSize: "14px" }}
                                    className="error-message"
                                >
                                    {phoneError}
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label>Vendor Email:</label>
                            <input type="text" name="vemail" value={formData.vemail} onChange={handleChange} />
                            {emailError && (
                                <div
                                    style={{ color: "red", fontSize: "14px" }}
                                    className="error-message"
                                >
                                    {emailError}
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label>Vendor Name:</label>
                            <input type="text" name="vname" value={formData.vname} onChange={handleChange} />

                        </div>
                        <div className="form-group">
                            <label>Comments:</label>
                            <input type="text" name="comments" value={formData.comments} onChange={handleChange} />

                        </div>
                    </div>
                    <button type="submit" className="Submission-Updation-button" disabled={updateDisable}>Update</button>
                    
                </form>
            </div>
        </div>
    );
};
export default SubmissionUpdation;
