// screeningDetailsPage.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './ScreeningDetails.css';
import useSession from "../session/useSession";
import Sidebar from '../sidebar/Sidebar';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';

const ScreeningDetailsPage = () => {
  useSession();
  const [screeningDetails, setScreeningDetails] = useState(null);
  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const { id } = useParams();
  const history = useHistory()
  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: false,
    openRequirements: false,
    openScreening: true,

  };
  const formatTime = (timeString) => {
    return timeString.replace('T', ' ').replace('Z', '');
  };
  const handleScreeningUpdate = (id) => {
    history.push(`/screening/${id}`);
  }


  useEffect(() => {
    setIsMounted(true);
  }, []);
  useEffect(() => {

    if (isMounted && jsonData) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/marketing/screenings/${id}`
      axios.get(apiUrl, {
        // Include necessary headers
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        },
      })
        .then(response => {
        console.log(response)
          setScreeningDetails(response.data);
        })
        .catch(error => {
          console.error('Error fetching interview details:', error);
        });
    }
  }, [id, jsonData, isMounted]);

  return (
    <div className="screening-details-container bg-[#171111]">
      <div>
        <Sidebar set_map={set_map} />
      </div>
      {screeningDetails ? (
        <div className='py-10'>
          <div className="bg-[#292256] rounded-l-full ml-10">
            <h1 className="text-2xl text-white font-semibold pl-6 py-2">SCREENING DETAILS</h1>
          </div>
          <div className='mx-10 mt-10 gap-10 grid grid-cols-1'>
            <div className="details-row justify-center space-x-20  px-4 border-[#292256]">
              <span className="field-name">JOB TITLE:</span>
              <span className="field-value">{screeningDetails?.job_title}</span>
            </div>
            <div className="details-row justify-center space-x-20  px-4 border-[#292256]">
              <span className="field-name ">Screening Type:</span>
              <span className="field-value">{screeningDetails?.screening_type}</span>
            </div>
            <div className="details-row justify-center space-x-20 pl-6 border-[#292256]">
              <span className="field-name">Screening TIME:</span>
              <span className="field-value">{formatTime(screeningDetails.start_time)+" EST"}</span>
            </div>
            <div className="details-row justify-center space-x-20  px-4 border-[#292256]">
              <span className="field-name">STATUS:</span>
              <span className="field-value">{screeningDetails?.status}</span>
            </div>
            <div className="details-row justify-center space-x-20 px-4 border-[#292256]">
              <span className="field-name">GUESTS:</span>
              <span className="field-value">{screeningDetails?.guest}</span>
            </div>
            <div className="details-row justify-center space-x-20  px-4 border-[#292256]">
              <span className="field-name">Calling Details:</span>
              <span className="field-value">{screeningDetails?.calling_details}</span>
            </div>
            <div className="details-row justify-center space-x-20  px-4 border-[#292256]">
              <span className="field-name">Additional Details:</span>
              <span className="field-value">{screeningDetails?.additional_details}</span>
            </div>
            <div className="details-row justify-center space-x-20  px-4 border-[#292256]">
              <span className="field-name">Feedback Remark:</span>
              <span className="field-value">{screeningDetails?.feedback_remark}</span>
            </div>
            <button className="edit-button ml-20 mb-4 bg-[#b4a4f5] text-slate-800 font-medium hover:bg-[#6c48fd] hover:text-white" disabled={!(jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role))
                      || screeningDetails?.marketer_id === jsonData?.result.id)} onClick={() => handleScreeningUpdate(screeningDetails.id)}>
                      Edit
            </button>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )
      }
    </div >
  );
};


export default ScreeningDetailsPage;
