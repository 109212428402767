import React, { useState } from 'react'
import "./DashBoard.css"
import submissionIcon from "../assets/SubmissionIcon.svg"
import interviewIcon from "../assets/InterviewIcon.svg"
import devBenchIcon from "../assets/DevBenchIcon.svg"
import OfferIcon from "../assets/OfferIcon.svg"
import BABenchIcon from "../assets/BABenchIcon.svg"
import projectIcon from "../assets/OnProjectIcon.svg"
import testIcon from "../assets/Test.svg"
import leadIcon from "../assets/Lead.svg"
import consultantIcon from "../assets/Consultant.svg"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { FlashMessage } from '../flashmessage/FlashMessage';
const CardIcon = ({backgroundColorforIcon,type,submission_count,interview_count,babenchCount,devbenchCount,testCount,leadCount,recuritmentCount,totalInterviews,totalTests,totalLeads
,start_date,end_date,scheduledInterview_count
}) => {

  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const history = useHistory();
  // console.log(totalSubmissions,totalSubmissions?.length===0);
  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000);
  };
  const handleSubmissionCardClick = () => {
    history.push({pathname:"/submissions",state:{start_date:start_date,end_date:end_date}});
  };

  const handleInterviewCardClick = () => {
    history.push({pathname:"/interviews",state:{start_date:start_date,end_date:end_date}})
  };

  const handleScheduledInterviewCardClick = () => {
    history.push({pathname:"/interviews",state:{start_date:start_date,end_date:end_date,scheduled:true}})
  };

  const handleTestCardClick = () => {
    history.push({pathname:"/tests",state:{start_date:start_date,end_date:end_date}})
  };
  const handleLeadsCardClick = () => {
    history.push({pathname:"/requirements",state:{start_date:start_date,end_date:end_date}})
  };

    const cardData = [
        {
          title: "SUBMISSION",
          icon: <img src={submissionIcon} className="card-icon" />,
          value: submission_count,
          className: "card-innermaincards",
          color: "#292256",
        },
        {
          title: "INTERVIEW",
          icon: <img src={interviewIcon} className="card-icon" />,
          value: interview_count,
          className: "card-innermaincards",
          color: "#292256",
        },
        {
          title: "INTERVIEWS ⌚",
          icon: <img src={OfferIcon} className="card-icon" />,
          value: scheduledInterview_count,
          className: "card-innermaincards",
          color: "#292256",
        },
        {
          title: "BA BENCH",
          icon: <img src={BABenchIcon} alt="" className="card-icon" />,
          value: babenchCount,
          className: "card-innermaincards",
          color: "#292256",
        },
        {
          title: "DEV BENCH",
          icon: <img src={devBenchIcon} alt="" srcset="" className="card-icon"/>,
          value: devbenchCount,
          className: "card-innermaincards",
          color: "#292256",
        },
        {
          title: "ON PROJECT",
          icon: <img src={projectIcon} className="card-icon" alt="" />,
          value: 0,
          className: "card-innermaincards",
          color: "#292256",
        },
      ];
      const cardData2 = [
        {
          title: "SUBMISSION",
          icon: <img src={submissionIcon} className="card-icon" />,
          value: submission_count,
          className: "card-innermaincards",
          color: "#292256",
        },
        {
          title: "INTERVIEW",
          icon: <img src={interviewIcon} className="card-icon" />,
          value: interview_count,
          className: "card-innermaincards",
          color: "#292256",
        },{
            title: "TEST",
          icon: <img src={testIcon} className="card-icon" alt="" />,
          value: testCount,
          className: "card-innermaincards",
          color: "#292256",
        },{
            title: "LEAD",
            icon: <img src={leadIcon} className="card-icon" alt="" />,
            value: leadCount,
            className: "card-innermaincards",
            color: "#292256",
        },
        {
            title: "CONSULTANT",
            icon: <img src={consultantIcon} className="card-icon" alt="" />,
            value: recuritmentCount,
            className: "card-innermaincards",
            color: "#292256",
        }
      ];
  return (
    <div className="main-cards">
      {flashMessage && (
          <FlashMessage message={flashMessage} type={flashMessageType} />
        )}
            {type=="Admin" ?cardData2?.map((card, index) => (
              <div
                key={index}
                className={`cardofmain-cards ${backgroundColorforIcon}`}
                style={{cursor:"pointer"}}
                onClick={card.title === "SUBMISSION" ? handleSubmissionCardClick : card.title === "INTERVIEW" 
                ? handleInterviewCardClick :card.title === "TEST" ? handleTestCardClick :
                card.title=="LEAD" ? handleLeadsCardClick : undefined
              }
              >
                <div className={card.className}>
                  <div> {card.icon}</div>
                </div>
                    <h3>{card.title}</h3>
                <h1>{card.value}</h1>
              </div>
            ))
            :
         cardData?.map((card, index) => (
              <div
                key={index}
                className={`cardofmain-cards ${backgroundColorforIcon}`}
                // style={{ backgroundColor: backgroundColorforIcon }}
                style={{cursor:"pointer"}}
                onClick={card.title === "SUBMISSION" ? handleSubmissionCardClick : card.title === "INTERVIEW" ? handleInterviewCardClick
                  :card.title=="INTERVIEWS ⌚"? handleScheduledInterviewCardClick  : undefined}
              >
                <div className={card.className}>
                  <div> {card.icon}</div>
                </div>
                    <h3>{card.title}</h3>
                <h1>{card.value}</h1>
              </div>
            ))}
          </div>
  )
}

export default CardIcon