import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BiCaretDown } from "react-icons/bi";
import { FcManager, FcBusinessman } from "react-icons/fc";
import { BsCurrencyDollar, BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import './CandidateDetailsPage.css';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useSession from '../session/useSession';
import Sidebar from '../sidebar/Sidebar';
import consultantInfoImg from "../assets/Consultant Info.svg"
import marketerImg from "../assets/Marketer.svg"
import domainImg from "../assets/Client.svg"
import rateImg from "../assets/Rate.svg"
import searchIcon from "../assets/SearchIcon.svg"
import RightArrow from "../assets/RightArrow.svg"
import LeftArrow from "../assets/LeftArrow.svg"

export const CandidateDetailsPage = () => {
  useSession();
  const [selectedValue, setSelectedValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isVisaStatusDropdownOpen, setIsVisaStatusDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const itemsPerPage = 10;
  const [endIndex, setEndIndex] = useState(0);
  const [candidateCards, setcandidateCards] = useState([]);
  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selVal,setselVal]=useState('');
  const [statusValue,setStatusValue]=useState("");
  const [selectedStatus,setSelectedStatus]=useState("");
  const [selectedVisaStatus,setVisaStatus] = useState("");
  const [visaStatus,setVisa] = useState("");
  const set_map = {
    showDashboard: false, showCandidate: true, showMarketing: false, showResume: false, openMy: false, openGlobal: false, openAdmin: false,
    openLogs: false, openCandidateTable: true,openAddCandidate: false, openSubmission: false, openInterview: false, openTest: false, openRequirements: false
  };

  const options = ['created', 'modified'];
  const status_options=[
    {id: "in_marketing",value:"In Marketing"},
    { id: "on_project", value: "On Project" },
    { id: "dev_bench", value: "DEV Bench" },
    { id: "ba_bench", value: "BA Bench" },
    { id: "training", value: "Training" }
  ]
  const visa_options = [
    { id: 'US_Citizen', value: "US Citizen" },
    { id: 'green_card', value: "Green Card" },
    { id: 'h1b', value: "H1B" },
    { id: 'opt', value: "OPT" },
    { id: 'opt_stem', value: "OPT Stem" },
    { id: 'cpt', value: "CPT" },
    { id: 'gc_ead' , value: "GC EAD"},
    { id: 'h4_ead', value: "H4 EAD"},
  ]

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlConsultants =`${process.env.REACT_APP_API_URL}/candidate/candidate/` ;
      axios.get(apiUrlConsultants, {
        params: {
          page: currentPage,
          page_size: itemsPerPage,
          search: searchTerm,
          ordering: selVal|| '-created',
          statusVal: selectedStatus,
          visaStatus: visaStatus,
        },
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        setcandidateCards(response.data.results);
        setEndIndex(response.data.total_count);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [currentPage, searchTerm,selVal,selectedStatus,visaStatus, jsonData, isMounted]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleStatusOptionClick = (option) => {
    setStatusValue(option.value);
    setIsStatusDropdownOpen(false);
    setSelectedStatus(option.id);
  };
  const handleVisaStatusOptionClick = (option) => {
    setVisaStatus(option.value);
    setIsVisaStatusDropdownOpen(false);
    setVisa(option.id);
  };
  const clearFilter = () => {
    setSelectedStatus("");
    setSelectedValue("");
    setIsStatusDropdownOpen(false);
    setIsDropdownOpen(false);
    setIsVisaStatusDropdownOpen(false);
    setStatusValue("");
    setSearchTerm("");
    setVisa("");
    setVisaStatus("");
    setCurrentPage(1);
  };
  const handleOptionClick = (option) => {
    console.log(option);
    setselVal(`-${option.toLowerCase()}`);
    setSelectedValue(option);
    setIsDropdownOpen(false);
    setVisaStatus("");
    setStatusValue("");
    
    if (isMounted) {
      const apiUrlConsultants = `${process.env.REACT_APP_API_URL}/candidate/candidate/`;
      axios.get(apiUrlConsultants, {
        params: {
          page: currentPage,
          page_size: itemsPerPage,
          search: searchTerm,
          ordering: selVal|| '-created', 
        },
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        const filteredResults = response.data.results.filter(candidateCard => candidateCard.profiles[0]?.location !== '');
        setcandidateCards(filteredResults);
        setEndIndex(response.data.total_count);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  const handleAddCandidate = () => {
    history.push('/detailscandidate/addcandidate');
  }

  const handleCandidateProfilePage = (id) => {
    history.push(`/detailscandidate/profilecandidate/${id}`);
  }
  
  return (
    <div className="candidatedetails-page">
      <div className="sidebar-candidatedetails">
        <Sidebar set_map={set_map} />
      </div>
      <div className="candidate-details-page-container">
        <div className="candidate-details-page-header">
          <div className="heading-container-ofpage">
            <div className='header-in-header'>
              <h2 className='d-flex gap-2 align-items-center'><i className="submissions-icon"><BsReverseLayoutTextWindowReverse /></i> CANDIDATE TABLE </h2>
            </div>
            <div>
            </div>
          </div>
          {/* <button 
            onClick={handleAddCandidate} 
            className='ADD-CANDIDATE-BUTTON'
            disabled={!jsonData?.result.roles.some(role => ["admin", "recruiter", "superadmin"].includes(role))}
          >
            Add Candidate
          </button> */}
          
        </div>
        <div className='d-flex gap-2 ms-4 align-items-center'>
        <div className="candidate-details-page-search-box">
          <img src={searchIcon} className='ps-2' alt="" />
            <input
              type="text"
              placeholder="Search Candidate"
              className="candidate-details-page-search-input"
              value={searchTerm}
              onChange={(e) => 
                {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);}
              }
            />
          </div>
          <div className="Container-c">
              <button
                className="button-in-container"
                onClick={clearFilter}
                style={{ marginTop: "0px" }}
              >
                Clear
              </button>
            </div> 
                        <div className="candidate-details-page-container2">
            {/* <p className="candidate-details-page-sort-by-text">Sort By</p> */}
            <div className="candidate-details-page-dropdown-input">
              <button className={` ${selVal=="-created" ? 'click' : ''}`}  onClick={() => handleOptionClick("created")}>
                      Created
                    </button>

                    <button className={` ${selVal=="-modified" ? 'click' : ''}`} onClick={() => handleOptionClick("modified")}>
                      Modified
                    </button>
            </div>
            
          </div>

            <div className="filter-for-side">
        <div className="candidate-details-page-dropdown-input2">
              <input
                type="text"
                placeholder="Status"
                value={statusValue}
                readOnly
                onClick={() => setIsStatusDropdownOpen(!isStatusDropdownOpen)}
                className="candidate-details-page-text"
              />
              <span
                className={`dropdown-icon1 ${isStatusDropdownOpen ? 'open' : ''}`}
                onClick={() => setIsStatusDropdownOpen(!isStatusDropdownOpen)}
              >
                <BiCaretDown />
              </span>
              {isStatusDropdownOpen && (
                <ul className="dropdown-list1">
                  {status_options.map((option) => (
                    <li key={option.id} onClick={() => 
                      {
                        setCurrentPage(1);
                        handleStatusOptionClick(option)
                      }
                    }>
                      {option.value}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="candidate-details-page-dropdown-input2">
              <input
                type="text"
                placeholder="Visa"
                value={selectedVisaStatus}
                readOnly
                onClick={() => setIsVisaStatusDropdownOpen(!isVisaStatusDropdownOpen)}
                className="candidate-details-page-text"
              />
              <span
                className={`dropdown-icon2 ${isVisaStatusDropdownOpen? 'open' : ''}`}
                onClick={() => setIsVisaStatusDropdownOpen(!isVisaStatusDropdownOpen)}
              >
                <BiCaretDown />
              </span>
              {isVisaStatusDropdownOpen && (
                <ul className="dropdown-list1">
                  {visa_options.map((option) => (
                    <li key={option.id} onClick={() => 
                      {
                        setCurrentPage(1);
                        handleVisaStatusOptionClick(option)
                      }
                    }>
                      {option.value}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            </div>  

        </div>
        <div className="candidate-details-page-submissions-filter">
      
          <div className="candidate-details-page-submissions-card">
            <div  className="font-bold  row  mt-4  pb-0 max-md:flex-wrap "  
            >
              <div className='flex col-4 flex-row gap-2 p-2.5 justify-center'>
              <img src={consultantInfoImg} alt="" 
              className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>
              Consultant info 
              </p>
              </div>
              <div className='flex col-3 flex-row gap-2 p-2.5 justify-center'>

              <img src={marketerImg} alt="" 
              className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>
              Marketer
              </p>
              </div>
              <div className='flex  col-2 flex-row gap-2 p-2.5 justify-center'>

              <img src={domainImg} alt="" 
              className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>
              Domain
              </p>
              </div>
              <div className='flex col-1 flex-row gap-2 p-2.5 justify-center'>

              <img src={rateImg} alt="" 
              className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>
              Rate
              </p>
              </div>
            </div>
            <ul>
              {candidateCards.map((candidateCard) => (
                <li key={candidateCard.id} className="candidate-details-page-submissions-box row  divide-x divide-white-600 p-2 " onClick={() => handleCandidateProfilePage(candidateCard.id)}>
                  <div className="col-4 candidate-details-page-consultant-marketer-box">
                    <div>
                    <span className="candidate-details-page-consultant-box">
                      <i title="Consultant"><FcManager /></i>&nbsp;{candidateCard?.name}
                    </span>
                    <span className="candidate-details-page-client-box" >
                    <i title="Current Location"><FontAwesomeIcon icon={faMapMarkerAlt} className="location-icon" /></i>&nbsp;
                    {candidateCard.profiles[0]?.location}
                    </span>
                    </div>
                  {/* </div>
                  <div className="candidate-details-page-consultant-marketer-box"> */}
                  {/* candidate-details-page-visa-contact-box */}
                  <div>
                    <span className="candidate-details-pagetime-box">
                    <i className="time-design" title="Visa Type"> <FontAwesomeIcon icon={faCcVisa} /></i>&nbsp;
                    {candidateCard.profiles.map((profile) => (
                      <span key={profile.id}>{profile.visa_type}</span>
                    ))}
                    </span>
                    <span className="candidate-details-page-time-box">
                    <i className="time-design" title="Phone"><FontAwesomeIcon icon={faPhone} /></i>&nbsp;{candidateCard?.phone_no}
                    </span>
                    </div>
                  </div>
                  <div className="flex col-3 gap-1 justify-center items-center">
                      {candidateCard.primary_marketer?.full_name}
                  </div>
                  <div className="flex col-2 gap-1 justify-center items-center">
                    <span className="candidate-details-page-vendor-box">
                      {candidateCard?.job_domain}
                    </span>
                  </div>
                  <div className="flex col-1 gap-1 justify-center items-center">
                      {candidateCard?.rate}
                  </div>
                </li>
              ))}
            </ul>
            {/* <div className="pagination">
              <button className='button-in-CabdidateDetails'
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span><b>{currentPage}</b></span>
              <button className='button-in-CabdidateDetails'
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={endIndex <= (itemsPerPage * (currentPage-1)) + candidateCards.length}
              >
                Next
              </button>
            </div> */}
            <div className="flex flex-row justify-end items-center space-x-2 mb-4">
            <div className="flex gap-2 justify-between">
                <div className="flex flex-row justify-end items-center space-x-2 mb-4">
              <button
                className="bg-[#171111] text-white hover:bg-[#171111] disabled:bg-[#171111] text-xl"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
            <img
              src={LeftArrow}
              alt=">"
              className="h-5"
            />              
        </button>
              <span>
                <p className="text-white text-2xl pt-2">{currentPage}</p>
              </span>
              <button
                className="bg-[#171111] hover:bg-[#171111] disabled:bg-[#171111] text-white text-xl"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={endIndex <= (itemsPerPage * (currentPage - 1)) + candidateCards.length}
              >
              <img
                  src={RightArrow}
                  alt=">"
                  className="h-5"/>
         </button>
            </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}
