import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faDollarSign, faAddressBook, faBirthdayCake, faGraduationCap, faCalendar, faPhone, faCheckCircle, faIdCard, faInfoCircle, faThumbsUp, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import './CandidateProfilePage.css';
import { FcBusinessman } from "react-icons/fc";
import { FcBusinesswoman } from "react-icons/fc";
import { FaDownload, FaPlusSquare } from 'react-icons/fa';
import Sidebar from '../sidebar/Sidebar';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useSession from '../session/useSession';
import { AddCompanyModal } from "../candidate/AttachmentPopUp";
import Comment from './Comment';
import extLink from "../assets/external-link.png"
export const CandidateProfilePage = () => {
  useSession();
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [profileId, setProfileId] = useState();
  const [isMounted, setIsMounted] = useState(false);
  const [apiResponse, setApiResponse] = useState([]);
  const jsonData = useSelector((state) => state.user);
  const [attachments, setAttachments] = useState([]);
  const [education, setEducation] = useState("");
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);
  const [dob, setDob] = useState(new Date());
  const [isReplying, setIsReplying] = useState(null);
  const [isEditing, setIsEditing] = useState(null);
  const [replyText, setReplyText] = useState('');
  const [editedText, setEditedText] = useState('');
  const set_map = {
    showDashboard: false, showCandidate: true, showMarketing: false, showResume: false, openMy: false, openGlobal: false, openAdmin: false,
    openLogs: false, openCandidateTable: true, openSubmission: false, openInterview: false, openTest: false, openRequirements: false
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted && jsonData) {

      const apiUrlConsultants = `${process.env.REACT_APP_API_URL}/candidate/candidate/`;
      axios.get(apiUrlConsultants, {
        params: {
          consultant_id: id
        },
        headers: {
          Authorization: `Token ${jsonData?.result.token}`
        }
      }).then(response => {
        setApiResponse(response.data.results);
        const profiles = response.data.results.profiles;
        console.log(profiles);
        if (profiles && profiles.length > 0) {
          setEducation(profiles[0].education);
          setDob(new Date(`${profiles[0].dob} EST`));
          const profile_id = profiles[0].id;
          setProfileId(profile_id);
          
          getComments(profile_id);
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [id, jsonData, isMounted]);


  const getComments=(profile_id)=>{
    axios.get(`${process.env.REACT_APP_API_URL}/profiles/${profile_id}/comments/`, {
      headers: {
        Authorization: `Token ${jsonData?.result.token}`,
      },
    })
      .then(response => {
        setComments(response.data);
      })
      .catch(error => {
        console.error('Error fetching comments:', error);
      });
  }

  useEffect(() => {
    handleDisplayAttachments();
  }, [modalShow]);

  // const fetchComments = () => {
  //   // Make an API call to fetch comments for the current profile ID
  //   const profileId = apiResponse.profiles?.id;

  //   axios.get(`http://localhost:8000/api/profiles/${PofileId}/comments/`, {
  //     headers: {
  //       Authorization: `Token ${jsonData?.result.token}`,
  //     },
  //   })
  //   .then(response => {
  //     // setComments(response.data);
  //   })
  //   .catch(error => {
  //     console.error('Error fetching comments:', error);
  //   });
  // };
  // useEffect(() => {
  //  fetchComments();
  // }, [jsonData]);
  const handleDisplayAttachments = () => {
    const content_type = 'candidate_consultant'
    axios.get(`${process.env.REACT_APP_API_URL}/static_data/attachments/${content_type}/${id}/`, {
      headers: {
        Authorization: `Token ${jsonData?.result.token}`,
      }
    }).then(response => {
      setAttachments(response.data);
    }).catch(error => {
      console.error('Error fetching attachments:', error);
    });
  };

  const downloadFileAtURL = (filename) => {
    const baseURL = 'https://vms-attachmnts.s3.ap-south-1.amazonaws.com/';
    const url = baseURL + filename;
    const atag = document.createElement('a');
    atag.href = url;
    atag.setAttribute('download', filename);
    document.body.appendChild(atag);
    atag.click();
    atag.remove();
  }

  const iconMapping = {
    faUser: faUser,
    faEnvelope: faEnvelope,
    faPhone: faPhone,
    faCheckCircle: faCheckCircle,
    faDollarSign: faDollarSign,
    faIdCard: faIdCard,
    faInfoCircle: faInfoCircle,
    faCalendar: faCalendar,
    faGraduationCap: faGraduationCap,
    faBirthdayCake: faBirthdayCake,
    faAddressBook: faAddressBook,
  };
  const candidateDetailslist = [
    {
      value: apiResponse?.name,
      icon: 'faUser',
      field: 'Name'
    },
    {
      value: apiResponse?.email,
      icon: 'faEnvelope',
      field: 'Email'
    },
    {
      value: apiResponse?.phone_no,
      icon: 'faPhone',
      field: 'Phone Number'

    },

    {
      value: apiResponse?.address,
      icon: 'faAddressBook',
      field: 'Address'
    },
    {
      value: new Date(dob).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
      icon: 'faBirthdayCake',
      field: 'DOB'
    },
    {
      value: apiResponse?.rtg,
      icon: 'faCheckCircle',
      field: 'RTG'
    },
    {
      value: apiResponse?.ssn,
      icon: 'faIdCard',
      field: 'SSN'
    },
    {
      value: apiResponse?.gender,
      icon: 'faUser',
      field: 'Gender'
    },
    {
      value: education,
      icon: 'faGraduationCap',
      field: 'Education',
    },
    {
      value: apiResponse?.status,
      icon: 'faInfoCircle',
      field: 'Status'
    },
    {
      value: apiResponse?.rate,
      icon: 'faDollarSign',
      field: 'Rate'
    }

  ];
  console.log("this is api response", apiResponse);
  const teamAssignedList = apiResponse?.teams;
  const listItemsTeams = [
    {
      text: 'Marketer', value: apiResponse?.primary_marketer?.full_name, icon: <FcBusinessman size={40} />, showUpdateButton: true, action: () => {
      },
    },
    {
      text: 'Recruiter', value: apiResponse?.recruiter?.full_name, icon: <FcBusinesswoman size={40} />, showUpdateButton: true, action: () => {
      },
    },
    { text: 'Engineer', value: 'xyz', icon: <FcBusinessman size={40} />, showUpdateButton: false },
  ];

  const projects = [];
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 10;
  const totalPages = Math.ceil(projects.length / projectsPerPage);
  const startIndex = (currentPage - 1) * projectsPerPage;
  const endIndex = startIndex + projectsPerPage;
  const currentProjects = projects.slice(startIndex, endIndex);
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleUpdateCandidate = () => {
    const profileId = apiResponse.profiles[0]?.id;
    const id = apiResponse.id;
    const email = apiResponse.email;
    const queryParam = {
      id: id,
      profileId: profileId,
      email: email,
    }
    history.push({
      pathname: "/detailscandidate/profilecandidate/updatecandidate/",
      search: `?${new URLSearchParams(queryParam).toString()}`
    });
  }
  const handlePostComment = () => {
    console.log("profile id", profileId);
    axios.post(
      `${process.env.REACT_APP_API_URL}/profiles/${profileId}/comments/`,
      {
        comment: newComment,
        profile: profileId,
      },
      {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        },
      }
    )
      .then((response) => {
        console.log('Comment created successfully:', response.data);
        setNewComment('');
        setIsReplying(false);
        setReplyText('');
        setIsEditing(false);
        setEditedText('');
        getComments(profileId);
      })
      .catch((error) => {
        console.error('Error posting comment:', error);
      });
  };
  const handleReply = (commentId) => {
    setIsReplying(commentId);
    setReplyText('');
  };
  
  const handleEdit = (commentId, currentText) => {
    setIsEditing(commentId);
    setEditedText(currentText);
  };
  
  const handleSaveEdit = (commentId) => {
    // Add logic here to save the edited text for the specific comment (commentId)
    // For example, you can make an API call to save the edited text
    // After saving, reset the state and fetch the updated comments
    setIsEditing(null);
    setEditedText('');
  
    // Example API call:
    // axios.put(`http://localhost:8000/api/profiles/${PofileId}/comments/${commentId}/`, {
    //   text: editedText,
    // }, {
    //   headers: {
    //     Authorization: `Token ${jsonData?.result.token}`,
    //   },
    // })
    // .then(response => {
    //   // Fetch updated comments after successful edit
    //   fetchComments();
    // })
    // .catch(error => {
    //   console.error('Error editing comment:', error);
    // });
  };
  
  const handleCancelEdit = () => {
    setIsEditing(null);
    setEditedText('');
  };
  const redirectSub=()=>{
    history.push({pathname:"/submissions",state:{consultant_id:id},});
  }
  
  return (
    <div className="candidateprofile-page">
      <div className="sidebar-candidateprofile">
        <Sidebar set_map={set_map} />
      </div>
      <div className="details-page-container">
        <div className='container details-page-title'>
        <h1> Candidate Details</h1>
        </div>
        <div className="left-container">
          <div className="candidate-details-container">
            <div className="details-card">

              <ul className="details-list">
                {candidateDetailslist?.map((item) => (
                  <li key={item.value} className="details-list-item">
                    <div className="tooltip-new">
                      <FontAwesomeIcon icon={iconMapping[item.icon]} />
                      <span className="tooltiptext-new">{item.field}</span>
                    </div>
                    &nbsp;&nbsp;<span style={{ fontFamily: "'Signika Negative', 'sans-serif'", fontSize: '16px' }}>{item.value}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className='container d-flex gap-2'>
          <div className="attached-files-container">
            <div className="content-details-container">
              <h1 className="heading-style-primarassign" >
                Attached Files
              </h1>
              {!(jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role))
                || apiResponse?.recruiter?.id === jsonData?.result.id) ? null : (
                <div style={{ cursor: 'pointer' }} onClick={() => setModalShow(true)}>
                  <FaPlusSquare size={27} color="#4681f4" />
                </div>
              )}
              <AddCompanyModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                candidateId={id}
              />
            </div>
            <div className="card-primary-assigment">
              <div className="singularlistitem-filescontainer">
                <ul className="list-primary-assignment">
                  {attachments?.map((document, index) => (
                    <li key={index} className="list-documents">
                      <div className="doc-icon" >{document.icon}</div>
                      <span className="doc-text w-75">{document.file_name.split('/').pop()}</span>
                      {!(jsonData?.result.roles.some(role => ["admin", "superadmin", "marketer"].includes(role))
                        || apiResponse?.recruiter?.id === jsonData?.result.id) ? null : (
                        <button className="download-button" onClick={() => downloadFileAtURL(document.file_name)}><FaDownload /></button>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className='d-flex flex-column w-100'>
          <div className="additional-details-container">
            <div className="card-details-container">
              <div className="content-details-container">
                <h1 className="heading-additional-details" >
                  Additional Details
                </h1>
                {!(jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role))
                  || apiResponse?.recruiter?.id === jsonData?.result.id) ? null : (
                  <div
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={handleUpdateCandidate}
                  >
                    <FaPlusSquare size={27} color="#4681f4" />
                  </div>)}
              </div>
            </div>
            <div className='row Additional-details'>
              <div className="col-6">
            <div className='d-flex flex-row'>
              {/* <p style={{ fontSize: "16px", marginTop: "10px", marginRight: "4px", marginLeft: "10px", fontWeight: 600, display: "inline-block" }}> */}
              <p className='Additional-details-containt-heading'>
                Tech Stack  :
              </p>
              <p className='Additional-details-containt'>
              {/*  style={{ fontSize: "16px", marginTop: "10px", color: "grey", display: "inline-block", marginLeft: "10px" }}*/}
                 {apiResponse?.job_domain}
              </p>
            </div>
                <div className='d-flex flex-row'>
                <p className='Additional-details-containt-heading'>
                  Preference
                </p>
                <p className='Additional-details-containt'>
                  {apiResponse?.job_domain}
                </p>
                </div>
                <div className="d-flex flex-row">
                <p className='Additional-details-containt-heading'>
                  Skill Set
                </p>
                <p className='Additional-details-containt'>
                  {apiResponse.skills}
                </p>
                </div>
                </div>
                <div className="col-6">
              <div className="d-flex flex-row">
                <p className='Additional-details-containt-heading '>
                  Submission Count
                </p>
                <p className='Additional-details-containt '>
                  {apiResponse.sub_count>0 ? 
                  <a target='_blank' onClick={redirectSub}  className='cursor-pointer  flex gap-2 justify-center items-center'>
                  {apiResponse.sub_count} 
                  <img src={extLink} className='h-4' />
                  </a>
                  : apiResponse.sub_count} 
                </p>
                </div>
                <div className='d-flex flex-row'>
                <p className='Additional-details-containt-heading'>
                  Marketing Status
                </p>
                <p className='Additional-details-containt'>
                  {apiResponse.status}
                </p>
              </div>
              <div className="d-flex flex-row">
                <p className='Additional-details-containt-heading'>
                  Interview Count
                </p>
                <p className='Additional-details-containt'>
                  {apiResponse.interview_count}
                </p>
              </div>
              </div>
            </div>
          </div>
          {/* <div className="team-assigned-container">
            <h1>Team Assigned</h1>
            <div className="card-team-assigned">
              <div className="content-team">
                <ul className="list-team-assigned">
                  {teamAssignedList?.map((item, index) => (
                    <li key={index} className="list-item-team">
                      <span style={{ fontFamily: "'Signika Negative', sans-serif", fontSize: '18px', fontWeight: 'bold' }}>{item.name}</span>

                    </li>
                  ))}
                </ul>
              </div>
              <div className="icon">
                <FontAwesomeIcon icon={faUserCircle} className="custom-icon-team" />
              </div>
            </div>
          </div>  */}
          <div className="d-flex flex-row gap-2">
          <div className="primary-assignment-container">
            <h1 className="heading-style-primarassign">Assigned Team</h1>
            <div className="card-primary-assignment">
              <ul className="list-primary-assignment">
                {listItemsTeams?.map((item) => (
                  <li key={item.value} className="list-item-primary">
                    <div className="icon-and-info">
                      {/* <div className="icon">{item.icon}</div> */}
                      <div className="text-and-value">
                        <span style={{ fontFamily: "'Signika Negative', sans-serif", fontSize: '18px', fontWeight: 'bold' }}>{item.text}</span>
                      </div>
                      <div className="text-and-value">
                        <span style={{ fontFamily: "'Signika Negative', sans-serif", fontSize: '16px' }}>{item.value}</span>
                      </div>
                    </div>
                  </li>
                ))}

              </ul>
            </div>
          </div>
          <div className="candidate-projects-container">
            <h1>Recent Projects</h1>
            {projects.length>0 ? <>{currentProjects?.map((project, index) => (
              <div key={index} className="project-card">
                <h3>{project.title}</h3>
                <p><strong>Vendor:</strong> {project.vendor}</p>
                <p><strong>Client:</strong> {project.client}</p>
                <p><strong>Rate:</strong> {project.rate}</p>
                <p><strong>Duration:</strong> {project.duration}</p>
              </div>
            ))}
            <div className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span><b>{currentPage}</b></span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={endIndex >= projects.length}
              >
                Next
              </button>
            </div>
            </> : 
            <>
            <center>No Projects</center>
            </>
            }
          </div>
          
          </div>
          <div className='comments'>
            <h1>Comments</h1>
            <div className='comments-container'>
              <div className='new-comment-section'>
                <textarea
                  placeholder='Write your comment...'
                  value={newComment}
                  className='text-white'
                  onChange={(e) => setNewComment(e.target.value)}
                />
                <button onClick={handlePostComment} className='bg-blue-900 ' >Post Comment</button>
                <div className='h-full overflow-y-scroll'>         
                {comments.map((comment) => (
                  <Comment
                    key={comment.id}
                    comment={comment}
                  // onReply={handleReply}
                  // onEdit={handleEdit}
                  // onDelete={handleDelete}
                  onReply={() => handleReply(comment.id)}
                  onEdit={() => handleEdit(comment.id, comment.text)}
                  onSaveEdit={() => handleSaveEdit(comment.id)}
                  onCancelEdit={handleCancelEdit}
                  isReplying={isReplying}
                  replyText={replyText}
                  isEditing={isEditing}
                  editedText={editedText}
                  />
                ))}
                  </div>
              </div>
            </div>

          </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}
