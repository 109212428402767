import React, { useState, useEffect, useRef } from "react";
import { BiCaretDown } from "react-icons/bi";


const Status = ({ filters, handleStatusOptionClick, selectedStatusValue, setSelectedStatusValue, setCurrentPage,label }) => {
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsStatusDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsStatusDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <input
        type="text"
        placeholder={`${label=="Feedback"? "Feedback" : "Status"}`}
        value={selectedStatusValue}
        readOnly
        onClick={toggleDropdown}
        className="text-white bg-[#171111] border-0 placeholder-white"
      />
      <span className={`dropdown-icon mt-1 ${isStatusDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}>
        <BiCaretDown className="text-white" />
      </span>
      {isStatusDropdownOpen && (
        <ul className="dropdown-list-i space-y-4 border-0" style={{ backgroundColor: "#171111", color: "white", zIndex: "1" }}>
          {filters?.map((option) => (
            <li
              key={option.id}
              onClick={() => {
                setCurrentPage(1);
                handleStatusOptionClick(option);
                setIsStatusDropdownOpen(false); // Close the dropdown after selection
              }}
              className="px-2 capitalize"
            >
              {option.value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Status;
