import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import './UpdateTest.css';
import Sidebar from '../sidebar/Sidebar';
import axios from 'axios';
import Multiselect from 'multiselect-react-dropdown';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlashMessage } from '../flashmessage/FlashMessage';
import useSession from "../session/useSession";
const UpdateTest = () => {
  useSession();
  const { id } = useParams();
  const history = useHistory();
  const test_choices = [
    { id: "text_based", value: "Text Based" },
    { id: "video_based", value: "Video Based" },
  ]
  const [coders,setCoders]=useState([]);
  const [preSelectedCoders,setpreSelectedCoders]=useState([]);
  const test_status_choice = [
    // { id: "failed", value: "Failed" },
    { id: "cancelled", value: "Cancelled" },
    { id: "scheduled", value: "Scheduled" },
    // { id: "passed", value: "Passed"}
  ]
  const test_feedback_status_choice = [
    // { id: "feedback_done", value: "Feedback Done" },
    { id: "passed", value: "Passed"},
    { id: "failed", value: "Failed" },
    { id: "feedback_due", value: "Feedback Due" }
  ]

  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: true,
    openRequirements: false,
  };

  const jsonData = useSelector((state) => state.user);
  const [finalCoders,setFinalCoders]=useState([])
  const [isMounted, setIsMounted] = useState(false);
  const [guestValue, setGuestValue] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [testType, setTestType] = useState('');
  const [callDetails, setCallDetails] = useState('');
  const [details, setDetails] = useState('');
  const [status, setstatus] = useState('');
  const [feedback_status, setFeedback_status] = useState('');
  const [GuestList, setGuestList] = useState([]);
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const [feedback, setFeedback] = useState('');
  useEffect(() => {
    setIsMounted(true);
  }, []);
  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000);
  };
  const [validationErrors, setValidationErrors] = useState({
    testType: '',
    feedback_status: '',
    guest: '',
    start_time: '',
    end_time: '',
    call_details: '',
    description: '',
    status: '',
  });
  const validateForm = () => {
    const errors = {
      testType: '',
      feedback_status: '',
      start_time: '',
      end_time: '',
      status: '',
    };
    if (!testType) {
      errors.testType = 'Test Type is required';
    }
    if (!feedback_status) {
      errors.feedback_status = 'Feedback Status is required';
    }
    if (!startTime) {
      errors.start_time = 'Start Time is required';
    }
    if (!endTime) {
      errors.end_time = 'End Time is required';
    } else if (endTime <= startTime) {
        errors.end_time = 'End Time must be after Start Time';
      }
    
    if (!status) {
      errors.status = 'Test Status is required';
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => !error);
  };

  const handleGuestChange = (e) => {
    const { options } = e.target;
    const selectedGuestIds = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => parseInt(option.value));

    setGuestValue(selectedGuestIds);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'test_type':
        setTestType(value);
        break;
      case 'feedback_status':
        setFeedback_status(value);
        break;
      case 'call_details':
        setCallDetails(value);
        break;
      case 'description':
        setDetails(value);
        break;
      case 'status':
        setstatus(value);
        break;
      case 'feedback':
        setFeedback(value);
        break;
      default:
        break;
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;

    if (name === 'start_time' || name === 'end_time') {
      parsedValue = value ? new Date(value + 'Z') : null;
    }

    switch (name) {
      case 'start_time':
        setStartTime(parsedValue);
        break;
      case 'end_time':
        setEndTime(parsedValue);
        break;
      default:
        break;
    }
  };


  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlUsers = `${process.env.REACT_APP_API_URL}/employee/all_user_list/`;

      axios.get(apiUrlUsers, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        setGuestList(response.data.results.marketers);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [history, jsonData, isMounted]);

  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlUsers = `${process.env.REACT_APP_API_URL}/marketing/test/${id}`;
      axios.get(apiUrlUsers, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        const testData = response.data.result;
        console.log("Test data "+testData)
        if(response.data.result.coders.length>0){
          const mappedOptions = response.data.result.coders.map(coder => ({
            cat: "Group 1",
            key: coder.name,
            id:coder.id,
          }));
          const ids = mappedOptions.map(option => option.id);
          setFinalCoders(ids);
          setpreSelectedCoders(mappedOptions)
        }
        
        
        // console.log("Mapped Options "+mappedOptions)
        
        const selectedGuestIds = testData.guest.map(guest => guest.id);
        setGuestValue(selectedGuestIds);
        setTestType(testData.test_type);
        setCallDetails(testData.call_details);
        setDetails(testData.description);
        setStartTime(new Date(testData.start_time));
        setEndTime(new Date(testData.end_time));
        setstatus(testData.status);
        setFeedback_status(testData.feedback_status);
        setFeedback(testData.feedback);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });

    }
  }, [history, jsonData, isMounted]);

  useEffect(() => {
    const coderAPI = `${process.env.REACT_APP_API_URL}/marketing/coder`;
    axios.get(coderAPI, {
      headers: {
        Authorization: `Token ${jsonData?.result.token}`,
      }
    }).then(response => {
      
      const mappedOptions = response.data.result.map(coder => ({
        cat: "Group 1",
        key: coder.name,
        id:coder.id,
      }));
      setCoders(mappedOptions)
      console.log("Coders", response);
    }).catch(error => {
      console.error("There was an error fetching the coders!", error);
    });
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
        return;
    }
    const apiUrlUpdateTest = `${process.env.REACT_APP_API_URL}/marketing/test/${id}/`;
    const updateData = {
        id: id,
        test_type: testType,
        feedback_status: feedback_status,
        guest: guestValue,
        start_time: startTime,
        end_time: endTime,
        call_details: callDetails,
        description: details,
        status: status,
        feedback: feedback,
        coders:finalCoders
    };
    try {
        const response = await axios.put(apiUrlUpdateTest, updateData, {
            headers: {
            Authorization: `Token ${jsonData?.result.token}`,
            },
        });
        displayFlashMessage('Test Updated Successfully', 'success');
        setTimeout(() => {
            history.push(`/tests`);
        }, 1000);
    } catch (error) {
        displayFlashMessage('Test Update Failed', 'error');
        console.error('Error updating Test:', error);
    }
  };
  const handleRemove=(selectedList, removedItem)=>{
    const updatedFinalCoders = finalCoders.filter(id => id !== removedItem.id);
    setFinalCoders(updatedFinalCoders);
    console.log(finalCoders)
  }
  const handleSelect = (selectedList, selectedItem) => {
    const updatedFinalCoders = [...finalCoders, selectedItem.id];
    setFinalCoders(updatedFinalCoders);
    console.log(finalCoders)
  };
  console.log("Final Coders "+finalCoders)
  
  return (
    <div className="add-test-page">
      <div>
        <Sidebar set_map={set_map} />
      </div>
      <div className="test-form-container  bg-neutral-900 text-white">
        {flashMessage && (
          <FlashMessage message={flashMessage} type={flashMessageType} />
        )}
        <header className="flex flex-col font-bold tracking-widest uppercase bg-neutral-900 pl-5">
                <h1 className="z-10 justify-center items-start px-4 py-2 bg-indigo-950 rounded-[43px] max-md:px-5 max-md:max-w-full text-white mt-4">
                  EDIT TESTS
                </h1>
              </header> 
        <form onSubmit={handleUpdate}>
          <div className="form-grid">
            <div className="form-group">
            <label>Test Type:</label>
              <select placeholder='Test Type' name="test_type" value={testType} onChange={handleChange} >
                <option value="">Select Test Type</option>
                {test_choices.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.testType}</div>
            </div>
            <div className="form-group">
            <label>Guest:</label>
              <select
              placeholder='Guest'
              id="guest"
              name="guest"
              value={guestValue}
              onChange={handleGuestChange}
            >
              <option value="" disabled>Select a guest</option>
              {GuestList.map((guest) => (
                <option key={guest.id} value={guest.id}>
                  {guest.full_name}
                </option>
              ))}
            </select>
            </div>
            <div className="form-group">
            <label>Coders:</label>
              <Multiselect
                displayValue="key"
                id="css_custom"
                onKeyPressFn={function noRefCheck() { }}
                onRemove={handleRemove}
                // onRemove={function remove(){}}
                onSearch={function noRefCheck() { }}
                onSelect={handleSelect}
                // onSelect={function handleSelect() {}}
                options={coders}
                selectedValues={preSelectedCoders}
                placeholder="Select Coders"
                style={{
                  optionContainer: {
                    color: "white",
                    backgroundColor: "black",
                  },
                  chips: {
                    background: 'black'
                  },
                  multiselectContainer: {
                    color: 'black'
                  },
                  searchBox: {
                    border: 'none',
                    'border': '1px solid white',
                    'border-radius': '8px'
                  }
                }}
              />
            </div>
            <div className="form-group">
            <label>Start Time:</label>
              <input placeholder='Start Time' type="datetime-local" name="start_time" value={startTime instanceof Date ? startTime.toISOString().slice(0, 16) : ''} onChange={handleDateChange} />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.start_time}</div>
            </div>
            <div className="form-group">
            <label>End Time:</label>
              <input placeholder='End Time' type="datetime-local" name="end_time" value={endTime instanceof Date ? endTime.toISOString().slice(0, 16) : ''} onChange={handleDateChange} />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.end_time}</div>
            </div>
            <div className="form-group">
            <label>Calling Details:</label>
              <textarea
              placeholder='Calling Details'
                name="call_details"
                value={callDetails}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
            <label>Additional Details:</label>
              <textarea
              placeholder='Additional Details'
                name="description"
                value={details}
                onChange={handleChange}
              />
            </div>
            <div className="form-group" >
            <label>Status:</label>
              <select placeholder='Status' name="status" value={status} onChange={handleChange}>
                <option value="">Select Test Status</option>
                {test_status_choice.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.status}</div>
            </div>
            <div className="form-group" >
            <label>Feedback Status:</label>
              <select placeholder='Feedbck Status' name="feedback_status" value={feedback_status} onChange={handleChange}>
                <option value="">Select Test Feedback Status</option>
                {test_feedback_status_choice.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.feedback_status}</div>
            </div>
            <div className="form-group">
            <label>Remarks:</label>
              <textarea
              placeholder='Remarks'
                name="feedback"
                value={feedback}
                onChange={handleChange}
              />
            </div>
          </div>
          <button type="submit">Update</button>
        </form>
      </div>
    </div>

  );

};
export default UpdateTest;