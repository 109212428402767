import {
  faCakeCandles,
  faDownload,
  faEnvelope,
  faFile,
  faHatCowboy,
  faUser,
  faEdit,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import "./Submission.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Sidebar from "../sidebar/Sidebar";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useSession from "../session/useSession";
import { FlashMessage } from "../flashmessage/FlashMessage";

export const Submission = () => {
  useSession();
  const history = useHistory();
  const jsonData = useSelector((state) => state.user);
  const [submissionCards, setSubmissionCards] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const [file, setFile] = useState(null);
  const { id } = useParams();
  const sub_id = id;
  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: true,
    openInterview: false,
    openTest: false,
    openRequirements: false,
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    uploadFile(selectedFile);
    setFile(selectedFile);
  };

  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000);
  };

  const uploadFile = (file2) => {
    const content_type = 'marketing_submission';
    const apiUrl = `${process.env.REACT_APP_API_URL}/static_data/attachment/upload/`;
    const formData = new FormData();
    formData.append('content_type', 12);
    formData.append('creator', jsonData?.result.id);
    formData.append("object_id", id);
    formData.append('attachment_file', file2);

    axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Token ${jsonData?.result.token}`,
      },
    }).then((res) => {
      console.log(res.data);
      getFiles();
      displayFlashMessage('Submission Added Successfully', 'success');
    }).catch((error) => {
      displayFlashMessage('YOU ARE TRYING TO UPLOAD FILE', 'error');
    });
  };

  const getFiles = () => {
    const content_type = 'marketing_submission';
    axios.get(`${process.env.REACT_APP_API_URL}/static_data/attachments/${content_type}/${id}/`, {
      headers: {
        Authorization: `Token ${jsonData?.result.token}`,
      }
    }).then(response => {
      console.log(response);
      setAttachments(response?.data);
    }).catch(error => {
      console.error('Error fetching attachments:', error);
    });
  };

  useEffect(() => {
    if (isMounted && jsonData) {
      getFiles();

      const apiUrlConsultants = `${process.env.REACT_APP_API_URL}/marketing/submission/sub_by_id/`;
      axios.get(apiUrlConsultants, {
        params: {
          id: id,
        },
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        console.log(response)
        setSubmissionCards(response.data.results);
        console.log(submissionCards);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
      // console.log(submissionCards);
    }
  }, [id, jsonData, isMounted, file]);

  const downloadFileAtURL = (filename) => {
    const baseURL = 'https://vms-attachmnts.s3.ap-south-1.amazonaws.com/';
    const url = baseURL + filename;
    const atag = document.createElement('a');
    atag.href = url;
    atag.setAttribute('download', filename);
    document.body.appendChild(atag);
    atag.click();
    atag.remove();
  };

  const handleAddInterview = () => {
    history.push(`/submission/AddInterview/${sub_id}`);
  };

  const handleAddTest = () => {
    history.push(`/submission/addTest/${sub_id}`);
  };
  const handleAddScreening = () => {
    const paramsObject={
      "consultant":submissionCards?.consultant?.consultant,
      "marketer": submissionCards?.lead.marketer,
      "vendor": submissionCards?.vendor_contact.company,
      "client":submissionCards?.client
    } 

    history.push({pathname:`/submission/AddScreening/${sub_id}`,search:`?${new URLSearchParams(paramsObject).toString()}`});
  };

  const handleActionClick = () => {
    const queryParam = {
      sub_id: sub_id,
      client: submissionCards?.client,
      phone: submissionCards?.marketing_phone,
      email: submissionCards?.marketing_email,
      rate: submissionCards?.rate,
      vcontact: submissionCards?.vendor_contact?.number,
      vname: submissionCards?.vendor_contact?.name,
      vemail: submissionCards?.vendor_contact?.email,
      docs: submissionCards?.documents_shared,
      comments: submissionCards?.comments,
    };
    history.push({
      pathname: "/submission/SubmissionUpdation",
      search: `?${new URLSearchParams(queryParam).toString()}`
    });
  };

  return (
    <div className="submission-page">
      <div className="sidebar-submission">
        <Sidebar set_map={set_map} />
      </div>

      <div className="container" style={{ background: "" }}>

        <div
          className="row p-3 d-flex align-items-center Header first-div"
          style={{
            background: "",
            color: "white",
            fontSize: "14px",
          }}>
          <div className="justify-center items-start px-3 py-2 text-2xl font-bold tracking-widest leading-9 uppercase whitespace-nowrap color rounded-l-[43px] text-zinc-100 px-2 ml-3 w-full">
            SUBMISSION INFO
          </div>
          <div className="mt-5 row ml-3">
            <div className="col-3">
              <div className="d-flex mt-2 align-items-center gap-2">
                <div className="d-flex flex-column gap-2">
                  <span><p>{submissionCards?.consultant?.consultant}</p></span>
                  <span><p>{submissionCards?.consultant?.location}</p></span>
                </div>
              </div>
            </div>
            <div className="col-3 d-flex flex-column gap-4">
              <div className="d-flex align-items-center gap-2 object">
                <FontAwesomeIcon className="mb-2" icon={faEnvelope} />
                <p>{submissionCards?.marketing_email}</p>
              </div>
              <div className="d-flex align-items-center gap-2 object">
                <FontAwesomeIcon className="mb-2" icon={faCakeCandles} />
                <p>{submissionCards?.consultant?.dob}</p>
              </div>
              <div className="d-flex align-items-center gap-2 object">
                <FontAwesomeIcon className="mb-2" icon={faFile} />
                <p>{submissionCards?.consultant?.visa_type},{submissionCards?.consultant?.visa_start} to {submissionCards?.consultant?.visa_end}</p>
              </div>
            </div>
            <div className="col-3 d-flex flex-column gap-4">
              <div className="d-flex align-items-center gap-2 object">
                <FontAwesomeIcon className="mb-2" icon={faHatCowboy} />
                <p>N/A</p>
              </div>
              <div className="d-flex align-items-center gap-2 object">
                <FontAwesomeIcon className="mb-2" icon={faLinkedin} />
                <p>N/A</p>
              </div>
              <div className="d-flex align-items-center gap-2 object">
                <FontAwesomeIcon className="mb-2" icon={faUser} />
                <p>Person</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row ml-3">
          <div
            className=" d-flex gap-1 Sidebar"
            style={{ background: "", fontSize: "14px"}}
          >
            <div className="flex gap-4 flex-col">
            <li className="d-flex align-items-center">
              <button
                style={{ width: "157px" }}
                onClick={handleAddInterview}
                disabled={!(jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role)) || submissionCards?.lead?.marketer_id === jsonData?.result.id)}
              >
                Add Interview
              </button>
            </li>
            <li className="d-flex align-items-center">
              <button
                style={{ width: "157px" }}
                onClick={handleAddTest}
                disabled={!(jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role)) || submissionCards?.lead?.marketer_id === jsonData?.result.id)}
              >
                Add Test
              </button>
            </li>
            <li className="d-flex align-items-center">
              <button
                style={{ width: "157px" }}
                onClick={handleAddScreening}
                disabled={!(jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role)) || submissionCards?.lead?.marketer_id === jsonData?.result.id)}
              >
                Add Screening
              </button>
            </li>
            </div>
            <div
            className="col-md-4 col-xs-12  p-3 Submission"
          >

            <div className="uploadFile">
              <label htmlFor="uploadIcon" className="ml-3">
                Upload File <FontAwesomeIcon icon={faUpload} />
              </label>
              <input class="form-control" type="file" id="uploadIcon"
                onChange={handleFileChange} />
              {flashMessage && (
                <FlashMessage message={flashMessage} type={flashMessageType} />
              )}
            </div>

            <ul className="list-primary-assignment">
              {attachments?.map((document, index) => (
                <li key={index} className="list-documents">
                  <span className="doc-text">{document.file_name.split('/').pop()}</span>
                  <button
                    className="download-button"
                    onClick={() => downloadFileAtURL(document.file_name)}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </button>
                </li>
              ))}
            </ul>
          </div>
          </div>
          
          <div
            className="col-md-4 col-xs-12 mt-4 mx-4 p-3 
            Requirements"
          >
            <div className="d-flex justify-content-between">
              <h4 style={{fontStyle:"sensation"}}>Requirements</h4>
            </div>
            <p style={{ fontSize: "16px", marginTop: "10px", fontWeight: 600 }}>
              Job Description
            </p>
            <p style={{ fontSize: "16px", marginTop: "10px", color: "grey" }}>
              {submissionCards?.lead?.job_desc}
            </p>
            <div className="row">
              <div className="col-6">
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                >
                  Job Title
                </p>
                <p style={{ fontSize: "14px", color: "grey" }}>
                  {submissionCards?.lead?.job_title}
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                >
                  Job Position
                </p>
                <p style={{ fontSize: "14px", color: "grey" }}>
                  {submissionCards?.lead?.job_title}
                </p>
              </div>
              <div className="col-6">
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                >
                  Job Location
                </p>
                <p style={{ fontSize: "14px", color: "grey" }}>{submissionCards?.lead?.location}</p>
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                >
                  Vendor Company
                </p>
                <p style={{ fontSize: "14px", color: "grey" }}>{submissionCards?.lead?.vendor_company_name}</p>
              </div>
            </div>
            <hr
              style={{
                height: "3px",
                marginInline: "100px",
                marginBlock: "50px",
              }}
            />
            
          </div>
          <div className="d-flex justify-content-between text-white sub-class ml-5">
              <h4>Submission</h4>
              {!(jsonData?.result.roles.some(role => ["admin", "leads", "superadmin"].includes(role)) || submissionCards?.lead?.marketer_id === jsonData?.result.id) ? null : (
                <div
                  className="div4"
                  title="Edit Submission"
                  onClick={() => handleActionClick()}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ color: "#fff" }}
                  />
                </div>
              )}
            </div>
            <div className="row text-white sub-class ml-3">
              <div className="col-6">
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                >
                  Marketing Email
                </p>
                <p style={{ fontSize: "14px", color: "grey" }}>{submissionCards?.marketing_email}</p>
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                >
                  Client
                </p>
                <p style={{ fontSize: "14px", color: "grey" }}>{submissionCards?.client}</p>
                
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                >
                  Documents Shared
                </p>
                <p style={{ fontSize: "14px", color: "grey" }}>{submissionCards?.documents_shared}</p>
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                >
                  Employer
                </p>
                <p style={{ fontSize: "14px", color: "grey" }}>{submissionCards?.employer}</p>
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                >
                  Vendor Contact
                </p>

                <div style={{ fontSize: "12px", color: "grey" }}>
                  <span>Email: {submissionCards?.vendor_contact?.email}</span>
                  <br />
                  <span>Name: {submissionCards?.vendor_contact?.name}</span>
                  <br />
                  <span>Number: {submissionCards?.vendor_contact?.number}</span>
                </div>
              </div>
              <div className="col-6">
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                >
                  Marketing Phone
                </p>
                <p style={{ fontSize: "14px", color: "grey" }}>{submissionCards?.marketing_phone}</p>
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                >
                  Rate
                </p>
                <p style={{ fontSize: "14px", color: "grey" }}>{submissionCards?.rate}</p>
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                >
                  Vendor Layers
                </p>
                <p style={{ fontSize: "14px", color: "grey" }}></p>
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                >
                  Requirement Type
                </p>
                <p style={{ fontSize: "14px", color: "grey" }}>{submissionCards?.lead?.requirements_type}</p>
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                  className="flex gap-10"
                >
                  DL/VISA/SSN Shared
                  <input type="checkbox" name="isShared" id="isShared"
                  style={{
                    width:"20px",
                    height:"20px"
                  }}
                  checked={submissionCards?.is_document_shared}/>
                </p>
                <p style={{ fontSize: "14px", color: "grey" }}>{submissionCards?.comments}</p>
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "30px",
                    fontWeight: "600",
                  }}
                >
                  Comments
                </p>
                <p style={{ fontSize: "14px", color: "grey" }}>{submissionCards?.comments}</p>
              </div>
            </div>
          
        </div>
      </div>
    </div>
  );
};
