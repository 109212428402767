// InterviewDetailsPage.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './InterviewDetailsPage.css';
import useSession from "../session/useSession";
import Sidebar from '../sidebar/Sidebar';
import { Link } from "react-router-dom";
const InterviewDetailsPage = () => {
  useSession();
  const [interviewDetails, setInterviewDetails] = useState(null);
  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const { id } = useParams();
  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: true,
    openTest: false,
    openRequirements: false,
  };
  const formatTime = (timeString) => {
    return timeString.replace('T', ' ').replace('Z', '');
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);
  useEffect(() => {

    if (isMounted && jsonData) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/marketing/interview/${id}`
      axios.get(apiUrl, {
        // Include necessary headers
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        },
      })
        .then(response => {
          setInterviewDetails(response.data.results);
          console.log(response.data.results)
        })
        .catch(error => {
          console.error('Error fetching interview details:', error);
        });
    }
  }, [id, jsonData, isMounted]);

  return (
    <div className="bg-[#171111] mx-60 overflow-hidden w-[84N%]">
      <div>
        <Sidebar set_map={set_map} />
      </div>
      {interviewDetails ? (
        <div className='py-10'>
          <div className="bg-[#292256] rounded-l-full ml-10">
            <h1 className="text-2xl text-white font-semibold pl-6 py-2">INTERVIEW DETAILS</h1>
          </div>
          <div className='grid grid-flow-row grid-cols-2 gap-10 mx-10 my-4'>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="">CONSULTANT:</span>
              <span className="">{interviewDetails?.submission?.consultant?.consultant}</span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i">VENDOR COMPANY:</span>
              <span className="field-value-i">{interviewDetails?.submission?.lead?.vendor_company_name}</span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i">MARKETER:</span>
              <span className="field-value-i">{interviewDetails?.submission?.lead?.marketer}</span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i ">ROUND:</span>
              <span className="field-value-i">{interviewDetails?.round}</span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i">DATE AND TIME:</span>
              <span className="field-value-i">{formatTime(interviewDetails.start_time)}</span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i">STATUS:</span>
              <span className="field-value-i">{interviewDetails?.status}</span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i">INTERVIEW TYPE:</span>
              <span className="field-value-i">{interviewDetails?.interview_type}</span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i">CTB NAME:</span>
              <span className="field-value-i">{interviewDetails?.ctb?.name}</span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i">CLIENT:</span>
              <span className="field-value-i">{interviewDetails?.submission?.client}</span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i">JOB TITLE:</span>
              <span className="field-value-i">{interviewDetails?.submission?.lead?.job_title}</span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i">GUESTS:</span>
              <div className="field-value-i">
                {interviewDetails.guest.map((guest, index) => (
                  <div key={index}>
                    {guest.full_name}
                  </div>
                ))}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i">DESCRIPTION:</span>
              <span className="field-value-i">{interviewDetails.description}</span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i">Interviewer:</span>
              <span className="field-value-i">{interviewDetails.interviewer_name}</span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i">Interviewer Designation:</span>
              <span className="field-value-i">{interviewDetails.interviewer_designation}</span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i">Linkedin url:</span>
              <span className="field-value-i">
                <a href={interviewDetails.linked_in_url} target='_blank'>
                  {interviewDetails.linked_in_url}
                </a>
              </span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i">CALLING DETAILS:</span>
              <span className="field-value-i">
                <a href={interviewDetails.call_details} target='_blank' className='text-blue-700 underline'>Link</a>
              </span>

            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="field-name-i ">FEEDBACK:</span>
              <span className="field-value-i ">{interviewDetails.feedback}</span>
            </div>
            <div className="grid grid-cols-2 gap-10 mx-4 text-white font-medium text-lg">
              <span className="">Duration:</span>
              <span className="">{interviewDetails?.duration} Hours</span>
            </div>

          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )
      }
    </div >
  );
};


export default InterviewDetailsPage;
