import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import './AddTest.css';
import Sidebar from '../sidebar/Sidebar';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlashMessage } from '../flashmessage/FlashMessage';
import useSession from "../session/useSession";

const TestForm = () => {
  const [validationErrors, setValidationErrors] = useState({
    feedback: '',
    test_type: '',
    guest: '',
    start_time: '',
    end_time: '',
    call_details: '',
    status: '',
    feedback_status: '',
  });
  const [coders, setCoders] = useState([]);
  useSession();
  const history = useHistory();
  const { sub_id } = useParams();
  const test_choices = [
    { id: "text_based", value: "Text Based" },
    { id: "video_based", value: "Video Based" },
  ]
  const test_status_choice = [
    // { id: "failed", value: "Failed" },
    { id: "cancelled", value: "Cancelled" },
    { id: "scheduled", value: "Scheduled" },
    // { id: "passed", value: "Passed"}
  ]
  const test_feedback_status_choice = [
    // { id: "feedback_done", value: "Feedback Done" },
    { id: "passed", value: "Passed" },
    { id: "failed", value: "Failed" },
    { id: "feedback_due", value: "Feedback Due" }
  ]
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const [PrimaryMarketerList, setPrimaryMarketerList] = useState([]);
  const [formData, setFormData] = useState({
    feedback: '',
    test_type: '',
    guest: [],
    start_time: '',
    end_time: '',
    call_details: '',
    description: '',
    attachment_link: '',
    calendar_id: '',
    submission: parseInt(sub_id),
    status: '',
    feedback_status: '',
    coders:[]
  });
  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000);
  };
  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: true,
    openRequirements: false,
  };

  const handleMarketerChange = (e) => {
    const { options } = e.target;
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => parseInt(option.value));

    setFormData((prevData) => ({
      ...prevData,
      guest: selectedValues,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;

    if (name === 'start_time' || name === 'end_time') {
      parsedValue = value ? new Date(value + 'Z') : null;
    }

    if (name === 'start_time' && parsedValue && formData.end_time && parsedValue >= formData.end_time) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        start_time: 'Start Time should be before End Time',
      }));
    } else if (name === 'end_time' && parsedValue && formData.start_time && parsedValue <= formData.start_time) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        end_time: 'End Time should be after Start Time',
      }));
    } else {

      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        start_time: '',
        end_time: '',
      }));
    }
    setFormData((prevData) => ({ ...prevData, [name]: parsedValue }));
  };
  const handleSelect = (selectedList, selectedItem) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      coders: [...prevFormData.coders, selectedItem.id]
    }));
  };

  const handleRemove = (selectedList, removedItem) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      coders: prevFormData.coders.filter(coderId => coderId !== removedItem.id)
    }));
  };

  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  useEffect(() => {
    const coderAPI = `${process.env.REACT_APP_API_URL}/marketing/coder`;
    axios.get(coderAPI, {
      headers: {
        Authorization: `Token ${jsonData?.result.token}`,
      }
    }).then(response => {
      
      const mappedOptions = response.data.result.map(coder => ({
        cat: "Group 1",
        key: coder.name,
        id:coder.id,
      }));
      setCoders(mappedOptions)
      console.log("Coders", response);
    }).catch(error => {
      console.error("There was an error fetching the coders!", error);
    });
  }, []);
  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlUsers = `${process.env.REACT_APP_API_URL}/employee/all_user_list/`;

      axios.get(apiUrlUsers, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        setPrimaryMarketerList(response.data.results.marketers);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [history, jsonData, isMounted]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const validateField = (name, value) => {
    switch (name) {
      case 'test_type':
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          test_type: value ? '' : 'Please select an test type',
        }));
        break;

      case 'start_time':
      case 'end_time':

        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: value ? '' : 'Please select a valid date and time',
        }));
        break;
      case 'status':
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          status: value ? '' : 'Please select an test status',
        }));
        break;
      case 'feedback_status':
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          feedback_status: value ? '' : 'Please select an test feedback status',
        }));
        break;
      default:
        break;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    validateAllFields();

    if (Object.values(validationErrors).some((error) => error !== '')) {
      displayFlashMessage('Enter the details correctly', 'error');
      setTimeout(() => {
        return;
      }, 1000);
    }
    try {

      await axios.post(`${process.env.REACT_APP_API_URL}/marketing/test/`, formData,
        {
          headers: {
            Authorization: `Token ${jsonData.result.token}`,
          },
        }
      );
      displayFlashMessage('Test Added Successfully', 'success');
      setFormData({
        feedback: '',
        test_type: '',
        guest: [],
        start_time: '',
        end_time: '',
        call_details: '',
        description: '',
        attachment_link: '',
        calendar_id: '',
        submission: parseInt(sub_id),
        status: '',
        feedback_status: '',
      });
      setTimeout(() => {
        history.push(`/tests`);
      }, 1000);
    } catch (error) {
      console.error('Error submitting form:', error.message);
      displayFlashMessage('Test Added Failed', 'error');
    }

  };
  const validateAllFields = () => {
    validateField('test_type', formData.test_type);
    validateField('start_time', formData.start_time);
    validateField('end_time', formData.end_time);
    validateField('call_details', formData.call_details);
    validateField('description', formData.description);
    validateField('status', formData.status);
    validateField('feedback_status', formData.feedback_status)
  };
  console.log(coders)
  return (
    <div >
      <div>
        <Sidebar set_map={set_map} />
      </div>
      <div className="justify-center items-start px-3 py-2 text-2xl font-bold tracking-widest leading-9 uppercase whitespace-nowrap color rounded-l-[43px] text-zinc-100 max-md:px-5 mt-4 mb-4  ml-[23%] ">
        Add Test
      </div>
      <div className="test-form-container">
        {flashMessage && (
          <FlashMessage message={flashMessage} type={flashMessageType} />
        )}
        {/* <h2>Test Details</h2> */}
        <form onSubmit={handleSubmit}>
          <div className="form-grid">
            <div className="form-group">
              <label>Test Type:</label>
              <select name="test_type" value={formData.test_type} onChange={handleChange}>
                <option value="">Select Test Type</option>
                {test_choices.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.test_type}</div>
            </div>
            <div className="form-group">
              <label>Guest:</label>
              <select
                id="guest"
                name="guest"
                onChange={handleMarketerChange}
              // multiple
              >
                <option value="" disabled>Select a guest</option>
                {PrimaryMarketerList.map((marketer) => (
                  <option value={marketer.id}>
                    {marketer.full_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
            <label>Coders:</label>
              <Multiselect
                displayValue="key"
                id="css_custom"
                onKeyPressFn={function noRefCheck() { }}
                onRemove={handleRemove}
                onSearch={function noRefCheck() { }}
                onSelect={handleSelect}
                options={coders}
                placeholder="Select Coders"
                style={{
                  optionContainer: {
                    color: "white",
                    backgroundColor: "black",
                  },
                  chips: {
                    background: 'black'
                  },
                  multiselectContainer: {
                    color: 'black'
                  },
                  searchBox: {
                    border: 'none',
                    'border': '1px solid white',
                    'border-radius': '8px'
                  }
                }}
              />
            </div>
            <div className="form-group">
              <label>Start Time:</label>
              <input type="datetime-local" name="start_time" value={formData.start_time ? formData.start_time.toISOString().slice(0, 16) : ''} onChange={handleDateChange} />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.start_time}</div>
            </div>
            <div className="form-group">
              <label>End Time:</label>
              <input type="datetime-local" name="end_time" value={formData.end_time ? formData.end_time.toISOString().slice(0, 16) : ''} onChange={handleDateChange} />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.end_time}</div>
            </div>
            <div className="form-group">
              <label>Calling Details:</label>
              <textarea
                name="call_details"
                value={formData.call_details}
                onChange={handleChange}
                className='text-white'
              />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.call_details}</div>
            </div>
            <div className="form-group">
              <label>Additional Details:</label>
              <textarea
                name="description"
                className='text-white'
                value={formData.description}
                onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Status:</label>
              <select name="status" value={formData.status} onChange={handleChange}>
                <option value="">Select Test Status</option>
                {test_status_choice.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.status}</div>
            </div>
            <div className="form-group">
              <label>Feedback Status:</label>
              <select name="feedback_status" value={formData.feedback_status} onChange={handleChange}>
                <option value="">Select Test Feedback Status</option>
                {test_feedback_status_choice.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.feedback_status}</div>
            </div>
          </div>
          <button type="submit" className="test-button">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default TestForm;
