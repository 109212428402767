import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import LoginForm from "./components/authentication/LoginForm";
import Interviews from "./components/interviews/Interviews";
import { Submissions } from "./components/submissions/Submissions";
import Dashboard from "./components/dashboard/Dashboard";
import AddCandidatePage from "./components/candidate/AddCandidatePage";
import UpdateCandidate from "./components/candidate/UpdateCandidate";
import { CandidateProfilePage } from "./components/candidate/CandidateProfilePage";
import { CandidateDetailsPage } from "./components/candidate/CandidateDetailsPage";
import { CreateLead } from "./components/requirements/create-lead";
import { MarketingDetails } from "./components/requirements/marketing-details/index";
import { RequirementList } from "./components/requirements/requirement-list/index";
import { Submission } from "./components/submissions/Submission";
import { ConsultantDetails } from "./components/requirements/consultant-details";
import './global.css';
import UserProfile from "./components/UserInformation/UserProfile";
import { Provider } from "react-redux";
import { store, persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import AddInterview from "./components/submissions/AddInterview";
import { LeadUpdation } from "./components/requirements/requirement-list/LeadUpdation";
import SubmissionUpdation from "./components/submissions/SubmissionUpdation";
import InterviewUpdate from "./components/interviews/InterviewUpdate";
import TestForm from "./components/tests/AddTest";
import Tests from "./components/tests/Tests";
import Screening from "./components/screening/Screening";
import UpdateTest from "./components/tests/UpdateTest";
import InterviewDetailsPage from "./components/interviews/InterviewDetailsPage";
import Logs from "./components/vms_logs/Logs";
import AdminDashboard from "./components/admin_dashboard/AdminDashboard";
import DocumentUpload from "./components/resume_analyzer/DocumentUpload";
import PersonalInfoUpdation from "./components/UserInformation/PersonalInfoUpdation"
import UpdateScreening from "./components/screening/UpdateScreening";
import ScreeningDetailsPage from "./components/screening/ScreeningDetails";
import AddScreening from "./components/screening/AddScreening";


function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Switch>
            <Route exact path="/sidebar" component={Sidebar} />
            <Route exact path="/" component={LoginForm} />
            <Route exact path="/login" component={LoginForm} />
            <Route exact path="/interviews" component={Interviews} />
            <Route exact path="/interviews/InterviewDetailsPage/:id" component={InterviewDetailsPage} />
            <Route exact path="/interviews/InterviewUpdate/:id" component={InterviewUpdate} />
            <Route exact path="/AddInterview/interviews" component={Interviews} />
            <Route exact path="/submissions" component={Submissions} />
            <Route exact path="/requirements/create-lead" component={CreateLead} />
            <Route exact path="/requirements/consultant-details" component={ConsultantDetails} />
            <Route exact path="/requirements/marketing-details" component={MarketingDetails} />
            <Route exact path="/requirements" component={RequirementList} />
            <Route exact path="/requirements/LeadUpdation/:lead_id" component={LeadUpdation} />
            <Route exact path="/submissions/submission/:id" component={Submission} />
            <Route exact path="/submission/AddInterview/:sub_id" component={AddInterview} />
            <Route exact path="/tests" component={Tests} />
            <Route exact path="/screening" component={Screening} />
            <Route exact path="/screening/ScreeningDetailsPage/:id" component={ScreeningDetailsPage} />
            <Route exact path="/screening/:id" component={UpdateScreening} />
            <Route exact path="/submission/AddScreening/:sub_id" component={AddScreening} />
            <Route exact path="/tests/TestUpdate/:id" component={UpdateTest} />
            <Route exact path="/submission/addTest/:sub_id" component={TestForm} />
            <Route exact path="/submission/SubmissionUpdation" component={SubmissionUpdation} />
            <Route exact path="/SubmissionUpdation/submission/:id" component={Submission} />
            <Route exact path="/LeadUpdation/requirements" component={RequirementList} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/dashboard/logs" component={Logs} />
            <Route exact path="/dashboard/admin" component={AdminDashboard} />
            <Route exact path="/detailscandidate/addcandidate" component={AddCandidatePage} />
            <Route exact path="/detailscandidate/profilecandidate/updatecandidate/" component={UpdateCandidate} />
            <Route exact path="/detailscandidate/profilecandidate/:id" component={CandidateProfilePage} />
            <Route exact path="/detailscandidate/profilecandidate/updatecandidate/profilecandidate/:id" component={CandidateProfilePage} />
            <Route exact path="/detailscandidate" component={CandidateDetailsPage} />
            <Route exact path="/userprofile" component={UserProfile} />
            <Route exact path="/userprofile/profileUpdation" component={PersonalInfoUpdation} />
            <Route exact path="/DocumentUpload" component={DocumentUpload} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
