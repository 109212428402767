import React, { useState } from 'react';
import './PersonalInfo.css';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PersonalInfoUpdation from './PersonalInfoUpdation';

const PersonalInfo = () => {
  const jsonData = useSelector((state) => state.user);
  const history = useHistory();
  const user = [
    {
      label: 'Full Name',
      value: jsonData?.result.full_name,
    },
    {
      label: 'Gender',
      value: jsonData?.result.gender,
    },
    {
      label: 'Joining-Date',
      value: jsonData?.result.date_joined ? format(new Date(jsonData.result.date_joined), 'dd-MM-yyyy') : '',
    },
    {
      label: 'Marital-Status',
      value: jsonData?.result.marital_status,
    },
    //  {
    //   label:'Location',
    //   value: jsonData?.result.location,
    //  } ,
    {
      label: 'Email',
      value: jsonData?.result.email,
    },
    {
      label: 'Mobile-Number',
      value: jsonData?.result.phone,
    },
    {
      label: 'Company-Accessory',
      value: jsonData?.result.company_accessory,
    },
    {
      label: 'Employee-ID',
      value: jsonData?.result.employee_id,
    },
    {
      label: 'DOB',
      value: jsonData?.result.dob,
    }
  ];

  const handleActionClick = () => {
    const queryParam = {
      full_name: jsonData?.result.full_name,
      gender: jsonData?.result.gender,
      date_joined: jsonData?.result.date_joined ? format(new Date(jsonData.result.date_joined), 'yyyy-MM-dd') : '',
      marital_status: jsonData?.result.marital_status,
      userLocation: jsonData?.location,
      email: jsonData?.result.email,
      phone: jsonData?.result.phone,
      company_accessory: jsonData?.result.company_accessory,
      employee_id: jsonData?.result.employee_id,
      dob: jsonData?.result.dob
    }
    history.push({
      pathname: "/userprofile/profileUpdation",
      search: `?${new URLSearchParams(queryParam).toString()}`
    });
  }


  return (
    <div className="Personal-Info-Container">
      <div
        className="relative top-[39.5rem] h-[40px] text-white text-xl w-[100px] flex flex-row justify-center space-x-4 items-center bg-[#292256] rounded-md"
        title="Edit Submission"
        onClick={() => handleActionClick()}
      >
        <FontAwesomeIcon
          icon={faEdit}
          style={{ color: "#fff" }}
        />
        Edit
      </div>
      <div className="text-white space-y-10">
        {user.map((options) => (
          <div className='flex flex-row space-x-40'>
            <span className="font-medium text-xl w-[300px]">
              {options.label}:
            </span>
            <span className="text-slate-400 text-xl">
              {options.value}
            </span>
          </div>
        ))}
      </div>
      <div className="text-white my-4">
      <div className="bg-[#292256] rounded-l-full relative top-14 my-4 right-[10px] w-svw">
          <h1 className="text-2xl text-white font-semibold pl-6 py-2">Description</h1>
        </div>
        <div className="text-white">
          <p className='Description-div-para-1 mt-20 mx-4 text-white' >
            {jsonData?.result.discription}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
