import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { FlashMessage } from '../../flashmessage/FlashMessage';

export const AddCompanyModal = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const jsonData = useSelector((state) => state.user);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setIsInputEmpty(value.trim() === "");
  };

  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000); 
  };

  const handleSubmit = () => {
    if (!isInputEmpty) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/marketing/company/`;
      axios
        .post(apiUrl, { name: inputValue }, {
          headers: {
            Authorization: `Token ${jsonData.result.token}`,
          }
        })
        .then(response => {
          displayFlashMessage(`Company ${response.data.result.name} added successfully`, 'success');
        })
        .catch(error => {
          displayFlashMessage(error.response.data.result, 'error');
        })
        .finally(() => {
          setInputValue("");
          setTimeout(() => {
            props.onHide();
          }, 1000);
        });
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter bg-[#252323]"
      centered
    >
      <Modal.Header className="d-flex flex-column text-3xl text-white bg-[#252323] border-b-0">
        <Form.Label>ADD COMPANY</Form.Label>
        <Form.Control
          type="text"
          className="text-white bg-[#252323] focus:text-white focus:bg-[#252323]"
          placeholder="Enter Company Name"
          value={inputValue}
          onChange={handleInputChange}
        />
      </Modal.Header>
        {flashMessage && (
          <FlashMessage message={flashMessage} type={flashMessageType} />
        )}
      <Modal.Footer className="d-flex justify-content-center text-white bg-[#252323] border-0">
        <Button
          onClick={handleSubmit}
          disabled={isInputEmpty}
          style={{ background: "#B4A4F5" }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};