import React from "react";
import "./create-lead.css";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { FlashMessage } from "../../flashmessage/FlashMessage";
import Sidebar from "../../sidebar/Sidebar";
import useSession from "../../session/useSession";

export const CreateLead = () => {
  useSession();
  const countryList = ["USA", "India", "Russia"];
  const requirementTypeList = [
    {id: "C2C", value: "C2C"}, 
    {id: "W2", value: "W2"}, 
    {id: "full_time", value: "Full time"}
  ]
  const [company, setCompany]  = useState([]);
  const [country, setCountry] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [requirementType, setRequirementType] = useState(null);
  const [skills, setSkills] = useState(null);
  const [job_title, setJob_title] = useState(null);
  const [location, setLocation] = useState(null);
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const jsonData = useSelector((state) => state.user);
  const history = useHistory();
  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false, 
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: false,
    openRequirements: true,
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if(isMounted && jsonData) {
      const apiUrl =  `${process.env.REACT_APP_API_URL}/marketing/company/` ;
      if (jsonData?.result.token) {
        axios
          .get(apiUrl, {
            headers: {
              Authorization: `Token ${jsonData?.result.token}`,
            },
          })
          .then((response) => {
            setCompany(response.data.results); 
          })
          .catch((error) => console.error("Error fetching company data:", error));
      }
    }
  }, [jsonData, isMounted]);

  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000); 
  };

  const handleSaveAndExit = () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/marketing/lead/`;
    console.log(requirementType)
    axios.post(apiUrl, {
      job_desc: description,
      country: country,
      location: location,
      job_title: job_title,
      skill: skills,
      requirement_type: requirementType,
      vendor_company: selectedCompanyId,
    },
    {
      headers: {
        Authorization: `Token ${jsonData.result.token}`,
      },
    }).then(response => {
      displayFlashMessage('Lead Added Successfully', 'success');
      setTimeout(() => {
        history.push("/requirements");
      }, 1000);
    }).catch(error => {
      displayFlashMessage(error.response.data.result, 'error');
    })
  }

  const handleSaveAndAddResume = () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/marketing/lead/`;
    console.log(requirementType)
    axios.post(apiUrl, {
      job_desc: description,
      country: country,
      location: location,
      job_title: job_title,
      skill: skills,
      // Fixed Default C2C bug
      requirements_type: requirementType,
      vendor_company: selectedCompanyId,
    },
    {
      headers: {
        Authorization: `Token ${jsonData.result.token}`,
      },
    }).then(response => {
      displayFlashMessage('Lead Added Successfully', 'success');
      const queryParam = {
        company_id: response.data.result.company_id,
        lead_id: response.data.result.id,
      } 
      setTimeout(() => {
        history.push({
          pathname: "/requirements/consultant-details",
          search: `?${new URLSearchParams(queryParam).toString()}`
        });
      }, 1000);
    }).catch(error => {
      displayFlashMessage(error.response.data.result, 'error');
    })
  };

  const handleDescriptionChange = (e) => {
    const selectedDescription = e.target.value;
    setDescription(selectedDescription);
  }

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setCountry(selectedCountry);
  }

  const handleCompanyChange = (e) => {
    const selectedCompanyId = e.target.value;
    setSelectedCompanyId(selectedCompanyId);
  };

  const handleRequirementChange = (e) => {
    const selectedRequirement = e.target.value;
    setRequirementType(selectedRequirement);
  };

  const handleSkillsChange = (e) => {
    const selectedSkills = e.target.value;
    setSkills(selectedSkills);
  }

  const handleJobTitleChange = (e) => {
    const selectedJobTitle = e.target.value;
    setJob_title(selectedJobTitle)
  }

  const handleLocationChange = (e) => {
    const selectedLocation = e.target.value;
    setLocation(selectedLocation);
  }

  return (
    <div className="create-lead-page bg-neutral-900 text-white py-3">
      <div className="sidebar-create-lead">
        <Sidebar set_map={set_map} />
      </div>
      
      <div className="width-check">
      <div className="bg-[#292256] rounded-l-full ml-5 mt-10">
          <h1 className="text-2xl text-white font-semibold pl-6 py-2">ADD REQUIREMENTS</h1>
      </div>
        <div
          className="mt-5 d-flex justify-content-center align-items-center flex-wrap"
          style={{ fontFamily: "Signika Negative" }}
        >
          <div className="flex-col">
            <div className="header-greens flex justify-center items-center rounded-full h-[50px] w-[50px] max-md:px-5 bg-emerald-700 stroke-[3px] border-white border-solid border-[1px]">1</div>
            <span className="mx-2">Create Lead</span>
          </div>
          <hr style={{ width: "370px", height:"3px", background:"white", marginBottom:"23px", marginLeft:"-26px", marginRight:"-20px"}} />
          <div className="flex-col">
            <div className="header-greens flex justify-center items-center rounded-full h-[50px] w-[50px] max-md:px-5 bg-neutral-900 stroke-[3px] border-white border-solid border-[1px]">2</div>
            <span className="Span mx-2 w-[70px]">Consultant Details</span>
          </div>
          <hr style={{ width: "375px", height:"3px", background:"white", marginBottom:"23px", marginLeft:"-56px", marginRight:"-20px"}} />
          <div className="flex-col">
            <div className="header-greens flex justify-center items-center rounded-full h-[50px] w-[50px] max-md:px-5 bg-neutral-900 stroke-[3px] border-white border-solid border-[1px]">3</div>
            <span className="Span mx-2 w-[70px]">Marketing Details</span>
          </div>
        </div>
        <div class="container mt-5 bg-neutral-900 text-white">
          {flashMessage && (
            <FlashMessage message={flashMessage} type={flashMessageType} />
          )}
          <div class="row">
            <div class="col-md-3 col-xs-12 ml-8">
              <div className="d-flex flex-column">
                <div>
                  <label for="exampleFormControlSelect" class="form-label ">
                    Country
                  </label>
                  <select
                    class="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    aria-label="Default select example"
                    onChange={handleCountryChange}
                  >
                    <option value={""} selected>Select Country</option>
                    {countryList.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                </div>
                <div className="mt-2">
                  <label for="exampleFormControlSelect" class="form-label ">
                    Job Location
                  </label>
                  <input
                    type="text"
                    class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    id="exampleFormControlInput1"
                    required
                    value={location}
                    onChange={handleLocationChange}
                  />
                </div>
                <div className="mt-2">
                  <label for="exampleFormControlSelect" class="form-label ">
                    Job Title
                  </label>
                  <input
                    type="text"
                    class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    id="exampleFormControlInput1"
                    required
                    value={job_title}
                    onChange={handleJobTitleChange}
                  />
                </div>
              </div>
              <div className="mt-2">
                <label for="exampleFormControlSelect" class="form-label ">
                  Skills
                </label>
                <input
                  type="text"
                  class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                  id="exampleFormControlInput1"
                  required
                  value={skills}
                  onChange={handleSkillsChange}
                />
              </div>
              <div className="mt-2">
                <label for="exampleFormControlSelect" class="form-label ">
                  Requirement Type
                </label>
                <select class="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white" aria-label="Default select example" onChange={handleRequirementChange}>
                  <option value={""} selected> Select Requirements Type</option>
                  {requirementTypeList.map((item) => (
                    <option value={item.id}>{item.value}</option>
                  ))}
                </select>
              </div>
              <div className="mt-2">
                <label for="exampleFormControlSelect" class="form-label ">
                  Vendor Company
                </label>
                <select class="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white" aria-label="Default select example" onChange={handleCompanyChange}>
                  <option value={""} selected>Select Vendor Company</option>
                  {company.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div class="col-md-6 col-xs-12">
              <label for="exampleFormControlTextarea1" class="form-label ">
                Job Description
              </label>
              <textarea
                class="form-control Textarea bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                id="exampleFormControlTextarea1"
                rows="3"
                required
                value={description}
                onChange={handleDescriptionChange}
              ></textarea>
            </div>
          </div>
        </div>
        <div className=" mt-2 gap-2 d-flex px-10">
          <button
            type="button"
            class=" leadButtons flex gap-1 px-3 py-2.5 text-white rounded-xl bg-[#292256]"
            onClick={handleSaveAndExit}
            disabled={
              !description || !country || !location || !job_title 
              || !skills  || !requirementType || !selectedCompanyId
            }
          >
            Save and Exit
          </button>
          <button
            type="button"
            class=" leadButtons flex gap-1 px-3 py-2.5 text-white rounded-xl bg-[#292256]"
            onClick={handleSaveAndAddResume}
            disabled={
              !description || !country || !location || !job_title 
              || !skills  || !requirementType || !selectedCompanyId
            }
          >
            Save and add Resume
          </button>
        </div>
      </div>
    </div>
  );
};
