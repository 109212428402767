import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { FlashMessage } from '../flashmessage/FlashMessage';

export const AddCompanyModal = ({ candidateId, ...props }) => {
    const [flashMessage, setFlashMessage] = useState(null);
    const [flashMessageType, setFlashMessageType] = useState(null);
    const jsonData = useSelector((state) => state.user);
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const displayFlashMessage = (message, type) => {
        setFlashMessage(message);
        setFlashMessageType(type);
        setTimeout(() => {
            setFlashMessage(null);
            setFlashMessageType(null);
        }, 1000);
    };

    const handleSubmit = () => {

        if (!file) {
            displayFlashMessage('Please select a file', 'error');
            return;
        }
        var myHeaders = new Headers({
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${jsonData?.result.token}`,
          });

        var formData = new FormData();
        formData.append("content_type", 18);
        formData.append("object_id", candidateId);
        formData.append("attachment_file", file);
        formData.append("creator", jsonData.result.id);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
            redirect: "follow",
        };
        axios.post(`${process.env.REACT_APP_API_URL}/static_data/attachment/upload/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Token ${jsonData?.result.token}`,
            },
          }).then((res) => {
            displayFlashMessage(`File Uploaded successfully`, 'success');
          }).catch((error) => {
            displayFlashMessage('error');
          });
        // fetch(`${process.env.REACT_APP_API_URL}/static_data/attachment/upload/`, requestOptions)
        //     .then((response) => {
        //         console.log(response);
        //         displayFlashMessage(`File Uploaded successfully`, 'success');
        //     })
        //     .catch(error => {
        //         console.log(error);
        //         displayFlashMessage('error');
        //     })
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="d-flex flex-column">
                <Form.Label>Add Documents</Form.Label>
                <div class="mt-0">
                    <label for="formFile" class="form-label">
                        Attachments:
                    </label>
                    <input class="form-control" type="file" id="formFile" accept=".pdf, .png, .jpeg, .jpg, .doc, .docx" onChange={handleFileChange} />
                    <p className="mt-3">
                        Only PDF, PNG, JPEG and DOCS files are supported
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
                {flashMessage && (
                    <FlashMessage message={flashMessage} type={flashMessageType} />
                )}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button
                    onClick={handleSubmit}
                    style={{ background: "#4681f4" }}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};