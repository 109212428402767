import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './AddCandidatePage.css';
import Sidebar from '../sidebar/Sidebar';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { FlashMessage } from '../flashmessage/FlashMessage';
import useSession from '../session/useSession'
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';

function AddCandidatePage() {
  useSession();
  const history = useHistory();
  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [marketingDateError, setMarketingDateError] = useState("");
  const [nameError, setNameError] = useState('');
  const [ssnError, setssnError] = useState('');
  const [genderError, setgenderError] = useState('');
  const [recruiterError, setrecruiterError] = useState('');
  const [marketerError, setmarketerError] = useState('');
  const [is_poolError, setis_poolError] = useState('');
  const [dobError, setDobError] = useState('');
  const [PrimaryMarketerList, setPrimaryMarketerList] = useState([]);
  const [RecruiterList, setRecruiterList] = useState([]);
  const [Teams, setTeams] = useState([]);
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const [locationError, setlocationError] = useState('');
  const [preferredlocationError, setpreferredlocationError] = useState('');
  const [RateValueError, setRateValueError] = useState('');
  const [StatusError, setStatusError] = useState('');
  const [visaError, setvisaError] = useState('');
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlUsers = `${process.env.REACT_APP_API_URL}/employee/all_user_list/`;
      axios.get(apiUrlUsers, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        setPrimaryMarketerList(response.data.results.marketers);
        setRecruiterList(response.data.results.recruiters);
        setTeams(response.data.results.teams);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [history, jsonData, isMounted]);

  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000);
  };

  const set_map = {
    showDashboard: false,
    showCandidate: true,
    showMarketing: false,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openAddCandidate:true,
    openSubmission: false,
    openInterview: false,
    openTest: false,
    openRequirements: false,
  };
  const status_choices = [
    { id: "in_marketing", value: "In Marketing" },
    { id: "on_project", value: "On Project" },
    { id: "training", value: "Training" },
  ]
  const bench_choices = [
    { id: "dev_bench", value: "DEV Bench" },
    { id: "ba_bench", value: "BA Bench" },
  ]
  const gender_choices = [
    { id: "male", value: "Male" },
    { id: "female", value: "Female" },
    { id: "other", value: "Other" },
  ]
  const visa_choices = [
    { id: 'US_Citizen', value: "US Citizen" },
    { id: 'green_card', value: "Green Card" },
    { id: 'h1b', value: "H1B" },
    { id: 'opt', value: "OPT" },
    { id: 'opt_stem', value: "OPT Stem" },
    { id: 'cpt', value: "CPT" },
    { id: 'gc_ead', value: "GC EAD" },
    { id: 'h4_ead', value: "H4 EAD" },
  ]
  const rate_choices = [
    { id: "$/hr", value: "$/hr" },
    { id: "$/annum", value: "$/annum" },
    { id: "percentage", value: "percentage" }
  ]

  const [customVisa, setCustomVisa] = useState('');
  const [formData, setFormData] = useState({
    is_active: true,
    name: '',
    email: '',
    ssn: '',
    phone_no: '',
    rtg: '',
    gender: 'Select Gender',
    rate: null,
    skills: '',
    preferred_location: '',
    teams: [],
    primary_marketer: null,
    recruiter: null,
    dob: '',
    in_pool: '',
    marketing_start: null,
    marketing_end: null,
    visa_start: null,
    visa_end: null,
    marketer: '',
    job_domain: '',
    status: '',
    visa: '',
    education: '',
    comments: '',
    zip_code: '',
    customVisa: '',
    location: '',
    address: '',
    rateType: '',
    bench_status: '',
  });

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidName = (name) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    return nameRegex.test(name);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

  };

  const handleDobChange = (e) => {
    const selectedDate = e.target.value;
    setFormData({ ...formData, dob: selectedDate });

    if (!isValidDate(selectedDate)) {
      setDobError('Please enter a valid date of birth.');
    } else if (isFutureDate(selectedDate)) {
      setDobError('Date of birth cannot be in the future.');
    } else {
      setDobError('');
    }
  };
  const handleDateChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'marketing_end') {
      const marketingStart = formData.marketing_start;
      const marketingEnd = value;
      if (marketingStart && marketingEnd && marketingEnd < marketingStart) {
        setMarketingDateError("Marketing end Date should be after start date");
      } else {
        setMarketingDateError("");
      }
    }
  };
  const isValidDate = (date) => {

    return !isNaN(Date.parse(date));
  };

  const isFutureDate = (date) => {
    const dobDate = new Date(date);
    const currentDate = new Date();
    return dobDate > currentDate;
  };

  const handleTeamChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      teams: [value],
    }));

  }
  const handleMarketerChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      primary_marketer: parseInt(value),
    }));
  }
  const handleRecruiterChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      recruiter: parseInt(value),
    }));
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const dateFields = ['dob', 'visa_start', 'visa_end', 'marketing_start', 'marketing_end'];
    dateFields.forEach((field) => {
      if (formData[field] === '') {
        formData[field] = null;
      }
    });
    let isValid = true;
    if (formData.name.trim() === '') {
      isValid = false;
      setNameError('*required');
    }

    else if (!isValidName(formData.name)) {
      isValid = false;
      setNameError('Name should be charaters');

    } else {
      setNameError('');
    }

    const enteredPhoneNumber = formData.phone_no;
    setPhoneNumber(enteredPhoneNumber);
    if (enteredPhoneNumber === '') {
      isValid = false;
      setPhoneError('*required');
    } else if (!isValidPhoneNumber(enteredPhoneNumber)) {
      isValid = false;
      setPhoneError("Invalid phone number.");
    }
    else {
      setPhoneError("");
    }

    const enteredEmail = formData.email;
    setEmail(enteredEmail);

    if (enteredEmail === '') {
      isValid = false;
      setEmailError('*required')
    }
    else if (!isValidEmail(enteredEmail)) {
      isValid = false;
      setEmailError("Invalid email address.");
    } else {
      setEmailError("");
    }

    const digitRegex = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$|^\d{4}$/;
    if (formData.ssn === '') {
      isValid = true;
    }

    else if (!digitRegex.test(formData.ssn)) {
      isValid = false;
      setssnError('Enter Valid SSN');

    } else {
      setssnError('');
    }

    if (formData.gender === '') {
      isValid = false;
      setgenderError("*required");
    }

    if (formData.primary_marketer === '') {
      isValid = false;
      setmarketerError("*required");
    }

    if (!isValidDate(formData.dob)) {
      isValid = false;
      setDobError('Please enter a valid date of birth.');
    } else if (isFutureDate(formData.dob)) {
      isValid = false;
      setDobError('Date of birth cannot be in the future.');
    } else {
      setDobError('');
    }

    if (formData.location === '') {
      isValid = false;
      setlocationError('*required')
    }
    if (formData.preferred_location === '') {
      isValid = false;
      setpreferredlocationError('*required')
    }
    if (formData.rateType === '') {
      isValid = false;
      setRateValueError('*required')
    }
    if (formData.status === '') {
      isValid = false;
      setStatusError('*required')
    }
    if (formData.visaType === '') {
      isValid = false;
      setvisaError('*required')
    }
    if (marketingDateError) {
      isValid = false;
    }
    if (isValid) {
      const postData = { ...formData };
      const visaType = formData.visa === 'custom' ? formData.customVisa : formData.visa;
      const updatedData = { ...postData, visa: formData.visa === 'custom' ? formData.customVisa : formData.visa };
      axios
        .post(`${process.env.REACT_APP_API_URL}/candidate/candidate/`, updatedData, {
          headers: {
            Authorization: `Token ${jsonData?.result.token}`,
          },
        })
        .then((response) => {
          const profileData = {
            consultant: response.data.result.id,
            dob: formData.dob,
            title: '',
            visa_end: formData.visa_start,
            visa_start: formData.visa_end,
            links: '',
            location: formData.location,
            visa_type: visaType,
            education: formData.education,
          };
          axios
            .post(`${process.env.REACT_APP_API_URL}/candidate/candidate_profile/`, profileData, {
              headers: {
                Authorization: `Token ${jsonData?.result.token}`,
              },
            })
            .then((profileResponse) => {
              displayFlashMessage('Consultant Added Successfully', 'success');
              setFormData({
                name: '',
                email: '',
                ssn: '',
                phone_no: '',
                rtg: '',
                gender: 'Select Gender',
                rate: '',
                skills: '',
                preferred_location: '',
                teams: [],
                primary_marketer: null,
                recruiter: null,
                in_pool: '',
                dob: '',
                marketing_start: '',
                marketing_end: '',
                marketer: '',
                job_domain: '',
                status: '',
                visa: '',
                education: '',
              });
              setFormData(prevFormData => ({
                ...prevFormData,
                teams: [],
                primary_marketer: null,
                recruiter: null,
              }));
              setTimeout(() => {
                history.push('/detailscandidate');
              }, 1000);
            })
            .catch((profileError) => {
              
              displayFlashMessage(profileError.response.data.result, 'error');
              console.log("-------------------",profileError.response.data.result)
              console.error('Error creating candidate profile:', profileError,"----");
            });
        })
        .catch((error) => {
          console.error('Error creating candidate:', error);
          displayFlashMessage(error.response.data.result, 'error');
              
        });
    }
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    if (name === 'status' && value === 'in_marketing') {
      setShowAdditionalFilters(true)
    }
    else {
      setShowAdditionalFilters(false)
    }
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="addcandidate-page bg-neutral-900 text-white">
      <div className="sidebar-addcandidate">
        <Sidebar set_map={set_map} />
      </div>
      <div className="add-candidate-container">
        {flashMessage && (
          <FlashMessage message={flashMessage} type={flashMessageType} />
        )}
        <div>
            <header className="flex flex-col font-bold tracking-widest uppercase bg-neutral-900 pl-5 w-full pb-2">
                    <h1 className="z-10 justify-center items-start px-4 py-2 bg-indigo-950 rounded-[43px] max-md:px-5 max-md:max-w-full text-white mt-4">
                      ADD Candidate
                    </h1>
              </header>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid-container">
            <div className="grid-item">
              <label htmlFor="name">Full Name:<span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {nameError && (
                <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                  {nameError}
                </div>
              )}
            </div>
            <div className="grid-item">
              <label htmlFor="email">Email:<span style={{ color: 'red' }}>*</span></label>
              <input
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {emailError && (
                <div
                  style={{ color: "red", fontSize: "14px" }}
                  className="error-message"
                >
                  {emailError}
                </div>
              )}
            </div>
            <div className="grid-item">
              <label htmlFor="phone_no">Phone:<span style={{ color: 'red' }}>*</span></label>
              <input
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                type="text"
                id="phone_no"
                name="phone_no"
                value={formData.phone_no}
                onChange={handleChange}
                required
              />
              {phoneError && (
                <div
                  style={{ color: "red", fontSize: "14px" }}
                  className="error-message"
                >
                  {phoneError}
                </div>
              )}
            </div>
            <div className="grid-item">
              <label htmlFor="dob">Date of Birth:<span style={{ color: 'red' }}>*</span></label>
              <input
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                type="date"
                id="dob"
                name="dob"
                value={formData.dob}
                onChange={handleDobChange}
                required
              />
              {dobError && (
                <div style={{ color: "red", fontSize: "14px" }} className="error-message">
                  {dobError}
                </div>
              )}
            </div>
            <div className="grid-item">
              <label htmlFor="gender">Gender:<span style={{ color: 'red' }}>*</span></label>
              <select
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={handleSelectChange}
                required
              >
                <option value="">Select Gender</option>
                {gender_choices.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              {genderError && (
                <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                  {genderError}
                </div>
              )}
            </div>
            <div className="grid-item">
              <label htmlFor="address">Address:</label>
              <textarea
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </div>
            <div className="grid-item">
              <label htmlFor="education">Education Details:</label>
              <textarea
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                type="text"
                id="education"
                name="education"
                value={formData.education}
                onChange={handleChange}
              />
            </div>
            <div className="grid-item">
              <label htmlFor="visa">Visa:<span style={{ color: 'red' }}>*</span></label>
              <select
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                id="visa"
                name="visa"
                value={formData.visa}
                onChange={handleSelectChange}
                required
              >
                <option value="">Select Visa</option>
                {visa_choices.map((visa) => (
                  <option key={visa.id} value={visa.id}>
                    {visa.value}
                  </option>
                ))}
                <option value="custom">Other (Type your own)</option>
              </select>
              {visaError && (
                <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                  {visaError}
                </div>
              )}
            </div>
            {formData.visa === 'custom' && (
              <div className="form-group">
                <label>Custom Visa:</label>
                <input
                  className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                  type="text"
                  name="customVisa"
                  value={formData.customVisa}
                  onChange={handleSelectChange}
                  placeholder="Type your custom visa"
                  required
                />
              </div>
            )}

            <div className="grid-item">
              <label htmlFor="visa_start">Visa Start:</label>
              <input
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                type="date"
                id="visa_start"
                name="visa_start"
                value={formData.visa_start}
                onChange={handleDateChange}

              />
            </div>
            <div className="grid-item">
              <label htmlFor="visa_end">Visa End:</label>
              <input
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                type="date"
                id="visa_end"
                name="visa_end"
                value={formData.visa_end}
                onChange={handleDateChange}

              />
            </div>
            <div className="grid-item">
              <label htmlFor="location">Current Location:<span style={{ color: 'red' }}>*</span></label>
              <input
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                type="text"
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                required
              />
              {locationError && (
                <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                  {locationError}
                </div>
              )}
            </div>
            <div className="grid-item">
              <label htmlFor="preferred_location">Preferred Locations:<span style={{ color: 'red' }}>*</span></label>
              <input
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                type="text"
                id="preferred_location"
                name="preferred_location"
                value={formData.preferred_location}
                onChange={handleChange}
                required
              />
              {preferredlocationError && (
                <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                  {preferredlocationError}
                </div>
              )}
            </div>
            <div className="grid-item">
              <label htmlFor="rtg">RTG:<span style={{ color: 'red' }}>*</span></label>
              <select
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                id="rtg"
                name="rtg"
                value={formData.rtg}
                onChange={handleSelectChange}
                required
              >
                <option value="">Select RTG</option>
                <option value="yes">Yes</option>
                <option value="no" selected>No</option>
              </select>

            </div>
            <div className="grid-item">
              <label htmlFor="ssn">SSN:</label>
              <input
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                type="text"
                id="ssn"
                name="ssn"
                value={formData.ssn}
                onChange={handleChange}
              />
              {ssnError && (
                <div
                  style={{ color: "red", fontSize: "14px" }}
                  className="error-message"
                >
                  {ssnError}
                </div>
              )}
            </div>
            <div className="grid-item">
              <label htmlFor="job_domain">Job Domain:</label>
              <input
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                type="text"
                id="job_domain"
                name="job_domain"
                value={formData.job_domain}
                onChange={handleChange}
              />
            </div>
            <div className="grid-item">
              <label htmlFor="skills">Preferred Tech Stack:</label>
              <input
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                type="text"
                id="skills"
                name="skills"
                value={formData.skills}
                onChange={handleChange}
              />
            </div>

            <div className="grid-item">
              <label htmlFor="primary_marketer">Marketer:<span style={{ color: 'red' }}>*</span></label>
              <select
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                id="primary_marketer"
                name="primary_marketer"
                onChange={handleMarketerChange}
                required
              >
                <option value={null} selected>Select a marketer</option>
                {PrimaryMarketerList.map((marketer) => (
                  <option value={marketer.id}>
                    {marketer.full_name}
                  </option>
                ))}
              </select>
              {marketerError && (
                <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                  {marketerError}
                </div>
              )}
            </div>
            <div className="grid-item">
              <label htmlFor="in_pool">In Pool:<span style={{ color: 'red' }}>*</span></label>
              <select
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                id="in_pool"
                name="in_pool"
                value={formData.in_pool}
                onChange={handleSelectChange}
                required
              >
                <option value="">Select Pool</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>

            <div className="grid-item">
              <label htmlFor="marketing_start">Marketing Start:</label>
              <input
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                type="date"
                id="marketing_start"
                name="marketing_start"
                value={formData.marketing_start}
                onChange={handleDateChange}

              />
            </div>
            <div className="grid-item">
              <label htmlFor="marketing_end">Marketing End:</label>
              <input
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                type="date"
                id="marketing_end"
                name="marketing_end"
                value={formData.marketing_end}
                onChange={handleDateChange}
              />
              {marketingDateError && (
                <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                  {marketingDateError}
                </div>
              )}
            </div>

            <div className="grid-item">
              <label htmlFor="recruiter">Recruiter:<span style={{ color: 'red' }}>*</span></label>
              <select
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                id="recruiter"
                name="recruiter"
                onChange={handleRecruiterChange}
                required
              >
                <option value={null} selected>Select a recruiter</option>
                {RecruiterList.map((recruiter) => (
                  <option value={recruiter.id}>
                    {recruiter.full_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="grid-item">
              <label htmlFor="rateType">Rate Type:<span style={{ color: 'red' }}>*</span></label>
              <select
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                id="rateType"
                name="rateType"
                value={formData.rateType}
                onChange={handleSelectChange}
                required
              >
                <option value="">Select Rate Type</option>
                {rate_choices.map((rate) => (
                  <option key={rate.id} value={rate.id}>
                    {rate.value}
                  </option>
                ))}
              </select>
              {RateValueError && (
                <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                  {RateValueError}
                </div>
              )}
            </div>

            {formData.rateType && (
              <div className="grid-item">
                <label htmlFor="rate">Rate: <span style={{ color: 'red' }}>*</span></label>
                <input
                  className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                  type="number"
                  id="rate"
                  name="rate"
                  value={formData.rate}
                  onChange={handleChange}
                  required
                />
              </div>
            )}

            <div className="grid-item">
              <label htmlFor="status">Status:<span style={{ color: 'red' }}>*</span></label>
              <select
                className='form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                id="status"
                name="status"
                value={formData.status}
                onChange={handleSelectChange}
                required
              >
                <option value="">Select Candidate Status</option>
                {status_choices.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              {StatusError && (
                <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                  {StatusError}
                </div>
              )}
            </div>
                  
            {showAdditionalFilters && formData.status === 'in_marketing' && (
              <div>
             
                <div className="grid-item">
                  <label htmlFor="bench_status">Bench Status:</label>
                  <select
                    id="bench_status"
                    name="bench_status"
                    value={formData.bench_status}
                    onChange={handleChange}
                  >
                    <option value="">Select Bench Status</option>
                    {bench_choices.map((bench) => (
                      <option key={bench.id} value={bench.id}>
                        {bench.value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}


          </div>
          <button
            type="submit"
            className='flex gap-1 px-4 py-2.5 text-white rounded-xl bg-indigo-950'
          >Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddCandidatePage;
