import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../actions/action';
import { FlashMessage } from '../flashmessage/FlashMessage';
import axios from 'axios';

const useSession = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);

  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000); 
  };

  useEffect(() => {
    const checkSession = () => {
      const isAuth = localStorage.getItem('access_token') !== null;

      if (isAuth) {
        const tokenExpiry = new Date(localStorage.getItem('token_expiry'));
        const currentTime = new Date();
        if (currentTime > tokenExpiry) {
          axios.delete(`${process.env.REACT_APP_API_URL}/employee/logout/`, {
            headers: {
              Authorization: `Token ${localStorage.getItem('access_token')}`,
            },
          }).then((response) => {
            displayFlashMessage('Session Expired', 'error');
            dispatch(logoutUser()); 
            localStorage.removeItem('access_token');
            localStorage.removeItem('token_expiry');
            setTimeout(()=> {
              history.push("/login");
            },1000);
          }).catch((error) => {
            console.error('session logout failed:', error);
          });
        }
      } else {
        dispatch(logoutUser()); 
        history.push("/login");
      }
    };

    checkSession();

    const sessionInterval = setInterval(() => {
      checkSession();
    }, 30000);

    return () => clearInterval(sessionInterval);
  }, [history, dispatch]);

  return (
    <div>
      {flashMessage && (
            <FlashMessage message={flashMessage} type={flashMessageType} />
          )}
    </div>
  );
};

export default useSession;
