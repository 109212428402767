import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import './MarketerFilter.css';

const MarketerFilter = ({ onSelect, onClearSearchTerm, onMarketerChange }) => {
  const [marketers, setMarketers] = useState([]);
  const [selectedMarketer, setSelectedMarketer] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const jsonData = useSelector((state) => state.user);

  useEffect(() => {
    // console.log(selectedMarketer)

    const apiUrlMarketers = `${process.env.REACT_APP_API_URL}/employee/all_user_list/`;
    axios
      .get(apiUrlMarketers, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }

      }).then((response) => {
        console.log(response)
        setMarketers(response.data.results.marketers);
      })
      .catch((error) => {
        console.error('Error fetching marketer data:', error);
      });

  }, [selectedMarketer]);

  console.log(marketers);
  
  const handleChange = (selectedOption) => {
    setSelectedMarketer(selectedOption);
    onSelect(selectedOption?.value);
    onMarketerChange();
  };
  const handleClear = () => {
    setSelectedMarketer(null);
    onSelect(null);
    onClearSearchTerm();
    onMarketerChange();
  };

  const filteredMarketers =
    Array.isArray(marketers) &&
    marketers.filter((marketer) =>
      marketer.full_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const options = filteredMarketers
    ? filteredMarketers.map((marketer) => ({
      value: marketer.id,
      label: marketer.full_name,
    }))
    : [];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
      backgroundColor: "#171111",
      color: "#FFFFFF",
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#171111' : 'inherit',
      color: state.isFocused ? '#FFFFFF' : '#FFFFFF',
      ':hover': {
        backgroundColor: '#171111',
        color: '#FFFFFF',
      },
      cursor: 'pointer',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#FFFFFF',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#FFFFFF',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#171111",
    }),
  };

  return (
    <Select
      value={selectedMarketer}
      onChange={handleChange}
      options={options}
      isSearchable
      onInputChange={(inputValue) => setSearchTerm(inputValue)}
      placeholder="Search for a marketer"
      className="my-4 ml-5 placeholder-white w-[180px] text-white"
      styles={customStyles}
    />
  );
};

export default MarketerFilter;
