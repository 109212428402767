import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BiCaretDown } from "react-icons/bi";
import { useHistory } from 'react-router-dom';
import {
  FcManager,
  FcBusinessman,
  FcDepartment,
  FcHome,
  FcOvertime,
  FcGraduationCap,
  FcReadingEbook,
  FcSlrBackSide,
  FcCheckmark,
  FcClock,
  FcCancel,
} from "react-icons/fc";
import { AiFillSchedule } from "react-icons/ai";
import { BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import "./Tests.css";
import Sidebar from "../sidebar/Sidebar";
import useSession from "../session/useSession";
import SearchIcon from "../assets/SearchIcon.svg"
import RightArrow from "../assets/RightArrow.svg"
import LeftArrow from "../assets/LeftArrow.svg"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Status from "../interviews/Status";
import Interview_header_img from "../assets/Interview_header_img.png"
import Consultant_header_img from "../assets/Consultant_header_img.png"
import Vendor_header_img from "../assets/Vendor_header_img.png"
import Client_header_img from "../assets/Client_header_img.png"
import Time_header_img from "../assets/Time_header_img.png"



const Tests = () => {
  useSession();
  const [selectedStatusValue, setSelectedStatusValue] = useState("");
  const [selectedFeedbackStatusValue, setSelectedFeedbackStatusValue] = useState("");
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isFeedbackStatusDropdownOpen, setIsFeedbackStatusDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [testCards, setTestCards] = useState([]);
  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const [endIndex, setEndIndex] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedFeedbackStatus, setSelectedFeedbackStatus] = useState("");
  const history = useHistory();
  
  const location = useLocation();
  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: true,
    openRequirements: false,
  };
  const filters = [
    // { id: "failed", value: "Failed" },
    { id: "cancelled", value: "Cancelled" },
    // { id: "passed", value: "Passed" },
    { id: "scheduled", value: "Scheduled" },
  ];
  const feedback_filters = [
    { id: "passed", value: "Passed" },
    { id: "failed", value: "Failed" },
    { id: "feedback_due", value: "Due" },
    // { id: "feedback_done", value: "Done" },
  ]
  const statusMappings = {
    // passed: 'Passed',
    // failed: 'Failed',
    scheduled: 'Scheduled',
    cancelled: 'Cancelled',
  };
  const feedback_statusMappings = {
    // feedback_done: 'Feedback Done',
    passed: 'Passed',
    failed: 'Failed',
    feedback_due: 'Feedback Due',
  }
  const roundMappings = {
    text_based: 'Text Based',
    video_based: 'Video Based',
  }
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const itemsPerPage = 10;
  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlConsultants = `${process.env.REACT_APP_API_URL}/marketing/test`;
      axios.get(apiUrlConsultants, {
        params: {
          page: currentPage,
          page_size: itemsPerPage,
          status: selectedStatus,
          feedback_status: selectedFeedbackStatus,
          search: searchTerm,
          start_date: location.state?.start_date,
          end_date: location.state?.end_date
        },
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        setTestCards(response.data.results);

        
        setEndIndex(response.data.total);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [currentPage, jsonData, searchTerm, selectedStatus, selectedFeedbackStatus, isMounted]);


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleStatusOptionClick = (option) => {
    setSelectedStatusValue(option.value);
    setIsStatusDropdownOpen(false);
    setSelectedStatus(option.id);
  };

  const handleFeedbackStatusOptionClick = (option) => {
    setSelectedFeedbackStatusValue(option.value);
    setIsFeedbackStatusDropdownOpen(false);
    setSelectedFeedbackStatus(option.id);
  };

  const handleClearFilter = (e) => {
    setSelectedStatusValue("");
    setSelectedStatus("");
    setSelectedFeedbackStatusValue("");
    setSelectedFeedbackStatus("");
    setSearchTerm("");
    setCurrentPage(1);
    setIsStatusDropdownOpen(false);
    setIsFeedbackStatusDropdownOpen(false);
  }
  const getInitials = (name) => {
    const words = name.split(' ');
    const initials = words.map(word => word[0]).join('');
    return initials.toUpperCase();
  };

  const handleTestUpdate = (id) => {
    history.push(`/tests/testUpdate/${id}`);
  }
  console.log(testCards)
  // console.log(coders);
  return (
    <div className="test-page bg-neutral-900 text-white">
      <div>
        <Sidebar set_map={set_map} />
      </div>
      <div className="test-page-container bg-neutral-900 text-white">
        <header className="flex flex-col font-bold tracking-widest uppercase bg-neutral-900 pl-5">
          <h1 className="z-10 justify-center items-start px-4 py-2 bg-indigo-950 rounded-[43px] max-md:px-5 max-md:max-w-full text-white mt-4">
            TESTS
          </h1>
        </header>
        <div className=" toolbar flex text-xl font-semibold text-zinc-400 max-md:flex-wrap mt-3 ml-5">
          <div className="flex gap-5 justify-between text-xl font-semibold text-zinc-400 max-md:flex-wrap mb-4 mt-3 ml-5">
            <div className="flex justify-center whitespace-nowrap text-fuchsia-50 text-opacity-50" style={{ width: "262px" }}>
              <div className="flex items-center justify-start px-4 py-1 w-full color border border-solid border-indigo-700 rounded-full max-md:px-5">
                <img
                  loading="lazy"
                  src={SearchIcon}
                  alt="Search icon"
                  className="aspect-square ml-4"
                />
                <input
                  type="text"
                  placeholder="Search Tests"
                  className="search-input bg-transparent border-none px-2"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value)
                    setCurrentPage(1)
                  }}
                />
              </div>
            </div>
          </div>

          <div className="my-2 mx-3 justify-center">

            <Status setCurrentPage={setCurrentPage} label={"Feedback"} filters={feedback_filters} selectedStatusValue={selectedFeedbackStatusValue} setSelectedStatusValue={setSelectedFeedbackStatusValue} handleStatusOptionClick={handleFeedbackStatusOptionClick} />
            {/* <input
              type="text"
              placeholder="Feedback"
              value={selectedFeedbackStatusValue}
              readOnly
              onClick={() => setIsFeedbackStatusDropdownOpen(!isFeedbackStatusDropdownOpen)}
              className="filtersss input-tests-page-feedback-status bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
            />
            <span
              className={`dropdown-icon ${isFeedbackStatusDropdownOpen ? "open" : ""}`}
              onClick={() => setIsFeedbackStatusDropdownOpen(!isFeedbackStatusDropdownOpen)}
            >
              <BiCaretDown />
            </span>
            {isFeedbackStatusDropdownOpen && (
              <ul className="dropdown-list">
                {feedback_filters.map((option) => (
                  <li
                    key={option.id}
                    onClick={() => {
                      setCurrentPage(1);
                      handleFeedbackStatusOptionClick(option)
                    }
                    }
                  >
                    {option.value}
                  </li>
                ))}
              </ul>
            )} */}
          </div>

          <div className="my-2 mx-3 justify-center">
            <Status setCurrentPage={setCurrentPage} filters={filters} selectedStatusValue={selectedStatusValue} setSelectedStatusValue={setSelectedStatusValue} handleStatusOptionClick={handleStatusOptionClick} />
            {/* <input
              type="text"
              placeholder="Status"
              value={selectedStatusValue}
              readOnly
              onClick={() => setIsStatusDropdownOpen(!isStatusDropdownOpen)}
              className="filtersss input-tests-page-status bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
            />
            <span
              className={`dropdown-icon ${isStatusDropdownOpen ? "open" : ""}`}
              onClick={() => setIsStatusDropdownOpen(!isStatusDropdownOpen)}
            >
              <BiCaretDown />
            </span>
            {isStatusDropdownOpen && (
              <ul className="dropdown-list">
                {filters.map((option) => (
                  <li
                    key={option.id}
                    onClick={() => {
                      setCurrentPage(1);
                      handleStatusOptionClick(option)
                    }
                    }
                  >
                    {option.value}
                  </li>
                ))}
              </ul>
            )} */}
          </div>
          <div className="flex align-items-center py-1">
            <button
              className="bg-[#b4a4f5] text-center text-slate-800 font-medium  hover:bg-[#6c48fd] hover:text-white w-max  min-w-[110px] max-w-full font-sans mb-4"
              onClick={handleClearFilter}
            >Clear</button>
          </div>


        </div>
      </div>
      <div className="header-bar2 flex gap-5  px-5 font-bold  grid grid-cols-5 ">
        <div className="header-title flex flex-row gap-2 p-2.5 justify-content-centers">
          <img
            loading="lazy"
            src={Interview_header_img}
            className="header-icon shrink-0 my-auto w-6 aspect-square"
          />
          <div>Job Title</div>
        </div>
        <div className="header-title flex flex-row gap-2 p-2.5 justify-content-centers">
          <img
            loading="lazy"
            src={Consultant_header_img}
            className="header-icon shrink-0 my-auto w-6 aspect-square"
          />
          <div>Consultant</div>
        </div>
        <div className="header-title flex flex-row gap-2 p-2.5 justify-content-center">
          <img
            loading="lazy"
            src={Vendor_header_img}
            className="header-icon shrink-0 my-auto w-6 aspect-square"
          />
          <div>Vendor</div>
        </div>
        <div className="header-title flex flex-row gap-2 p-2.5 justify-content-center">
          <img
            loading="lazy"
            src={Client_header_img}
            className="header-icon shrink-0 my-auto w-6 aspect-square"
          />
          <div>Client</div>
        </div>
        <div className="header-title flex flex-row gap-2 p-2.5 font-medium justify-content-center">
          <img
            loading="lazy"
            src={Time_header_img}
            className="header-icon shrink-0 my-auto aspect-square w-[21px]"
          />
          <div>Time</div>
        </div>
      </div>

      <div className="tests-filter bg-neutral-900 text-white">
        <div className="tests-card  text-white w-100 ml-4">
          <ul>
            {testCards.map((testCard) => (
              <li key={testCard.id} className="tests-box " >
                <div className="tests-box-upper bg-indigo-950 text-white">
                  {/* <div className="id-round-profile-coders">
                      <div className="id-round">
                        <span className="id" title="Test Id">
                          {testCard.id}
                        </span>
                      </div>
                      <div className="profile">
                        <span title="Profile" className="cursur-on-hover">
                          <FcGraduationCap />
                        </span>
                        <span className="consultant-box">
                          {testCard.job_title}
                        </span>
                      </div>
                      <div className="coders">
                        <span
                          title="Guest Assigned"
                          className="cursur-on-hover"
                        >
                          <FcReadingEbook />
                        </span>
                        <span className="coders-list">
                          <ul className="list">
                            {testCard.guest_names.slice(0, 3).map((guestName) => (
                              <li>{guestName}</li>
                            ))}
                            {testCard.guest_names.length > 3 && (
                              <div className="more-guests">+ {testCard.guest_names.length - 3} more</div>
                            )}
                          </ul>
                        </span>
                      </div>
                    </div> */}
                  <div className="consultant-marketer-box">
                    <span className="consultant-box">
                      <i title="Job Title">
                        <FcGraduationCap />
                      </i>
                      &nbsp;{testCard.job_title}
                    </span>

                    <span className="marketer-box">
                      <i title="Guest">
                        <FcReadingEbook />
                      </i>
                      &nbsp;{testCard.guest_names.slice(0, 3).map((guestName) => (
                        <li>{guestName}</li>
                      ))}
                      {testCard.guest_names.length > 3 && (
                        <div className="more-guests">+ {testCard.guest_names.length - 3} more</div>
                      )}
                    </span>
                    {/* <button className="edit-button rounded-xl bg-indigo-950" disabled={!(jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role))
                      || testCard?.marketer_id === jsonData?.result.id)} onClick={() => handleTestUpdate(testCard.id)}>
                      Edit
                    </button> */}
                    <div className="inline-block">
                      {testCard.coders?.map((item) => {
                        const initials = getInitials(item.name);

                        return (
                          <button   className="rounded-full custom-button mr-2" key={item.name} title={item.name}>{initials}</button>
                        );
                      })}
                    </div>
                    <div>

                    </div>
                  </div>
                  <div className="consultant-marketer-box">
                    <span className="consultant-box">
                      <i title="Consultant">
                        <FcManager />
                      </i>
                      &nbsp;{testCard.consultant_name}
                    </span>

                    <span className="marketer-box">
                      <i title="Marketer">
                        <FcBusinessman />
                      </i>
                      &nbsp;{testCard.marketer_name}
                    </span>
                  </div>
                  <div className="vendor-requirement-box">
                    <span className="vendor-box">
                      <i title="Vendor">
                        <FcDepartment />
                      </i>
                      &nbsp;{testCard.company_name}
                    </span>
                  </div>
                  <div className="client-testee-time">
                    <span className="client-box">
                      <i title="Client">
                        <FcHome />
                      </i>
                      &nbsp;{testCard.client}
                    </span>
                    {window.innerWidth <= 600 ? null : (
                      <span className="time-box1" title="Time of Test">
                        &nbsp;{testCard.start_time}
                      </span>
                    )}
                  </div>
                  <div className="test-call">
                    <span className="test-status-box ">
                      <i title="Test Mode">
                        <FcSlrBackSide />
                      </i>{" "}
                      &nbsp; <span>{roundMappings[testCard.test_type] || testCard.test_type}</span>
                    </span>

                    <span className="test-status" title="Test Status">
                      {/* {testCard.status === "passed" && <FcCheckmark />}
                      {testCard.status === "failed" && <FcCancel />} */}
                      {testCard.status === "cancelled" && <FcCancel />}
                      {testCard.status === "scheduled" && <AiFillSchedule />}
                      &nbsp;{statusMappings[testCard.status] || testCard.status}
                    </span>
                    <span className="test-status" title="Test Feedback Status">
                      {testCard.feedback_status === "feedback_due" && <FcClock />}
                      {testCard.feedback_status === "passed" && <FcCheckmark />}
                      {testCard.feedback_status === "failed" && <FcCancel />}
                      {/* {testCard.feedback_status === "feedback_done" && <FcCheckmark />} */}
                      &nbsp;{feedback_statusMappings[testCard.feedback_status] || testCard.feedback_status}
                    </span>
                    <button className="edit-button rounded-xl bg-indigo-950" disabled={!(jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role))
                      || testCard?.marketer_id === jsonData?.result.id)} onClick={() => handleTestUpdate(testCard.id)}>
                      Edit
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {/* <div className="pagination-i">
              <button
                className="button-in-test"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>
                <b>{currentPage}</b>
              </span>
              <button
                className="button-in-test"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={endIndex <= (itemsPerPage * (currentPage - 1)) + testCards.length}
              >
                Next
              </button>
            </div> */}
          <div className="flex flex-row justify-end items-center space-x-2 mb-4">
            <button
              className="bg-[#171111] text-white hover:bg-[#171111] disabled:bg-[#171111] text-xl"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img
                src={LeftArrow}
                alt=">"
                className="h-5"
              />
            </button>
            <span>
              <p className="text-white text-2xl pt-2">{currentPage}</p>
            </span>
            <button
              className="bg-[#171111] hover:bg-[#171111] disabled:bg-[#171111] text-white text-xl"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={endIndex <= (itemsPerPage * (currentPage - 1)) + testCards.length}
            >
              <img
                src={RightArrow}
                alt=">"
                className="h-5" />
            </button>
          </div>

        </div>
      </div>
    </div>

  );
};

export default Tests;
