import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {
  FcManager,
  FcBusinessman,
  FcDepartment,
  FcHome,
  FcOvertime,
} from "react-icons/fc";
import {
  BsCurrencyDollar,
  BsBookmarkFill,
  BsReverseLayoutTextWindowReverse,
} from "react-icons/bs";
import "./Submissions.css";
import Sidebar from "../sidebar/Sidebar";
import { useSelector } from 'react-redux';
import useSession from "../session/useSession";
import ConsultantFilter from "./ConsultantFilter";
import RightArrow from "../assets/RightArrow.svg"
import LeftArrow from "../assets/LeftArrow.svg"
import marketerImg from "../assets/Marketer.svg"
import rateImg from "../assets/Rate.svg"
import SearchIcon from "../assets/Search_Icon.png"
import Interview_header_img from "../assets/Interview_header_img.png"
import Consultant_header_img from "../assets/Consultant_header_img.png"
import Vendor_header_img from "../assets/Vendor_header_img.png"
import Client_header_img from "../assets/Client_header_img.png"
import Time_header_img from "../assets/Time_header_img.png"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MarketerFilter from "./MarketerFilter";


export const Submissions = () => {
  useSession();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [consKey,setConsKey]=useState(0);
  const [marskey,setMarsKey]=useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [submissionCards, setSubmissionCards] = useState([]);
  const [endIndex, setEndIndex] = useState(0);
  const history = useHistory();
  const location=useLocation();
  const jsonData = useSelector((state) => state.user);
  const [searchTerm, setSearchTerm] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const [requirementsType, setRequirementsType] = useState("");
  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const [selectedMarketer, setSelectedMarketer] = useState(null);

  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: true,
    openInterview: false,
    openTest: false,
    openRequirements: false,
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const itemsPerPage = 10;

  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlConsultants = `${process.env.REACT_APP_API_URL}/marketing/submission/`;
      axios.get(apiUrlConsultants, {
        params: {
          page: currentPage,
          page_size: itemsPerPage,
          search: searchTerm,
          consultant_id: location.state?.consultant_id || selectedConsultant ,
          marketer_id: selectedMarketer,
          requirements_type: requirementsType,
          start_date:location.state?.start_date,
          end_date:location.state?.end_date
        },
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        console.log(response)
        setSubmissionCards(response.data.results);
        console.log(response.data.results)
        setEndIndex(response.data.total_count);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [currentPage, jsonData, searchTerm, isMounted, selectedConsultant, selectedMarketer, requirementsType]);

  const handleSubmission = (id) => {
    history.push(`/submissions/submission/${id}`);
  };
  const clearFilter = () => {
    console.log(consKey,marskey)
    setMarsKey(mKey => mKey + 1);
    setConsKey(prevKey => prevKey + 1);

    console.log(consKey,marskey)

    setRequirementsType("")
    setSelectedConsultant(null);
    setSelectedMarketer(null);
    // setStatusValue("");
    setSearchTerm("");
    setCurrentPage(1);
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleClearSearchTerm = () => {
    setSearchTerm("");
  };

  const handleConsultantChange = () => {
    setCurrentPage(1);
  };
  const handleMarketerChange = () => {
    setCurrentPage(1);
  };
  const parseDate = (dateString) => {
  // Replace 'EST' with '-0500' for proper parsing by Date object
  return new Date(dateString.replace('EST', '-0500'));
};
  console.log(submissionCards);

  return (
    <div className="submissions-page">
      <div className="sidebar-submissions">
        <Sidebar set_map={set_map} />
      </div>
      <div className="submissions-page-container">
        <div className="text-white justify-center items-start px-3 py-2 mt-4 ml-4 text-2xl font-bold tracking-widest uppercase whitespace-nowrap color rounded-l-[43px] text-zinc-100">
          Submission
        </div>

        <div className="flex gap-2 justify-between text-xl font-semibold text-zinc-400 max-md:flex-wrap mb-2 ml-5 pr-4">
          <div className="flex text-xl font-semibold text-zinc-400 max-md:flex-wrap mb-4 mt-3 ml-5 ">
            <div className="flex flex-col justify-center whitespace-nowrap text-fuchsia-50 text-opacity-50 w-40">
              <div className="flex items-center justify-start px-4 color border border-solid border-indigo-700 rounded-full searchSubmission">
                <img
                  loading="lazy"
                  src={SearchIcon}
                  className="h-5 w-5 text-indigo-300"
                />
                <input
                  type="text"
                  placeholder="Search"
                  className="color text-white placeholder-indigo-300 outline-none border-none"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex py-1">
            <div className="flex flex-col my-auto">
              <div>
                <select
                  className="w-full border-none outline-none"
                  style={{ backgroundColor: "#171111", color: "#B2B2B2" }}
                  value={requirementsType}
                  onChange={(e) => {
                    setRequirementsType(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Select Requirement</option>
                  <option value="W2">W2</option>
                  <option value="C2C">C2C</option>
                  <option value="full_time">Full Time</option>
                </select>
              </div>
            </div>
          </div>
          <div className="flex align-items-center py-1">
            <ConsultantFilter key={consKey} onSelect={setSelectedConsultant} onClearSearchTerm={clearFilter} onConsultantChange={handleConsultantChange} />
          </div>
          <div className="flex align-items-center py-1">
            <MarketerFilter key={marskey} onSelect={setSelectedMarketer} onClearSearchTerm={clearFilter} onMarketerChange={handleMarketerChange} />
          </div>
          <div className="flex align-items-center py-1">
            <button
            className="bg-[#b4a4f5] text-center text-slate-800 font-medium  hover:bg-[#6c48fd] hover:text-white w-max  min-w-[110px] max-w-full font-sans "
            onClick={clearFilter}
          >Clear</button>
          </div>
        </div>
        
        {/* max-md:flex-wrap justify-between flex gap-5 */}
        <div className=" text-lg text-white  ">
          <div className="flex gap-5  px-5 font-bold  grid grid-cols-4 max-md:flex-wrap  max-md:max-w-full">
            <div className="flex flex-row gap-2 p-2.5 justify-content-centers">
              <img
                loading="lazy"
                src={Consultant_header_img}
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <div>Consultant info</div>
            </div>
            <div className="flex flex-row gap-2 p-2.5 justify-content-center">
              <img
                loading="lazy"
                src={Vendor_header_img}
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <div>Vendor info</div>
            </div>
            <div className="flex flex-row gap-2 p-2.5 justify-content-center">
              <img
                loading="lazy"
                src={Client_header_img}
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <div>Client</div>
            </div>
            <div className="flex flex-row gap-2 p-2.5 font-medium justify-content-center">
              <img
                loading="lazy"
                src={Time_header_img}
                className="shrink-0 my-auto aspect-square w-[21px]"
              />
              <div>Submission Details</div>
            </div>
          </div>
        </div>



        <div className="submissions-filter">
          <div className="submissions-card w-100 ml-4 ">
            <ul>
              {submissionCards.sort((a, b) => b.id - a.id).map((submissionCard) => (
                <li
                  key={submissionCard.id}
                  className="submissions-box relative"
                  onClick={
                    jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role)) ||
                      submissionCard?.marketer_id === jsonData?.result.id
                      ? () => handleSubmission(submissionCard.id)
                      : null
                  }
                >
                  <div className="color text-white grid grid-cols-4 divide-x divide-white-600 p-2 rounded w-full">
                    {/* Name Section */}
                    <div className="flex flex-col justify-center items-center">
                      <span className="font-bold">
                        {submissionCard.consultant_name}
                      </span>
                      <span className="font-bold">
                        {submissionCard.job_title}
                      </span>
                      <p className="font-bold flex gap-1 mt-2 items-center justify-center">
                        <img src={marketerImg} className="mt-0.5" alt="" height="20px" width="20px" />
                        <span>
                          {submissionCard?.marketer_name}
                        </span>
                      </p>
                    </div>
                    {/* Vendor Name Section */}
                    <div className="flex flex-col justify-center items-center pl-2">
                      <span className="font-bold">{submissionCard.company_name}</span>
                      <span className="font-bold flex gap-1">
                        <img src={rateImg} className="mt-0.5" alt="" height="20px" width="15px" />
                        {submissionCard.rate}</span>
                    </div>
                    {/* Company Name Section */}
                    <div className="flex flex-col justify-center items-center pl-2">
                      <span className="font-bold">{submissionCard.client}</span>
                    </div>
                    {/* Submission Details Section */}
                    <div className="flex flex-col justify-center items-center pl-2">
                      <span className="font-bold">{submissionCard.created}</span>
                      <span className="font-bold">{submissionCard.documents_shared}</span>
                    </div>
                  </div>
                  {window.innerWidth <= 600 ? null : (
                    <div
                      className={`absolute submission-flag  ${submissionCard.rate !== 0 && submissionCard.client ? "complete" : ""
                        }`}
                    >
                      <BsBookmarkFill />
                    </div>
                  )}
                </li>
              ))}
            </ul>
            <div className="flex flex-row justify-end items-center space-x-2 mb-4">
              <div className="flex gap-2 justify-between">
                <div className="flex flex-row justify-end items-center space-x-2 mb-4">
                  <button
                    className="bg-[#171111] text-white hover:bg-[#171111] disabled:bg-[#171111] text-xl"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <img
                      src={LeftArrow}
                      alt=">"
                      className="h-5"
                    />
                  </button>
                  <span>
                    <p className="text-white text-2xl pt-2">{currentPage}</p>
                  </span>
                  <button
                    className="bg-[#171111] hover:bg-[#171111] disabled:bg-[#171111] text-white text-xl"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={endIndex <= (itemsPerPage * (currentPage - 1)) + submissionCards.length}
                  >
                    <img
                      src={RightArrow}
                      alt=">"
                      className="h-5" />
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
{/* <button
                className="button-in-submission "
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>
                <b>{currentPage}</b>
              </span>
              <button
                className="button-in-submission "
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={endIndex <= (itemsPerPage * (currentPage - 1)) + submissionCards.length}
              >
                Next
              </button> */}