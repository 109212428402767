import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import './AddScreening.css';
import Sidebar from '../sidebar/Sidebar';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlashMessage } from '../flashmessage/FlashMessage';
import useSession from "../session/useSession";
import {useLocation} from 'react-router-dom';
const AddScreening = () => {
  useSession();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Create an object to store the parsed parameters
  const queryParams = {};

  // Iterate through each parameter and store them in the object
  for (const [key, value] of searchParams.entries()) {
    // Replace '+' with space and decode the value
    queryParams[key] = decodeURIComponent(value.replace(/\+/g, ' '));
  }

  // Extract individual parameters from the queryParams object
  console.log(queryParams)
  // console.log('consultant:', consultant);
  // console.log('marketer:', marketer);
  // console.log('vendor:', vendor);
  // console.log('client:', client);
  const vendor_choices = [
    { id: "vendor", value: "Vendor" },
    { id: "account_manager", value: "Account Manager" },
    { id: "client_hiring", value: "Client Hiring" },
    { id: "Technical", value: "Technical" },
  ]

  const screening_status_choice = [
    { id: "failed", value: "Failed" },
    { id: "passed", value: "Passed" },
    { id: "didn't_happen", value: "Didn't Happen" },
    { id: "scheduled", value: "Scheduled" },
  ]
  // const [PrimaryMarketerList, setPrimaryMarketerList] = useState([]);
  
  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: false,
    openRequirements: false,
  };
  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const [guestValue, setGuestValue] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [screeningType, setScreeningType] = useState('');
  const [calling_details, setCallDetails] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [additional_details, setAdditionalDetails] = useState('');
  const [status, setstatus] = useState('');
  const [GuestList, setGuestList] = useState([]);
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const [feedback_remark, setFeedbackRemark] = useState('');
  useEffect(() => {
    setIsMounted(true);
  }, []);
  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000);
  };
  const [validationErrors, setValidationErrors] = useState({
    screeningType: '',
    job_title: '',
    guest: '',
    start_time: '',
    end_time: '',
    calling_details: '',
    additional_details: '',
    status: '',

  });
  const validateForm = () => {
    const errors = {
      screeningType: '',
      jobTitle: '',
      start_time: '',
      end_time: '',
      status: '',
    };
    if (!screeningType) {
      errors.screeningType = 'Screening Type is required';
    }
    if (!jobTitle) {
      errors.jobTitle = 'Feedback Status is required';
    }
    if (!startTime) {
      errors.start_time = 'Start Time is required';
    }
    if (!endTime) {
      errors.end_time = 'End Time is required';
    } else if (endTime <= startTime) {
      errors.end_time = 'End Time must be after Start Time';
    }

    if (!status) {
      errors.status = 'Screening Status is required';
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => !error);
  };

  const handleGuestChange = (e) => {
    const { options } = e.target;
  
  // Get an array of selected guest full names
  const selectedGuestFullNames = Array.from(options)
    .filter(option => option.selected)
    .map(option => option.value);
  
  // Update the state with the selected guest full names
  
  setGuestValue(selectedGuestFullNames[0]);
    
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'screening_type':
        setScreeningType(value);
        break;
      case 'job_title':
        setJobTitle(value);
        break;
      case 'calling_details':
        setCallDetails(value);
        break;
      case 'additional_details':
        setAdditionalDetails(value);
        break;
      case 'status':
        setstatus(value);
        break;
      case 'feedback_remark':
        setFeedbackRemark(value);
        break;
      default:
        break;
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;

    if (name === 'start_time' || name === 'end_time') {
      parsedValue = value ? new Date(value + 'Z') : null;
    }

    switch (name) {
      case 'start_time':
        setStartTime(parsedValue);
        break;
      case 'end_time':
        setEndTime(parsedValue);
        break;
      default:
        break;
    }
  };


  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlUsers = `${process.env.REACT_APP_API_URL}/employee/all_user_list/`;

      axios.get(apiUrlUsers, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        setGuestList(response.data.results.marketers);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [history, jsonData, isMounted]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const apiUrlSubmitScreening = `${process.env.REACT_APP_API_URL}/marketing/screenings/`;
    
    const submitData = {
      screening_type: screeningType,
      guest: guestValue,
      status: status,
      start_time: startTime,
      end_time: endTime,
      additional_details: additional_details,
      calling_details: calling_details,
      feedback_remark: feedback_remark,
      job_title: jobTitle,
      consultant_name:queryParams.consultant,
      marketer:queryParams.marketer,
      client:queryParams.client,
      vendor_name:queryParams.vendor,
    };
    console.log(submitData)
    try {
      const response = await axios.post(apiUrlSubmitScreening, submitData, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        },
      });
      displayFlashMessage('Screening Added Successfully', 'success');
      setTimeout(() => {
        history.push(`/screening`);
      }, 1000);
    } catch (error) {
      console.log(submitData)
      displayFlashMessage('Screening Addition Failed', 'error');
      console.error('Error updating Screening:', error);
    }
  };

  return (
    <div >
      <div>
        <Sidebar set_map={set_map} />
      </div>
      <div className="justify-center items-start px-3 py-2 text-2xl font-bold tracking-widest leading-9 uppercase whitespace-nowrap color rounded-l-[43px] text-zinc-100 max-md:px-5 mt-4 mb-4  ml-[23%] ">
                Add Screening
        </div>
      <div className="test-form-container">
        {flashMessage && (
          <FlashMessage message={flashMessage} type={flashMessageType} />
        )}
        {/* <h2>Test Details</h2> */}
        <form onSubmit={handleSubmit}>
          <div className="form-grid">
          <div className="form-group">
          <label>Screening Type:</label>
              <select placeholder='Screening Type' name="screening_type" value={screeningType} onChange={handleChange} >
                <option value="">Select Screening Type</option>
                {vendor_choices.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.screeningType}</div>
            </div>
            <div className="form-group" >
            <label>Job Title:</label>
              <textarea
              className='bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                placeholder='Job title'
                name="job_title"
                value={jobTitle}
                onChange={handleChange}
                rows={1}
              />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.job_title}</div>
            </div>
            <div className="form-group">
            <label>Guest:</label>
              <select
                className='bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                placeholder='Guest'
                id="guest"
                name="guest"
                value={guestValue}
                onChange={handleGuestChange}
              >
                <option value="" disabled>Select a guest</option>
                {GuestList.map((guest) => (
                  <option key={guest.id} value={guest.full_name}>
                    {guest.full_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
            <label>Start Time:</label>
                <input placeholder='Start Time' type="datetime-local" name="start_time" value={startTime instanceof Date ? startTime.toISOString().slice(0, 16) : ''} onChange={handleDateChange} />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.start_time}</div>
            </div>
            <div className="form-group">
            <label>End Time:</label>
              <input placeholder='End Time' type="datetime-local" name="end_time" value={endTime instanceof Date ? endTime.toISOString().slice(0, 16) : ''} onChange={handleDateChange} />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.end_time}</div>
            </div>
            <div className="form-group">
            <label>Calling Details:</label>
              <textarea
              className='bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                placeholder='Calling Details'
                name="calling_details"
                value={calling_details}
                onChange={handleChange}
                rows={1}
              />
            </div>
            <div className="form-group">
            <label>Additional Details:</label>
              <textarea
              className='bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                placeholder='Additional Details'
                name="additional_details"
                value={additional_details}
                rows={1}
                onChange={handleChange}
              />
            </div>
            <div className="form-group" >
            <label>Screening Status:</label>
              <select className='bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white' placeholder='Status' name="status" value={status} onChange={handleChange}>
                <option value="">Select Screening Status</option>
                {screening_status_choice.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.status}</div>
            </div>
            <div className="form-group">
            <label>Remarks:</label>
              <textarea
              className='bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white'
                placeholder='Remarks'
                name="feedback_remark"
                value={feedback_remark}
                onChange={handleChange}
                rows={1}
              />
            </div>
          </div>
          <button type="submit" className="test-button">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default AddScreening;
