import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './LoginForm.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../actions/action';
import LoginPage from '../assets/Technman_Logo.png'
function InputField({ label, type, id, placeholder, value, functionToCall }) {
  return (
    <>
      <label htmlFor={id} className="sr-only">
        {label}
      </label>
      <input
        type={type}
        id={id}
        value={value}
        onChange={functionToCall}
        placeholder={placeholder}
        className="justify-center p-2.5 mt-6 whitespace-nowrap bg-white rounded-lg shadow-sm text-black  max-md:max-w-full inputColor"
        aria-label={label}
      />
    </>
  );
}
const LoginForm = () => {


  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginDisable, setLoginDisable] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const history = useHistory();
  const jsonData = useSelector((state) => state.user);
  const [emailError, setEmailError] = useState("");
  const isValidEmail = (username) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(username);
  };
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setUsername(enteredEmail);

    if (!isValidEmail(enteredEmail)) {
      setLoginDisable(true);
      setEmailError("Invalid email address.");
    } else {
      setLoginDisable(false);
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  useEffect(() => {
    if (isAuth) {
      const profile_check = jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role))
      const queryParam = {
        search_profile: profile_check ? "global" : "my"
      }
      history.push({
        pathname: "/dashboard",
        search: `?${new URLSearchParams(queryParam).toString()}`
      });
    }
  }, [isAuth, history]);

  const showPopupMessage = (message) => {
    setPopupMessage(message);
    setShowPopup(true);
  };

  const hidePopup = () => {
    setShowPopup(false);
    setPopupMessage('');
  };
  const dispatch = useDispatch();
  const handleLogin = (e) => {
    e.preventDefault();
    const apiUrl = `${process.env.REACT_APP_API_URL}/employee/authenticate/`;

    axios.post(apiUrl, {
      employee_id: username,
      password: password,
    })
      .then((response) => {
        setIsAuth(true);
        const jsonData = response.data;
        const tokenExpiry = new Date(jsonData.token_expiration);
        localStorage.setItem('access_token', jsonData.result.token);
        localStorage.setItem('token_expiry', tokenExpiry);
        localStorage.setItem('marketing_email', JSON.stringify(jsonData.result.marketing_email));
        localStorage.setItem('marketing_mobile', JSON.stringify(jsonData.result.marketing_mobile));
        dispatch(setUser(jsonData));
      })
      .catch(() => {
        showPopupMessage('Login failed. Please check your credentials.');
      }).finally(() => {
        setLoginDisable(true);
        setUsername('');
        setPassword('');
      });
  };


  return (
    <div className='w-full h-[100vh] flex justify-content-center align-items-center'>
      <div className="flex w-[470px]">
        <div className="flex flex-col grow justify-center p-6 w-[500px] text-xl font-semibold bg-white rounded-3xl shadow-2xl text-zinc-600 max-md:px-5 max-md:mt-10 ">
          <img
            loading="lazy"
            // src="https://cdn.builder.io/api/v1/image/assets/TEMP/3ef667b8c089ce43cad08dc00c302f8fede7c8e1a140f88a56a233cc25bc6dc1?apiKey=c0a0bb8a81bb4557be57989c8417b01c&"
            src={LoginPage}
            alt="Placeholder"
            className="w-full aspect-[1.61] max-md:max-w-full"
          />
          <p className="text-base max-md:max-w-full">
            Welcome back! Please enter your details.
          </p>
          <form onSubmit={handleLogin}>
            <InputField
              label="Username"
              type="text"
              id="username"
              placeholder="Username"
              value={username}
              functionToCall={handleEmailChange}
            />
            <InputField
              label="Password"
              type="password"
              id="password"
              value={password}
              functionToCall={handlePasswordChange}
              placeholder="Password"
            />
            <div className='w-100'>
              <button
                type="submit"
                className="justify-center w-100 p-2.5 mt-4 text-center text-white whitespace-nowrap bg-violet-500 rounded-md shadow-lg max-lg:max-w-full"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
      {showPopup && (
      <div className="popup">
        <div className="popup-content">
          <span className="close" onClick={hidePopup}>
            &times;
          </span>
          <p>{popupMessage}</p>
        </div>
      </div>
    )}
    </div>
  );
};

export default LoginForm;
