import React, { useState, useEffect, } from 'react';
import Sidebar from '../sidebar/Sidebar';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FlashMessage } from '../flashmessage/FlashMessage';
import useSession from '../session/useSession';
import { setUser } from '../../actions/action';
import './PersonalInfoUpdation.css'

const PersonalInfoUpdation = () => {
    useSession();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const full_name = searchParams?.get('full_name');
    const gender = searchParams?.get('gender');
    const date_joined = searchParams?.get('date_joined');
    const marital_status = searchParams?.get('marital_status');
    const userLocation = searchParams?.get('userLocation');
    const email = searchParams?.get('email');
    const phone = searchParams?.get('phone')
    const company_accessory = searchParams?.get('company_accessory')
    const employee_id = searchParams?.get('employee_id')
    const dob = searchParams?.get('dob')
    const docs = searchParams?.get('docs')
    const comments = searchParams?.get('comments');
    const [flashMessageType, setFlashMessageType] = useState(null);
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [clienterror, setclientError] = useState("");
    const [rateerror, setrateError] = useState("");
    const [flashMessage, setFlashMessage] = useState(null);
    const [updateDisable, setupdateDisable] = useState(false);
    const [generalError, setGeneralError] = useState("");

    const [formData, setFormData] = useState({
        full_name: full_name,
        gender: gender,
        date_joined: date_joined,
        marital_status: marital_status,
        location: userLocation,
        email: email,
        phone: phone,
        company_accessory: company_accessory,
        employee_id: employee_id,
        dob: dob,
    });
    const displayFlashMessage = (message, type) => {
        setFlashMessage(message);
        setFlashMessageType(type);
        setTimeout(() => {
            setFlashMessage(null);
            setFlashMessageType(null);
        }, 1000);
    };
    const set_map = {
        showDashboard: false,
        showCandidate: false,
        showMarketing: false,
        showResume: false,
        openMy: false,
        openGlobal: false,
        openLogs: false,
        openAdmin: false,
        openCandidateTable: false,
        openSubmission: true,
        openInterview: false,
        openTest: false,
        openRequirements: false,
    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
        switch (name) {
            case 'email':
                setEmailError("");
                break;
            default:
                break;
        }
        setGeneralError("");

    };
    const handlePhoneChange = (e) => {
        const enteredPhoneNumber = e.target.value;
        const { name, value } = e.target;
        // const cleanedPhoneNumber = enteredPhoneNumber.replace(/\D/g, '');
        // const formattedPhoneNumber = formatPhoneNumber(cleanedPhoneNumber);
        setFormData({
            ...formData,
            [name]: enteredPhoneNumber,
        });
        if (!isValidPhoneNumber(enteredPhoneNumber)) {
            setPhoneError("Invalid phone number.");
        } else {
            setPhoneError("");
        }
    };

    const formatPhoneNumber = (phoneNumber) => {
        const country = phoneNumber.slice(0, 3);
        const firstPart = phoneNumber.slice(3, 6);
        const secondPart = phoneNumber.slice(6, 10);

        return `(${country})-${firstPart}-${secondPart}`;
    };
    const jsonData = useSelector((state) => state.user);
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        setIsMounted(true);
    }, []);

    const dispatch = useDispatch();
    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;
        const enteredPhoneNumber = formData.phone;

        if (enteredPhoneNumber === '') {
            isValid = false;
            setPhoneError('*required');
        }
        const enteredEmail = formData.email;
        if (enteredEmail === '') {
            isValid = false;
            setEmailError('*required')
        }
        else if (!isValidEmail(enteredEmail)) {
            setEmailError("Invalid email address.");
        } else {
            setEmailError("");
        }

        if (!isValid) {
            setGeneralError("Please enter the details in correct format .");
            return;
        }
        setGeneralError("");
        // /${employee_id}

        const apiUrlUsers = `${process.env.REACT_APP_API_URL}/employee/update/`;
        axios.put(apiUrlUsers, {
            email: formData.email,
            phone: formData.phone,
            full_name: formData.full_name,
            gender: formData.gender,
            // date_joined: formData.date_joined,
            marital_status: formData.marital_status,
            location: formData.location,
            company_accessory: formData.company_accessory,
            employee_id: formData.employee_id,
            dob: formData.dob,
        }, {
            headers: {
                Authorization: `Token ${jsonData?.result.token}`,
            }
        }).then(response => {
            // console.log(response);
            const jsonData = response.data;
            const tokenExpiry = new Date(jsonData.token_expiration);
            displayFlashMessage('Profile Updated Successfully', 'success');
            localStorage.setItem('access_token', jsonData.result.token);
            localStorage.setItem('token_expiry', tokenExpiry);
            dispatch(setUser(jsonData));
            setTimeout(() => {
                history.push(`/userprofile`);
            }, 1000);
        }).catch(error => {
            displayFlashMessage('Your Profile is Not Updated Successfully', 'error');
            console.error('Error fetching data:', error);
        });
    };
    const isValidPhoneNumber = (phoneNumber) => {
        const phoneRegex = /^\d+$/;
        // const phoneRegex = /^\d{10}$/;
        // regex=
        return phoneRegex.test(phoneNumber);
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <div className="submission-update-page">
            <div className="sidebar-submissionupdate">
                <Sidebar set_map={set_map} />
            </div>
            <div className="bg-[#292256] rounded-l-full ml-[300px] my-5">
                    <h1 className="text-2xl text-white font-semibold pl-6 py-2">Personal Info</h1>
                </div>
            <div className="relative mx-[250px]">
                {flashMessage && (
                    <FlashMessage message={flashMessage} type={flashMessageType} />
                )}
                {generalError && (
                    <div
                        style={{ color: "red", fontSize: "14px" }}
                        className="error-message"
                    >
                        {generalError}
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 mx-16 my-4 gap-20">
                        <div className="form-group">
                            <label>Email </label>
                            <input type="text" name="email" value={formData.email} onChange={handleChange}
                                className='disabledInput'
                                disabled />

                            {emailError && (
                                <div
                                    style={{ color: "red", fontSize: "14px" }}
                                    className="error-message"
                                >
                                    {emailError}
                                </div>
                            )}
                        </div>

                        <div className="form-group">
                            <label>Phone</label>
                            <input type="text" name="phone" value={formData.phone} onChange={handlePhoneChange}
                                placeholder="XXXXX-XXXXX" />
                            {phoneError && (
                                <div
                                    style={{ color: "red", fontSize: "14px" }}
                                    className="error-message"
                                >
                                    {phoneError}
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label>Full Name:</label>
                            <input type="text" name="full_name" value={formData.full_name} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Gender :</label>
                            <select name="gender" id="gender" onChange={handleChange} value={formData.gender}>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </select>
                            {/* <input type="text" name="gender" value={formData.gender} onChange={handleChange} /> */}
                        </div>

                        <div className="form-group">
                            <label>Date Joined:</label>
                            <input type="date" name="date_joined" value={new Date(formData.date_joined).toISOString().substring(0, 10)} onChange={handleChange} />

                        </div>
                        <div className="form-group">
                            <label>Marital Status:</label>
                            <select name="marital_status" id="marital_status" onChange={handleChange} value={formData.marital_status}>
                                <option value="single">Single</option>
                                <option value="married">Married</option>
                            </select>
                            {/* <input type="text" name="marital_status" value={formData.marital_status} onChange={handleChange} /> */}
                        </div>
                        {/* <div className="form-group">
                            <label>Location:</label>
                            <input type="text" name="userLocation" value={formData.userLocation} onChange={handleChange} />
                        </div> */}
                        <div className="form-group">
                            <label> Company Accessory :</label>
                            <input type="text" name="company_accessory" value={formData.company_accessory} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Employee id :</label>
                            <input type="text" name="employee_id" value={formData.employee_id}
                                disabled
                                className='disabledInput'
                            // onChange={handleChange} 
                            />
                        </div>
                        <div className="form-group">
                            <label> DOB :</label>
                            <input type="date" name="dob" value={formData.dob} onChange={handleChange} />
                        </div>
                    </div>
                    <button type="submit" className="ml-16 hover:bg-[#292256] mb-4" disabled={updateDisable}>Save Changes</button>

                </form>
            </div>
        </div>
    );
}

export default PersonalInfoUpdation