import React, { useState, useEffect,useRef } from "react";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BiCaretDown } from "react-icons/bi";
import { useHistory } from 'react-router-dom';
import {
  FcManager,
  FcBusinessman,
  FcDepartment,
  FcHome,
  FcOvertime,
  FcGraduationCap,
  FcReadingEbook,
  FcSlrBackSide,
  FcCheckmark,
  FcClock,
  FcCancel,
} from "react-icons/fc";
import { AiFillSchedule } from "react-icons/ai";
import { BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import "./Screening.css";
import Sidebar from "../sidebar/Sidebar";
import useSession from "../session/useSession";
import SearchIcon from "../assets/SearchIcon.svg"
import RightArrow from "../assets/RightArrow.svg"
import LeftArrow from "../assets/LeftArrow.svg"
import Status from "../interviews/Status";
import Interview_header_img from "../assets/Interview_header_img.png"
import Consultant_header_img from "../assets/Consultant_header_img.png"
import Vendor_header_img from "../assets/Vendor_header_img.png"
import Client_header_img from "../assets/Client_header_img.png"
import Time_header_img from "../assets/Time_header_img.png"


const Screening = () => {
  useSession();
  const [selectedStatusValue, setSelectedStatusValue] = useState("");
  const [selectedFeedbackStatusValue, setSelectedFeedbackStatusValue] = useState("");
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isFeedbackStatusDropdownOpen, setIsFeedbackStatusDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [screeningCards, setScreeningCards] = useState([]);
  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const [endIndex, setEndIndex] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedFeedbackStatus, setSelectedFeedbackStatus] = useState("");
  const history = useHistory();
  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: false,
    openRequirements: false,
    openScreening: true,
  };
  const filters = [
    { id: "failed", value: "Failed" },
    // { id: "cancelled", value: "Cancelled" },
    { id: "passed", value: "Passed" },
    { id: "scheduled", value: "Scheduled" },
    {id : "didnt_happen" ,value:"Didn't Happen"}
  ];
  const feedback_filters = [
    { id: "passed", value: "Passed" },
    { id: "failed", value: "Failed" },
    { id: "feedback_due", value: "Due" },
    // { id: "feedback_done", value: "Done" },
  ]
  const statusMappings = {
    passed: 'Passed',
    failed: 'Failed',
    didnt_happen:"Didn't Happen",
    scheduled:"Scheduled"
    
  };
  const feedback_statusMappings = {
    // feedback_done: 'Feedback Done',
    passed: 'Passed',
    failed: 'Failed',
    didnt_happen: "Didn't Happen",
    scheduled:"Scheduled"

  }
  const roundMappings = {
    text_based: 'Text Based',
    video_based: 'Video Based',
  }
  const [searchTerm, setSearchTerm] = useState('');
  const statusDropdownRef = useRef(null);
  useEffect(() => {
    // Function to handle clicks outside of the dropdown
    const handleClickOutside = (event) => {
      if (
        statusDropdownRef.current &&
        !statusDropdownRef.current.contains(event.target)
      ) {
        setIsStatusDropdownOpen(false);
      }
    };

    // Add event listener to detect clicks outside of the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup: Remove event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [statusDropdownRef]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const itemsPerPage = 10;
  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlConsultants = `${process.env.REACT_APP_API_URL}/marketing/screenings`;
      axios.get(apiUrlConsultants, {
        params: {
          page: currentPage,
          page_size: itemsPerPage,
          status: selectedStatus,
          feedback_status: selectedFeedbackStatus,
          search: searchTerm,
        },
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        console.log(response)
        setScreeningCards(response.data.result);
        setEndIndex(response.data.total);
        // console.log(response.data.total)
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [currentPage, jsonData, searchTerm, selectedStatus, selectedFeedbackStatus, isMounted]);


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleStatusOptionClick = (option) => {
    setSelectedStatusValue(option.value);
    setIsStatusDropdownOpen(false);
    setSelectedStatus(option.id);
  };

  const handleFeedbackStatusOptionClick = (option) => {
    setSelectedFeedbackStatusValue(option.value);
    setIsFeedbackStatusDropdownOpen(false);
    setSelectedFeedbackStatus(option.id);
  };

  const handleClearFilter = (e) => {
    setSelectedStatusValue("");
    setSelectedStatus("");
    setSelectedFeedbackStatusValue("");
    setSelectedFeedbackStatus("");
    setSearchTerm("");
    setCurrentPage(1);
    setIsStatusDropdownOpen(false);
    setIsFeedbackStatusDropdownOpen(false);
  }

  const handleScreeningUpdate = (id) => {
    history.push(`/screening/${id}`);
  }

  const handleScreeningDetails = (id) => {
    history.push(`/screening/ScreeningDetailsPage/${id}`);
  }
  const formatTime = (timeString) => {
    return timeString?.slice(0, -4).replace('T', ' ').replace('Z', '');
  };
  console.log(screeningCards)
  return (
    <div className="screening-page bg-neutral-900 text-white">
      <div>
        <Sidebar set_map={set_map} />
      </div>
      <div className="screening-page-container bg-neutral-900 text-white">
        <header className="flex flex-col font-bold tracking-widest uppercase bg-neutral-900 pl-5">
          <h1 className="z-10 justify-center items-start px-4 py-2 bg-indigo-950 rounded-[43px] max-md:px-5 max-md:max-w-full text-white mt-4">
            SCREENING
          </h1>
        </header>
        <div className=" toolbar flex text-xl font-semibold text-zinc-400 max-md:flex-wrap mt-3 ml-5">
          <div className="flex gap-5 justify-between text-xl font-semibold text-zinc-400 max-md:flex-wrap mb-4 my-2 ml-5">
            <div className="flex justify-center whitespace-nowrap text-fuchsia-50 text-opacity-50" style={{ width: "262px" }}>
              <div className="flex items-center justify-start px-4 py-1 w-full color border border-solid border-indigo-700 rounded-full max-md:px-5">
                <img
                  loading="lazy"
                  src={SearchIcon}
                  alt="Search icon"
                  className="aspect-square ml-4"
                />
                <input
                  type="text"
                  placeholder="Search Screenings"
                  className="search-input bg-transparent border-none px-2"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value)
                    setCurrentPage(1)
                  }}
                />
              </div>
            </div>
          </div>


          <div className="mx-3 my-2 justify-center ">
<Status setCurrentPage={setCurrentPage} filters={filters} selectedStatusValue={selectedStatusValue} setSelectedStatusValue={setSelectedStatusValue} handleStatusOptionClick={handleStatusOptionClick} />
          </div>
          <div className="flex align-items-center py-1 mb-3">
            <button
            className="bg-[#b4a4f5] text-center text-slate-800 font-medium  hover:bg-[#6c48fd] hover:text-white w-max  min-w-[110px] max-w-full font-sans "
            onClick={handleClearFilter}
          >Clear</button>
          </div>


        </div>
      </div>
      <div className="header-bar3 flex gap-5  px-5 font-bold  grid grid-cols-5 ">
        <div className="header-title flex flex-row gap-2 p-2.5 justify-content-centers">
          <img
            loading="lazy"
            src={Interview_header_img}
            className="header-icon shrink-0 my-auto w-6 aspect-square"
          />
          <div>Job Title</div>
        </div>
        <div className="header-title flex flex-row gap-2 p-2.5 justify-content-centers">
          <img
            loading="lazy"
            src={Consultant_header_img}
            className="header-icon shrink-0 my-auto w-6 aspect-square"
          />
          <div>Consultant</div>
        </div>
        <div className="header-title flex flex-row gap-2 p-2.5 justify-content-center">
          <img
            loading="lazy"
            src={Vendor_header_img}
            className="header-icon shrink-0 my-auto w-6 aspect-square"
          />
          <div>Vendor</div>
        </div>
        <div className="header-title flex flex-row gap-2 p-2.5 justify-content-center">
          <img
            loading="lazy"
            src={Client_header_img}
            className="header-icon shrink-0 my-auto w-6 aspect-square"
          />
          <div>Client</div>
        </div>
        <div className="header-title flex flex-row gap-2 p-2.5 font-medium justify-content-center">
          <img
            loading="lazy"
            src={Time_header_img}
            className="header-icon shrink-0 my-auto aspect-square w-[21px]"
          />
          <div>Time</div>
        </div>
      </div>

      <div className="screenings-filter bg-neutral-900 text-white">
        <div className="screenings-card  text-white w-100 ml-4">
          <ul>
            {screeningCards?.map((screeningCard) => (
              <li disabled={!(jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role))
              || screeningCard?.marketer_id === jsonData?.result.id)} onClick={() => handleScreeningDetails(screeningCard.id)} key={screeningCard.id} className="screenings-box " >
                <div className="screenings-box-upper bg-indigo-950 text-white">
                  {/* <div className="id-round-profile-coders">
                      <div className="id-round">
                        <span className="id" title="Test Id">
                          {testCard.id}
                        </span>
                      </div>
                      <div className="profile">
                        <span title="Profile" className="cursur-on-hover">
                          <FcGraduationCap />
                        </span>
                        <span className="consultant-box">
                          {testCard.job_title}
                        </span>
                      </div>
                      <div className="coders">
                        <span
                          title="Guest Assigned"
                          className="cursur-on-hover"
                        >
                          <FcReadingEbook />
                        </span>
                        <span className="coders-list">
                          <ul className="list">
                            {testCard.guest_names.slice(0, 3).map((guestName) => (
                              <li>{guestName}</li>
                            ))}
                            {testCard.guest_names.length > 3 && (
                              <div className="more-guests">+ {testCard.guest_names.length - 3} more</div>
                            )}
                          </ul>
                        </span>
                      </div>
                    </div> */}
                  <div className="consultant-marketer-box">
                    <span className="consultant-box">
                      <i title="Job Title">
                        <FcGraduationCap />
                      </i>
                      &nbsp;{screeningCard.job_title}
                    </span>

                    <span className="marketer-box">
                      <i title="Guest">
                        <FcReadingEbook />
                      </i>
                      &nbsp;{screeningCard.guest}
                    </span>
                  
                    {/* <button className="edit-button rounded-xl bg-indigo-950" disabled={!(jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role))
                      || screeningCard?.marketer_id === jsonData?.result.id)} onClick={() => handleScreeningUpdate(screeningCard.id)}>
                      Edit
                    </button> */}
                    {/* <button className="edit-button rounded-xl bg-indigo-950" disabled={!(jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role))
                      || screeningCard?.marketer_id === jsonData?.result.id)} onClick={() => handleScreeningDetails(screeningCard.id)}>
                      Show Details
                    </button> */}
                  </div>
                  <div className="consultant-marketer-box">
                    <span className="consultant-box"  
                    style={{overflow:"hidden"}}
                    >
                      <i title="Consultant">
                        <FcManager />
                      </i>
                      &nbsp;{screeningCard?.consultant_name}
                    </span>

                    <span className="marketer-box">
                      <i title="Marketer">
                        <FcBusinessman />
                      </i>
                      &nbsp;{screeningCard?.marketer}
                    </span>
                  </div>
                  <div className="vendor-requirement-box">
                    <span className="vendor-box">
                      <i title="Vendor">
                        <FcDepartment />
                      </i>
                      &nbsp;{screeningCard?.vendor_name}
                    </span>
                  </div>
                  <div className="client-testee-time">
                    <span className="client-box">
                      <i title="Client">
                        <FcHome />
                      </i>
                      &nbsp;{screeningCard?.client}
                    </span>
                    {window.innerWidth <= 600 ? null : (
                      <span className="time-box1" title="Time of Screening">
                        &nbsp;{formatTime(screeningCard.start_time)+ " EST"}
                      </span>
                    )}
                  </div>
                  <div className="Screening-call">
                    <span className="Screening-status-box ">
                      <i title="Screening Mode">
                        <FcSlrBackSide />
                      </i>{" "}
                      &nbsp; <span>{roundMappings[screeningCard.screening_type] || screeningCard.screening_type}</span>
                    </span>

                    <span className="test-status" title="Test Status">
                      {screeningCard.status === "passed" && <FcCheckmark />}
                      {screeningCard.status === "failed" && <FcCancel />}
                      {screeningCard.status === "cancelled" && <FcCancel />}
                      {screeningCard.status === "scheduled" && <AiFillSchedule />}
                      &nbsp;{statusMappings[screeningCard.status] || screeningCard.status}
                    </span>
                    <span className="test-status" title="Test Feedback Status">
                      {screeningCard.feedback_status === "feedback_due" && <FcClock />}
                      {screeningCard.feedback_status === "feedback_done" && <FcCheckmark />}
                      &nbsp;{feedback_statusMappings[screeningCard.feedback_status] || screeningCard.feedback_status}
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {/* <div className="pagination-i">
              <button
                className="button-in-test"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>
                <b>{currentPage}</b>
              </span>
              <button
                className="button-in-test"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={endIndex <= (itemsPerPage * (currentPage - 1)) + testCards.length}
              >
                Next
              </button>
            </div> */}
          <div className="flex flex-row justify-end items-center space-x-2 mb-4">
            <button
              className="bg-[#171111] text-white hover:bg-[#171111] disabled:bg-[#171111] text-xl"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img
                src={LeftArrow}
                alt=">"
                className="h-5"
              />
            </button>
            <span>
              <p className="text-white text-2xl pt-2">{currentPage}</p>
            </span>
            <button
              className="bg-[#171111] hover:bg-[#171111] disabled:bg-[#171111] text-white text-xl"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={endIndex <= (itemsPerPage * (currentPage - 1)) + screeningCards?.length}
            >
              <img
                src={RightArrow}
                alt=">"
                className="h-5" />
            </button>
          </div>

        </div>
      </div>
    </div>

  );
};

export default Screening;
