import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import axios from 'axios';
import "./consultant.css";
import Sidebar from "../../sidebar/Sidebar";
import useSession from "../../session/useSession";

export const ConsultantDetails = () => {
  useSession();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lead_id = searchParams?.get('lead_id');
  const company_id = searchParams?.get('company_id');
  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const [selectedConsultantId, setSelectedConsultantId] = useState(null);
  const [selectedConsultantProfileId, setSelectedConsultantProfileId] = useState(null);
  const [showNextSelect, setShowNextSelect] = useState(false);
  const [consultantList, setConsultantList] = useState([]);
  const [consultantProfileList, setConsultantProfileList] = useState([]);
  // const [selectedValue,setSelectedValue]=useState(null);

  const history = useHistory();

  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false, 
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: false,
    openRequirements: true,
  };

  useEffect(() => {
    setIsMounted(true); 
  }, []);

  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlConsultants = `${process.env.REACT_APP_API_URL}/candidate/candidate/`;
      axios.get(apiUrlConsultants, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        setConsultantList(response.data.results);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [jsonData, isMounted]);

  const handleConsultantChange = (event) => {
   const selectedValue=event.target.value;
    setSelectedConsultantId(selectedValue);
    if (!selectedValue) {
      setShowNextSelect(false);
      setSelectedConsultantProfileId(null);
      setConsultantProfileList([]);
    } else {
      const apiUrlConsultants = `${process.env.REACT_APP_API_URL}/candidate/candidate_profile/`;
      axios.get(apiUrlConsultants, {
        params: { 
          consultant_id: selectedValue,
        },
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        console.log("1234",response)
        setConsultantProfileList(response.data.results);
        setShowNextSelect(true);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  const handleConsultantProfileChange = (event) => {
   const selectedValue=event.target.value;
    setSelectedConsultantProfileId(selectedValue);
  }

  const handlePrevious = () => {
    history.push("/requirements/create-lead");
  };
  const handleNext = () => {
  
    const queryParam = {
      company_id: company_id,
      lead_id: lead_id,
      consultant_profile_id: selectedConsultantProfileId,
    }
    setTimeout(() => {
      history.push({
        pathname: "/requirements/marketing-details",
        search: `?${new URLSearchParams(queryParam).toString()}`
      });
    }, 1000);
  };
  return (
    <div className="consultant-details-page bg-neutral-900 text-white">
      <div className="sidebar-consultant-details">
        <Sidebar set_map={set_map} />
      </div>
      <div>
      <div className="bg-[#292256] rounded-l-full ml-5 mt-10">
          <h1 className="text-2xl text-white font-semibold pl-6 py-2">ADD REQUIREMENTS</h1>
      </div>
      <div
          className="mt-5 d-flex justify-content-center align-items-center flex-wrap"
          style={{ fontFamily: "Signika Negative" }}
        >
          <div className="flex-col">
            <div className="header-greens flex justify-center items-center rounded-full h-[50px] w-[50px] max-md:px-5 bg-emerald-700 stroke-[3px] border-white border-solid border-[1px]">1</div>
            <span className="mx-2">Create Lead</span>
          </div>
          <hr style={{ width: "370px", height:"3px", background:"#50C878", marginBottom:"23px", marginLeft:"-26px", marginRight:"-20px"}} />
          <div className="flex-col">
            <div className="header-greens flex justify-center items-center rounded-full h-[50px] w-[50px] max-md:px-5 bg-emerald-700  stroke-[3px] border-white border-solid border-[1px]">2</div>
            <span className="Span mx-2 w-[70px]">Consultant Details</span>
          </div>
          <hr style={{ width: "375px", height:"3px", background:"white", marginBottom:"23px", marginLeft:"-56px", marginRight:"-20px"}} />
          <div className="flex-col">
            <div className="header-greens flex justify-center items-center rounded-full h-[50px] w-[50px] max-md:px-5 bg-neutral-900 stroke-[3px] border-white border-solid border-[1px]">3</div>
            <span className="Span mx-2 w-[70px]">Marketing Details</span>
          </div>
        </div>
        <div className="container mt-5 bg-neutral-900 text-white">
          <div className="row">
            <div className="col-6">
              <div className="">
                <label htmlFor="consultantSelect" className="form-label">
                  Consultant
                </label>
                <select
                  className="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                  aria-label="Default select example"
                  id="consultantSelect"
                  onChange={handleConsultantChange}
                >
                  <option value="" selected>Select a consultant</option>
                  {consultantList.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
              {showNextSelect && (
                <div
                  className="mt-3"
                  id="nextSelectContainer"
                  style={{ display: "block" }}
                >
                  <label htmlFor="nextSelect" className="form-label">
                    Select Consultant Profile
                  </label>
                  <select
                    className="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    aria-label="Default select example"
                    id="nextSelect"
                    onChange={handleConsultantProfileChange}
                  >
                    <option value="" selected>Select a Consultant Profile</option>
                    {consultantProfileList.map((item) => (
                      <option value={item.id}>{item.title}</option> 
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 mb-5 d-flex justify-content-between">
          <button
            type="button"
            className="flex gap-1 px-3 py-2.5 text-white rounded-xl bg-[#292256]"
            style={{ marginLeft: "30px" }}
            onClick={handlePrevious}
          >
            Previous
          </button>
          <button
            type="button"
            className="flex gap-1 px-3 py-2.5 text-white rounded-xl bg-[#292256]"
            style={{ marginRight: "30px" }}
            onClick={handleNext}
            disabled={
              !lead_id || !company_id || !selectedConsultantProfileId
            }
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};