import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import './InterviewUpdate.css';
import Sidebar from '../sidebar/Sidebar';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlashMessage } from '../flashmessage/FlashMessage';
import useSession from "../session/useSession";
const InterviewUpdate = () => {
  useSession();
  const { id } = useParams();
  const history = useHistory();
  const interview_choices = [
    // { id: "screening", value: "Screening" },
    { id: "video_call", value: "Video Call" },
    { id: "telephonic", value: "Voice Call" }
  ]
  const interview_status_choice = [
    { id: "failed", value: "Failed" },
    { id: "cancelled", value: "Cancelled" },
    { id: "scheduled", value: "Scheduled" },
    { id: "next_round", value: "Next Round" },
    { id: "feedback_due", value: "Feedback Due" },
    { id: "cleared", value: "Cleared" }
  ]
  const duration_choices = [
    { id: "1.0", value: "1 hour" },
    { id: "1.5", value: "1.5 hours" },
    { id: "2.0", value: "2 hours" },
    { id: "2.5", value: "2.5 hours" },
    { id: "3.0", value: "3 hours" },
    { id: "3.5", value: "3.5 hours" },
    { id: "4.0", value: "4 hours" },
    { id: "4.5", value: "4.5 hours" },
    { id: "5.0", value: "5 hours" }
  ];

  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: true,
    openTest: false,
    openRequirements: false,
  };

  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const [interviewCards, setInterviewCards] = useState([]);
  const [ctbValue, setCtbValue] = useState('');
  const [guestValue, setGuestValue] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [duration, setDuration] = useState('');
  // const [endTime, setEndTime] = useState(null);
  const [interviewType, setInterviewType] = useState('');
  const [callDetails, setCallDetails] = useState('');
  const [details, setDetails] = useState('');
  const [interviewer_name, setinterviewer_Name] = useState('');
  const [interviewer_designation, setinterviewer_Designation] = useState('');
  const [linked_in_url, setlinked_in_url] = useState('');
  const [status, setstatus] = useState('');
  const [PrimaryMarketerList, setPrimaryMarketerList] = useState([]);
  const [SupervisorList, setSupervisorList] = useState([]);
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const [feedback, setFeedback] = useState('');
  useEffect(() => {
    setIsMounted(true);
  }, []);
  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000);
  };
  const [validationErrors, setValidationErrors] = useState({
    interview_type: '',
    ctb: '',
    guest: '',
    start_time: '',
    end_time: '',
    call_details: '',
    description: '',
    status: '',
    duration: ''
  });
  const validateForm = () => {
    const errors = {
      interview_type: '',
      ctb: '',
      start_time: '',
      // end_time: '',
      status: '',
      duration: ''
    };
    if (!interviewType) {
      errors.interview_type = 'Interview Type is required';
    }
    if (!ctbValue) {
      errors.ctb = 'Supervisor is required';
    }
    if (!startTime) {
      errors.start_time = 'Start Time is required';
    }
    // if (!endTime) {
    //   errors.end_time = 'End Time is required';
    // } else if (endTime <= startTime) {
    //   errors.end_time = 'End Time must be after Start Time';
    // }
    if (!duration) {
      errors.duration = 'Duration is required';
    }

    if (!status) {
      errors.status = 'Interview Status is required';
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => !error);
  };

  const handleMarketerChange = (e) => {
    const { options } = e.target;
    const selectedGuestIds = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => parseInt(option.value));

    setGuestValue(selectedGuestIds);
  };

  const handleSupervisorChange = (e) => {
    const { value } = e.target;
    setCtbValue(parseInt(value));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'interview_type':
        setInterviewType(value);
        break;
      case 'ctb':
        setCtbValue(parseInt(value, 10));
        break;
      case 'guest':
        setGuestValue(parseInt(value, 10));
        break;
      case 'call_details':
        setCallDetails(value);
        break;
      case 'description':
        setDetails(value);
        break;
      case 'status':
        setstatus(value);
        break;
      case 'feedback':
        setFeedback(value);
        break;
      case 'interviewer_name':
        setinterviewer_Name(value);
        break;
      case 'interviewer_designation':
        setinterviewer_Designation(value);
        break;
      case 'linked_in_url':
        setlinked_in_url(value);
        break;
      case 'duration':
        setDuration(value);
        break;
      default:
        break;
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;

    if (name === 'start_time') {
      parsedValue = value ? new Date(value + 'Z') : null;
    }
    if (name === 'start_time') {
      setStartTime(parsedValue);
    }
  };


  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlUsers = `${process.env.REACT_APP_API_URL}/employee/all_user_list/`;

      axios.get(apiUrlUsers, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        console.log(response.data);
        setPrimaryMarketerList(response.data.results.marketers);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });

      const apiUrlSupervisors = `${process.env.REACT_APP_API_URL}/marketing/supervisor/`;

      axios.get(apiUrlSupervisors, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        console.log(response.data);
        setSupervisorList(response.data.result);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [history, jsonData, isMounted]);


  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlUsers = `${process.env.REACT_APP_API_URL}/marketing/interview/${id}`;
      axios.get(apiUrlUsers, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        const interviewData = response.data.results;
        console.log(response.data.results)
        const selectedGuestIds = interviewData.guest.map(guest => guest.id);
        setGuestValue(selectedGuestIds);
        setInterviewCards(response.data.results);
        setCtbValue(interviewData?.ctb?.id);
        setInterviewType(interviewData.interview_type);
        setCallDetails(interviewData.call_details);
        setDetails(interviewData.description);
        setStartTime(new Date(interviewData.start_time));
        // setEndTime(new Date(interviewData.end_time));
        setstatus(interviewData.status);
        const formattedDuration = parseFloat(interviewData.duration).toFixed(1).toString()
        setDuration(formattedDuration);
        setFeedback(interviewData.feedback);
        setinterviewer_Name(interviewData.interviewer_name);
        setinterviewer_Designation(interviewData.interviewer_designation);
        setlinked_in_url(interviewData.linked_in_url);
        

      }).catch(error => {
        console.error('Error fetching data:', error);
      });

    }
  }, [history, jsonData, isMounted]);  
  console.log(duration)

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const start = new Date(startTime);
    const parsedDuration = parseFloat(duration);
    const calculatedEndTime = new Date(start.getTime() + parsedDuration * 60 * 60 * 1000); // Convert hours to milliseconds

    const apiUrlUpdateInterview = `${process.env.REACT_APP_API_URL}/marketing/interview/${id}/update/`;
    const updateData = {
      interview_type: interviewType,
      ctb: ctbValue,
      guest: guestValue,
      start_time: startTime,
      end_time: calculatedEndTime.toISOString(),
      call_details: callDetails,
      description: details,
      status: status,
      feedback: feedback,
      interviewer_name: interviewer_name,
      interviewer_designation: interviewer_designation,
      linked_in_url: linked_in_url,
      duration: duration,
    };
    
    try {
      const response = await axios.put(apiUrlUpdateInterview, updateData, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        },
      });
      displayFlashMessage('Interview Updated Successfully', 'success');
      setTimeout(() => {
        history.push(`/interviews`);
      }, 1000);
    } catch (error) {

      console.error('Error updating interview:', error);
    }
  };


  return (
    <div className="ml-56 bg-[#171111]">
      <div className="sidebar-addinterview">
        <Sidebar set_map={set_map} />
      </div>
      <div className="pt-10">
        {flashMessage && (
          <FlashMessage message={flashMessage} type={flashMessageType} />
        )}
        <div className="bg-[#292256] rounded-l-full ml-10">
          <h1 className="text-2xl text-white font-semibold pl-6 py-2">EDIT INTERVIEW DETAILS</h1>
        </div>
        <form onSubmit={handleUpdate}>
          <div className="form-grid mx-16 mt-10">
            <div className="form-group">
              <label className='text-white text-xl'>Interview Type:</label>
              <select name="interview_type" value={interviewType} onChange={handleChange} className='bg-[#171111] text-white'>
                <option value="">Select Interview Type</option>
                {interview_choices.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.interview_type}</div>
            </div>
            <div className="form-group mt-[1px]">
              <label className='text-white text-xl'>Supervisor:</label>
              <select
                id="ctb"
                name="ctb"
                value={ctbValue}
                onChange={handleSupervisorChange}
                className='bg-[#171111] text-white'
              >
                <option value="">Select a Supervisor</option>
                {SupervisorList?.map((supervisor) => (
                  <option value={supervisor?.id}>
                    {supervisor?.name}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.ctb}</div>
            </div>
            <div className="form-group">
              <label className='text-white text-xl'>Guest:</label>
              <select
                id="guest"
                name="guest"
                value={guestValue}
                onChange={handleMarketerChange}
                // multiple
                className='bg-[#171111] text-white'
              >
                <option value="" disabled>Select a guest</option>
                {PrimaryMarketerList.map((marketer) => (
                  <option key={marketer.id} value={marketer.id} selected={guestValue.includes(marketer.id)}>
                    {marketer.full_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label className='text-white text-xl'>Start Time:</label>
              <input className='bg-[#171111] text-white' type="datetime-local" name="start_time" value={startTime instanceof Date ? startTime.toISOString().slice(0, 16) : ''} onChange={handleDateChange} />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.start_time}</div>
            </div>
            {/* <div className="form-group">
              <label className='text-white text-xl'>End Time:</label>
              <input className='bg-[#171111] text-white' type="datetime-local" name="end_time" value={endTime instanceof Date ? endTime.toISOString().slice(0, 16) : ''} onChange={handleDateChange} />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.end_time}</div>
            </div> */}
            <div>
            <label className='text-white text-xl'>Duration:</label>
            <select name="duration" value={duration} onChange={handleChange} className='bg-[#171111] text-white'>
              <option value=''>Select Duration</option>
              {duration_choices.map((choice) => (
                <option key={choice.id} value={choice.id}>
                  {choice.value}
                </option>
              ))}
            </select>
            {validationErrors.duration && <div className="error-message">{validationErrors.duration}</div>}
          </div>
            <div className="form-group">
              <label className='text-white text-xl'>Calling Details:</label>
              <textarea
                name="call_details"
                value={callDetails}
                onChange={handleChange}
                className='bg-[#171111] text-white h-[42px]'
              />
            </div>
            <div className="form-group">
              <label className='text-white text-xl'>Additional Details:</label>
              <textarea
                name="description"
                value={details}
                onChange={handleChange}
                className='bg-[#171111] text-white h-[42px]'
              />
            </div>
            <div className="form-group" >
              <label className='text-white text-xl'>Status:</label>
              <select name="status" value={status} onChange={handleChange} className='bg-[#171111] text-white'>
                <option value="">Select Interview Status</option>
                {interview_status_choice.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.status}</div>
            </div>
            <div className="form-group">
              <label className='text-white text-xl'>Feedback:</label>
              <textarea
                name="feedback"
                value={feedback}
                onChange={handleChange}
                className='bg-[#171111] text-white h-[42px]'
              />
            </div>
                        <div className="form-group">
              <label>interviewer Name:</label>
              <input type='textfield'
                name="interviewer_name"
                value={interviewer_name}
                onChange={handleChange}
              />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.interviewer_name}</div>
            </div>
            <div className="form-group">
              <label>interviewer Designation:</label>
              <input type='textfield'
                name="interviewer_designation"
                value={interviewer_designation}
                onChange={handleChange}
              />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.interviewer_designation}</div>
            </div>
            <div className="form-group">
              <label>Linkedin URL:</label>
              <input type='textfield'
                name="linked_in_url"
                value={linked_in_url}
                onChange={handleChange}
              />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.linked_in_url}</div>
            </div>

          </div>
          <button type="submit" className='ml-16 mb-4 bg-[#b4a4f5] text-slate-800 font-medium hover:bg-[#6c48fd] hover:text-white'>Update</button>
        </form>
      </div>
    </div>

  );

};
export default InterviewUpdate;