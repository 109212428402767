import { React, useState, useEffect } from "react";
import Sidebar from "../sidebar/Sidebar";
import "./AdminDashboard.css";
import Table from "react-bootstrap/Table";
import {
  BsFillGrid3X3GapFill,
  BsFillBellFill,
} from "react-icons/bs";
import { FaCheckCircle, FaTag, FaClock } from "react-icons/fa";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Rectangle,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import axios from "axios";
import { IoIosDownload } from 'react-icons/io';
import useSession from "../session/useSession";
import CardIcon from "../dashboard/CardIcon";
import Charts from "../dashboard/Charts";
import { DateRangePicker } from 'react-date-range';
function AdminDashboard({ dashBoardType }) {
  useSession();
  const [total_interview, setTotal_interview] = useState(0);
  const [total_submission, setTotal_submission] = useState(0);
  const [total_lead, setTotal_lead] = useState(0);
  const [total_test, setTotal_test] = useState(0);
  const [total_recruitment, setTotal_recruitment] = useState(0);
  const [employee_list, setEmployee_list] = useState([]);
  const [consultant_list, setConsultant_list] = useState([]);
  const [start_date, setStart_date] = useState(null);
  const [end_date, setEnd_date] = useState(null);
  const [selected_employee_id, setSelected_employee_id] = useState(null);
  const [employee_currentPage, setEmployee_currentPage] = useState(1);
  const [employee_endIndex, setEmployee_endIndex] = useState(0);
  const [consultant_currentPage, setConsultant_currentPage] = useState(1);
  const [consultant_endIndex, setConsultant_endIndex] = useState(0);
  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const [search_date, setSearch_date] = useState(true);
  const [dataList, setDataList] = useState("employee");
  const [getData, setGetData] = useState(false);
  const [totalSubmissions,setTotalSubmissions]=useState([]);
  const [totalInterviews,setTotalInterviews]=useState([]);
  const [totalTests,setTotalTests]=useState([]);
  const [totalLeads,setTotalLeads]=useState([]);

  const itemsPerPage = 10;
  const set_map = {
    showDashboard: true,
    showCandidate: false,
    showMarketing: false,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: true,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: false,
    openRequirements: false,
  };
  const [marketingData,setMarketingData]=useState();
  const [chartKey,setChartKey]=useState(0);
  // //  marketingData = [
    // { label: "Subs", count: total_submission },
    // { label: "Interviews", count: total_interview },
    // { label: "Tests", count: total_test, backgroudnColor: "#EF446A" },
    // { label: "Leads", count: total_lead, backgroundColor: "#557BF1" },
    // { label: "Consultants", count: total_recruitment, backgroundColor: "#88EFA7" },
  // ];


  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    setTotalSubmissions([]);
    setTotalInterviews([]);
    setTotalLeads([]);
    if (isMounted && jsonData) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/dashboard/admin/`;
      axios
        .get(apiUrl, {
          params: {
            start_date: start_date,
            end_date: end_date,
            employee_page: employee_currentPage,
            consultant_page: consultant_currentPage,
            page_size: itemsPerPage,
            employee_id: selected_employee_id
          },
          headers: {
            Authorization: `Token ${jsonData.result.token}`,
          },
        })
        .then((response) => {
          setTotal_interview(response.data.result.total_interview);
          setTotal_submission(response.data.result.total_submission);
          setTotal_lead(response.data.result.total_lead);
          setTotal_test(response.data.result.total_test);
          setTotal_recruitment(response.data.result.total_recruitment);
          setConsultant_endIndex(response.data.result.total_consultant);
          // setEmployee_endIndex(response.data.result.total_employee);
          // setEmployee_list(response.data.result.employee_list);
          setConsultant_list(response.data.result.consultant_list);
          setGetData(true);
          setMarketingData([ { label: "Subs", count: response.data.result.total_submission },
          { label: "Interviews", count: response.data.result.total_interview },
          {label:"Interviews Scheduled",count:response.data.result.upcoming_interviews},
          { label: "Tests", count: response.data.result.total_test},
          { label: "Leads", count: response.data.result.total_lead },
          { label: "Consultants", count: response.data.result.total_consultant},])
          setTotalSubmissions(response.data.result?.total_submissions);
          setTotalInterviews(response.data.result?.total_interviews)
          setTotalTests(response.data.result?.total_tests)
          setTotalLeads(response.data.result?.total_leads)
          setChartKey(prevKey => prevKey + 1);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

        const apiUrl2 = `${process.env.REACT_APP_API_URL}/dashboard/admin/get_employees`;
        axios
        .get(apiUrl2, {
          params: {
            start_date: start_date,
            end_date: end_date,
            employee_page: employee_currentPage,
            page_size: itemsPerPage,
            employee_id: selected_employee_id
          },
          headers: {
            Authorization: `Token ${jsonData.result.token}`,
          },
        })
        .then((response) => {
          setEmployee_endIndex(response.data.result.total_employee);
          setEmployee_list(response.data.result.employee_list);
        }).catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [jsonData, isMounted, employee_currentPage, consultant_currentPage, selected_employee_id, search_date]);

  const handleEmployeePageChange = (newPage) => {
    setEmployee_currentPage(newPage);
    setConsultant_currentPage(1);
  };

  const handleConsultantPageChange = (newPage) => {
    setConsultant_currentPage(newPage);
  };

  const handleRowClick = (employeeId) => {
    setSelected_employee_id(employeeId);
    setConsultant_currentPage(1);
  };

  const handleEmployeeDownload = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/dashboard/admin/employee_download`;
    axios
      .get(apiUrl, {
        params: {
          start_date: start_date,
          end_date: end_date
        },
        headers: {
          Authorization: `Token ${jsonData.result.token}`,
        }
      })
      .then((response) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data.data], { type: 'text/csv' }));
        link.download = response.data.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch((error) => {
        console.error('Error downloading CSV:', error);
      });
  };

  const handleConsultantDownload = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/dashboard/admin/consultant_download`;
    axios
      .get(apiUrl, {
        params: {
          start_date: start_date,
          end_date: end_date,
          employee_id: selected_employee_id
        },
        headers: {
          Authorization: `Token ${jsonData.result.token}`,
        },
      })
      .then((response) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data.data], { type: 'text/csv' }));
        link.download = response.data.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch((error) => {
        console.error('Error downloading CSV:', error);
      });
  };

  const handleStartDateChange = (date) => {
    setStart_date(date);
  };

  const handleEndDateChange = (date) => {
    setEnd_date(date);
  };

  const handleSearchButtonClick = () => {
    setSearch_date(!search_date);
  };

  const handleSearchButtonClearClick = () => {
    setStart_date(null);
    setEnd_date(null);
    setSearch_date(!search_date);
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ])
  }
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const newDatefunction = (item)=>{
    setState([item.selection])
    setStart_date(item.selection.startDate)
    setEnd_date(item.selection.endDate)
    setSearch_date(!search_date);
  }

  return (
    <div className="SideNavAdminDashboard">
      {/* <div className="admin-sidenav">
      <Sidebar set_map={set_map} />
    </div> */}
      <div className={`admin-main ${dashBoardType}`}>
        <main className="admin-main-container">
          <CardIcon type="Admin" submission_count={total_submission}
            interview_count={total_interview} testCount={total_test}
            leadCount={total_lead} recuritmentCount={total_recruitment}
            backgroundColorforIcon={dashBoardType}
            start_date={start_date}
            end_date={end_date}
            totalTests={totalTests}
            totalLeads={totalLeads}
          />
          {/* <div className="admin-main-cards">
          {cardData.map((card, index) => (
            <div
              key={index}
              className="cardof-admin-main-cards"
              style={{ backgroundColor: card.color }}
            >
              <div className={card.className}>
                <span>
                  {" "}
                  <h3>{card.title}</h3>
                </span>
                <div> {card.icon}</div>
              </div>
              <h1>{card.value}</h1>
            </div>
          ))}
        </div> */}
        <div className="row w-100">
            <div className="col-8 admin-chart">
              {getData === true && <Charts key={chartKey} type="Admin" dashBoardType={dashBoardType} chartData={marketingData} /> }
            </div>
          <div className="col-4">
          <DateRangePicker
                        onChange={item => newDatefunction(item)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={state}
                        direction="horizontal"
                      />
                      <button className="dashboard-button w-100 mb-2" onClick={handleSearchButtonClearClick}>Clear</button>
          </div>
          </div>
          <div className="d-flex flex-col">
            <div className="admin-employee-table">
              <div className="download-container">
                <button className={`${dataList == "employee" ? 'click' : ''}`} onClick={() => { setDataList("employee") }}>
                  <h3 className="admin-employee-heading">Employees List</h3>
                </button>
                <button className={`${dataList == "consultant" ? 'click' : ''}`} onClick={() => { setDataList("consultant") }}>
                  <h3 className="admin-employee-heading">Consultant List</h3>
                </button>
                <button onClick={() => { dataList == "employee" ? handleEmployeeDownload() : handleConsultantDownload() }} className="download-button ">
                  <IoIosDownload className="download-icon" />
                </button>
              </div>
              {
                dataList == "employee" ?
                  <>
                    <Table hover>
                      <thead>
                        <tr>
                          <th>Employee Name</th>
                          <th>Consultant</th>
                          <th>Lead</th>
                          <th>Submission</th>
                          <th>Interview</th>
                          <th>Test</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employee_list.map((item) => (
                          <tr
                            key={item.employee_id}
                            onClick={() => handleRowClick(item.employee_id)}
                            style={{ backgroundColor: selected_employee_id === item.employee_id ? '#d3d3d3' : '' }}
                          >
                            <td>{item.employee_name}</td>
                            <td>{item.total_recruitment}</td>
                            <td>{item.total_lead}</td>
                            <td>{item.total_submission}</td>
                            <td>{item.total_interview}</td>
                            <td>{item.total_test}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="employee-pagination">
                      <button
                        onClick={() => handleEmployeePageChange(employee_currentPage - 1)}
                        disabled={employee_currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="color-white"><b>{employee_currentPage}</b></span>
                      <button
                        onClick={() => handleEmployeePageChange(employee_currentPage + 1)}
                        disabled={employee_endIndex <= (itemsPerPage * (employee_currentPage - 1)) + employee_list.length}
                      >
                        Next
                      </button>
                    </div>
                  </> :
                  <><Table hover>
                    <thead>
                      <tr>
                        <th>Consultant Name</th>
                        <th>Submission</th>
                        <th>Interview</th>
                        <th>Test</th>
                      </tr>
                    </thead>
                    <tbody>
                      {consultant_list.map((item, i) => (
                        <tr key={i}>
                          <td>{item.consultant_name}</td>
                          <td>{item.total_submission}</td>
                          <td>{item.total_interview}</td>
                          <td>{item.total_test}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                    <div className="employee-pagination">
                      <button
                        onClick={() => handleConsultantPageChange(consultant_currentPage - 1)}
                        disabled={consultant_currentPage === 1}
                      >
                        Previous
                      </button>
                      <span><b>{consultant_currentPage}</b></span>
                      <button
                        onClick={() => handleConsultantPageChange(consultant_currentPage + 1)}
                        disabled={consultant_endIndex <= (itemsPerPage * (consultant_currentPage - 1)) + consultant_list.length}
                      >
                        Next
                      </button>
                    </div></>}
            </div>
            <div className="admin-employee-table">
              {/* <div className="download-container">
              <h3 className="admin-employee-heading">Consultant List</h3>
              <button onClick={handleConsultantDownload} className="download-button">
                <IoIosDownload className="download-icon" />
              </button>
            </div> */}
              {/* <Table hover>
              <thead>
                <tr>
                  <th>Consultant Name</th>
                  <th>Submission</th>
                  <th>Interview</th>
                  <th>Test</th>
                </tr>
              </thead>
              <tbody>
                {consultant_list.map((item, i) => (
                  <tr key={i}>
                    <td>{item.consultant_name}</td>
                    <td>{item.total_submission}</td> 
                    <td>{item.total_interview}</td> 
                    <td>{item.total_test}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="employee-pagination">
              <button
                onClick={() => handleConsultantPageChange(consultant_currentPage - 1)}
                disabled={consultant_currentPage === 1}
              >
                Previous  
              </button>
              <span><b>{consultant_currentPage}</b></span>
              <button
                onClick={() => handleConsultantPageChange(consultant_currentPage + 1)}
                disabled={consultant_endIndex <= (itemsPerPage * (consultant_currentPage-1)) + consultant_list.length}
              >
                Next
              </button>
            </div> */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AdminDashboard;
