import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import Sidebar from "../sidebar/Sidebar";
import "./DashBoard.css";
import {
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsFillBellFill,
} from "react-icons/bs";
import { addDays } from 'date-fns';
import Charts from "./Charts";
import { useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import useSession from "../session/useSession";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'rc-slider/assets/index.css';
import submissionIcon from "../assets/SubmissionIcon.svg"
import interviewIcon from "../assets/InterviewIcon.svg"
import devBenchIcon from "../assets/DevBenchIcon.svg"
import OfferIcon from "../assets/OfferIcon.svg"
import BABenchIcon from "../assets/BABenchIcon.svg"
import projectIcon from "../assets/OnProjectIcon.svg"
import CardIcon from "./CardIcon";
import AdminDashboard from "../admin_dashboard/AdminDashboard";
import BirthdayPopup from "./BirthdayPopup";

function Dashboard() {
  useSession();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const search_profile = searchParams?.get("search_profile");
  const [perform_days, setPerform_days] = useState(30);
  const [submission_count, setSubmission_count] = useState(0);
  const [interview_count, setInterview_count] = useState(0);
  const [babenchCount, setBaBenchCount] = useState(0);
  const [devbenchCount, setDevBenchCount] = useState(0);
  const [projectCount,setProjectCount]=useState(0);
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchEndDate, setSearchEndDate] = useState(null);
  const [marketing_performance_interview, setMarketing_performance_interview] =
    useState(0);
  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const [search_date, setSearch_date] = useState(true);
  const [sliderStartDate, setSliderStartDate] = useState(null);
  const [getApiData,setApiData]=useState(false);
  const [dashBoard, setDashBoard] = useState("global");
  const [totalSubmissions,setTotalSubmissions]=useState([]);
  const [totalInterviews,setTotalInterviews]=useState([]);
  const [birthdayEmployeeAge,setBirthdayEmployeeAge]=useState(0);
  const [birthdayEmployeeName,setBirthdayEmployeeName]=useState('');
  const [birthdays,setBirthdays]=useState([]);
  const history = useHistory();
  const set_map = {
    showDashboard: true,
    showCandidate: false,
    showMarketing: false,
    showResume: false,
    openMy: search_profile == "my" ? true : false,
    openGlobal: search_profile == "global" ? true : false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: false,
    openRequirements: false,
  };
  const MarketingDataOptions = [
    { value: 30, name: "1 Month" },
    { value: 180, name: "6 Months" },
    { value: 365, name: "1 Year" },
  ];


  const [marketingData,setMarketingData]=useState();
  const [chartKey,setChartKey]=useState(0);
  const formatDateTime = (dateTimeString) => {
    const optionsDate = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    };

    const formattedDate = new Date(dateTimeString).toLocaleDateString('en-US', optionsDate);
    const timePart = dateTimeString.split('T')[1].split('Z')[0].slice(0, 5);;
    return `${formattedDate} ${timePart} `;
  };


  const [upcoming_interviews, setUpcoming_interviews] = useState([]);
  const handleSubmissionCardClick = () => {
    history.push("/submissions");
  };

  const handleInterviewCardClick = () => {

    history.push("/interviews");
  };
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const handleStartDateChange = (date) => {
    console.log(date)
    setSearchStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSearchEndDate(date);
  };

  const handleSearchButtonClick = () => {
    // const apiUrl = `${process.env.REACT_APP_API_URL}/dashboard/`;
    // axios
    //   .get(apiUrl, {
    //     params: {
    //       search_profile: search_profile,
    //       start_date: searchStartDate,
    //       end_date: searchEndDate,
    //     },
    //     headers: {
    //       Authorization: `Token ${jsonData.result.token}`,
    //     },
    //   })
    //   .then((response) => {
    //     setUpcoming_interviews(response.data.result.upcoming_interviews);
    //     setInterview_count(response.data.result.total_interview);
    //     setSubmission_count(response.data.result.total_submission);
    //     setBaBenchCount(response.data.result.total_ba_bench);
    //     setDevBenchCount(response.data.result.total_dev_bench);
    //     setMarketing_performance_interview(
    //       response.data.result.marketing_performance.interview_count
    //     );

    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data:", error);
    //   });
    setSearch_date(!search_date);
  };
  const handleSearchButtonClearClick = () => {
    setSearchStartDate(null);
    setSearchEndDate(null);
    setSearch_date(!search_date);
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ])
  }

  useEffect(() => {

    console.log("----------------------------->", dashBoard)
    setUpcoming_interviews(0);
          setInterview_count(0);
          setSubmission_count(0);
          setBaBenchCount(0);
          setDevBenchCount(0);
          setProjectCount(0);
    if (dashBoard == "global") {
      getDataforGlobal();
    }
    if (dashBoard == "personal") {
      getDataforPersonal();
    }
    if (dashBoard == "admin") {
      getDataforAdmin();
    }
    if (dashBoard == "axe") {
      getDataforAxe();
    }


  }, [jsonData, isMounted, search_profile, search_date, dashBoard]);

  const handleMarketingDataOptionsChange = (e) => {
    const selectedValue = e.target.value;
    setPerform_days(selectedValue);
  };

  const getDataforPersonal = () => {
    if (isMounted && jsonData) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/dashboard`;
      axios
        .get(apiUrl, {
          params: {
            search_profile: 'my',
            start_date: searchStartDate,
            end_date: searchEndDate,
          },
          headers: {
            Authorization: `Token ${jsonData.result.token}`,
          },
        })
        .then((response) => {
          setUpcoming_interviews(response.data.result.upcoming_interviews);
          setInterview_count(response.data.result.total_interview);
          setSubmission_count(response.data.result.total_submission);
          // setBaBenchCount(response.data.result?.total_ba_bench);
          // setDevBenchCount(response.data.result?.total_dev_bench);
          setMarketing_performance_interview(
            response.data.result.marketing_performance.interview_count
          );
          setMarketingData([{ label: "Interview", percentage: response.data.result.marketing_performance?.interview_count },
          { label: "Submission", percentage: response.data.result?.total_submission },
          { label: "Offer", percentage: 0 },
          { label: "Bench", percentage: response.data.result?.total_dev_bench+response.data.result?.total_ba_bench },
          { label: "On Project", percentage: 0 }]);
          setChartKey(prevKey => prevKey + 1);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }

  const getDataforAdmin = () => {
    if (isMounted && jsonData) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/dashboard/admin`;
      axios
        .get(apiUrl, {
          params: {
            search_profile: '',
            start_date: searchStartDate,
            end_date: searchEndDate,
          },
          headers: {
            Authorization: `Token ${jsonData.result.token}`,
          },
        })
        .then((response) => {
          setUpcoming_interviews(response.data.result?.upcoming_interviews);
          setInterview_count(response.data.result?.total_interview);
          setSubmission_count(response.data.result?.total_submission);
          // setBaBenchCount(response.data.result?.total_ba_bench);
          // setDevBenchCount(response.data.result?.total_dev_bench);
          setMarketing_performance_interview(
            response.data.result?.marketing_performance?.interview_count
          );
          setTotalSubmissions(response.data.result?.total_ubmissions);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }

  const getDataforAxe = () => {
    if (isMounted && jsonData) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/dashboard`;
      axios
        .get(apiUrl, {
          params: {
            search_profile: "axe",
            start_date: searchStartDate,
            end_date: searchEndDate,
          },
          headers: {
            Authorization: `Token ${jsonData.result.token}`,
          },
        })
        .then((response) => {
          console.log("Axee")
          // setUpcoming_interviews(response.data.result?.upcoming_interviews);
          setInterview_count(response.data.result?.total_interview);
          setSubmission_count(response.data.result?.total_submission);
          setBaBenchCount(response.data.result?.total_ba_bench);
          setDevBenchCount(response.data.result?.total_dev_bench);
          setMarketing_performance_interview(
            response.data.result.marketing_performance.interview_count
          );
          setUpcoming_interviews(response.data.result?.upcoming_interviews)
          setMarketingData([{ label: "Interview", percentage: response.data.result?.total_interview },
          { label: "Submission", percentage: response.data.result?.total_submission },
          { label: "Offer", percentage: 0 },
          { label: "Bench", percentage: response.data.result?.total_dev_bench+response.data.result?.total_ba_bench },
          { label: "On Project", percentage: 0 }]);
          setTotalSubmissions(response.data.result?.total_submissions);
          setTotalInterviews(response.data.result?.total_interview)
          setChartKey(prevKey => prevKey + 1);
          console.log(response.data.result.marketing_performance.interview_count)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setUpcoming_interviews(0);
          setInterview_count(0);
          setSubmission_count(0);
          setBaBenchCount(0);
          setDevBenchCount(0);
          setProjectCount(0);
          setMarketing_performance_interview(0);
          
        });
    }
  }
  const getDataforGlobal = () => {
    if (isMounted && jsonData) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/dashboard`;
      axios
        .get(apiUrl, {
          params: {
            search_profile: "global",
            start_date: searchStartDate,
            end_date: searchEndDate,
          },
          headers: {
            Authorization: `Token ${jsonData.result.token}`,
          },
        })
        .then((response) => {
          console.log("------>",response);
          setUpcoming_interviews(response.data.result?.upcoming_interviews);
          setInterview_count(response.data.result?.total_interview);
          setSubmission_count(response.data.result?.total_submission);
          setBaBenchCount(response.data.result?.total_ba_bench);
          setDevBenchCount(response.data.result?.total_dev_bench);
          setProjectCount(response.data.result?.total_project_count);
          setMarketing_performance_interview(
            response.data.result.marketing_performance.interview_count
          );
          setUpcoming_interviews(response.data.result?.upcoming_interviews)
          setMarketingData([{ label: "Interview", percentage: response.data.result.marketing_performance.interview_count },
          { label: "Submission", percentage: response.data.result?.total_submission },
          { label: "Offer", percentage: 0 },
          { label: "Bench", percentage: response.data.result?.total_dev_bench+response.data.result?.total_ba_bench },
          { label: "On Project", percentage: 0 },
          {label:"Interviews Scheduled",percentage:response.data.result?.upcoming_interviews}
        ]);
          setChartKey(prevKey => prevKey + 1);
          setTotalSubmissions(response.data.result?.total_submissions);
          setTotalInterviews(response.data.result?.total_interviews)
          setApiData(true);
          setBirthdayEmployeeAge(response.data.result?.today_dob[0]?.age);
          setBirthdayEmployeeName(response.data?.result?.today_dob[0]?.name);
          setBirthdays(response.data?.result?.today_dob);
        })
        .catch((error) => {
          setUpcoming_interviews(0);
          setInterview_count(0);
          setSubmission_count(0);
          setBaBenchCount(0);
          setDevBenchCount(0);
          setProjectCount(0);
          setMarketing_performance_interview(0);
          console.error("Error fetching data:", error);
        });
    }
  }
  const setDashBoardData = (e) => {
    e.preventDefault();
    setDashBoard(e.target.value)
  }

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const newDatefunction = (item)=>{
    setState([item.selection])
    setSearchStartDate(item.selection.startDate)
    setSearchEndDate(item.selection.endDate)
    setSearch_date(!search_date);
  }
  return (
    <>
      {dashBoard && <div className="SideNavDashboard">
        <div className="divofsidenav">

          <Sidebar set_map={set_map} />
        </div>
        <div className="divofmain">
          <main className="main-container">
            <div className={`main-title ${dashBoard.toLowerCase()}Dashboard`}>
              <select name="dashboard" id="" onChange={setDashBoardData}
                value={dashBoard}
              >
                <option value="global">GLOBAL DASHBOARD</option>
                {jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role)) ? (<option value="admin">ADMIN DASHBOARD</option>)
                :(<option value="personal">PERSONAL DASHBOARD</option>)}
                <option value="axe">AXE DASHBOARD</option>
              </select>
              <div className="birthday">
              {birthdays.length>0 && <BirthdayPopup birthdays={birthdays}  name={birthdayEmployeeName} age={birthdayEmployeeAge}  />}
              </div>
            </div>
            <div>
              {dashBoard == "admin" ? <AdminDashboard dashBoardType={`${dashBoard.toLowerCase()}Dashboard`} />
                :
                <>

                  <CardIcon submission_count={submission_count}
                    interview_count={interview_count} babenchCount={babenchCount} devbenchCount={devbenchCount}
                    backgroundColorforIcon={`${dashBoard.toLowerCase()}Dashboard`}
                    // totalSubmissions={totalSubmissions}
                    scheduledInterview_count={upcoming_interviews}
                    start_date={searchStartDate}
                    end_date={searchEndDate}
                    totalInterviews={totalInterviews}
                  />
                  <div className="row ">
                    <div className="col-8">
                      {getApiData && <Charts key={chartKey} dashBoardType={`${dashBoard.toLowerCase()}Dashboard`} chartData={marketingData} />}

                    </div>
                    <div className={`col-4 ${dashBoard.toLowerCase()}Dashboard`}>
                      <DateRangePicker
                        onChange={item => newDatefunction(item)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={state}
                        direction="horizontal"
                      />
                    <button className="dashboard-button w-100 mb-2" onClick={handleSearchButtonClearClick}>Clear</button>
                    </div>
                  </div>

                </>
              }
            </div>
          </main>
        </div>
      </div>}
    </>
  );
}

export default Dashboard;


