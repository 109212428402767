import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { FlashMessage } from "../../flashmessage/FlashMessage";

export const AddVendorModal = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const jsonData = useSelector((state) => state.user);
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");

  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000); 
  };

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);

    if (!isValidEmail(enteredEmail)) {
      setEmailError("Invalid email address.");
    } else {
      setEmailError("");
    }
  };

  // const handlePhoneChange = (e) => {
  //   const enteredPhoneNumber = e.target.value;
  //   setContact(enteredPhoneNumber);

  //   if (!isValidPhoneNumber(enteredPhoneNumber)) {
  //     setPhoneError("Invalid phone number.");
  //   } else {
  //     setPhoneError("");
  //   }
  // };
  const handlePhoneChange = (e) => {
    const enteredPhoneNumber = e.target.value;
    const cleanedPhoneNumber = enteredPhoneNumber.replace(/\D/g, '');
    const formattedPhoneNumber = formatPhoneNumber(cleanedPhoneNumber);
    setContact(formattedPhoneNumber);
    if (!isValidPhoneNumber(cleanedPhoneNumber)) {
      setPhoneError("Invalid phone number.");
    } else {
      setPhoneError("");
    }
  };
  
  const formatPhoneNumber = (phoneNumber) => {
    const country = phoneNumber.slice(0, 3);
    const firstPart = phoneNumber.slice(3, 6);
    const secondPart = phoneNumber.slice(6, 10);
  
    return `(${country})-${firstPart}-${secondPart}`;
  };
  
  const isValidPhoneNumber = (phoneNumber) => {
    // Adjust the regex pattern if needed
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };
  

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // const isValidPhoneNumber = (contact) => {
  //   const phoneRegex = /^\d{10}$/;
  //   return phoneRegex.test(contact);
  // };

  const handleSubmit = () => {
    if (!name || !email || !contact || !props.company_id) {
      alert("Please fill in all fields");
    } else {
      const apiUrl = `${process.env.REACT_APP_API_URL}/marketing/vendor/`;
      axios
        .post(apiUrl, { 
          name: name, 
          email: email,
          number: contact,
          company: props.company_id
         }, 
         {
          headers: {
            Authorization: `Token ${jsonData.result.token}`,
          }
        })
        .then(response => {
          displayFlashMessage(`Vendor Contact ${response.data.result.name} added successfully`, 'success');
        })
        .catch(error => {
          displayFlashMessage(error.response.data.result, 'error');
        })
        .finally(() => {
          setContact("");
          setEmail("");
          setName("");
          setTimeout(() => {
            props.onHide();
          }, 1000);
        });
    }
    setContact("");
    setEmail("");
    setName("");
  };
  return (
    <Modal
      {...props}
      size="md"
      className="bg-neutral-900 text-white"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body 
      className="bg-neutral-900 text-white border-1">
        <Form.Label>Vendor Name</Form.Label>
        <Form.Control
          type="text"
          className="bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
          placeholder="Enter Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Form.Label>Vendor Email</Form.Label>
        <Form.Control
          className="bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={handleEmailChange}
        />
        {emailError && (
          <div
            style={{ color: "red", fontSize: "14px" }}
            className="error-message"
          >
            {emailError}
          </div>
        )}
        <Form.Label>Vendor Contact</Form.Label>
        <Form.Control
          type="text"
          className="bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
          placeholder="(XXX)-XXX-XXXX"
          value={contact}
          onChange={handlePhoneChange}
        />
        {phoneError && (
          <div
            style={{ color: "red", fontSize: "14px" }}
            className="error-message"
          >
            {phoneError}
          </div>
        )}
        {flashMessage && (
          <FlashMessage message={flashMessage} type={flashMessageType} />
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center bg-neutral-900 text-white border-1">
        <Button onClick={handleSubmit} className="flex gap-1 px-3 py-2.5 text-white rounded-xl bg-[#292256] border-0">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
