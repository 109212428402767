export const setUser = (userData) => {
    return {
      type: 'SET_USER',
      payload: userData,
    };
  };
  
  export const logoutUser = () => ({
    type: 'LOGOUT_USER',
  });

  export const loginError = (errorMessage) => {
    return {
      type: 'LOGIN_ERROR',
      payload: errorMessage,
    };
  };
  