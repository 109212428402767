import React, { useState, useRef, useEffect } from "react";
import './UserProfile.css';
import PersonalInfo from './PersonalInfo';
import Sidebar from "../sidebar/Sidebar";
import useSession from "../session/useSession";
import { useSelector } from 'react-redux';
import axios from "axios";
import { IoIosSend } from "react-icons/io";
const UserProfile = () => {
  useSession();
  const [selectedOption, setSelectedOption] = useState('personal-info');
  const [profileImage, setProfileImage] = useState(null);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const hiddenFileInput = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isButtonHidden, setIsButtonHidden] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const [attachments, setAttachments] = useState(null);
  const jsonData = useSelector((state) => state.user);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setIsButtonHidden(false);
  };
  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: false,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: false,
    openRequirements: false,
  };
  const renderContent = () => {
    switch (selectedOption) {
      case 'personal-info':
        return <PersonalInfo />;
      default:
        return null;
    }
  };
  const handleUploadButtonClick = (file) => {
    var myHeaders = new Headers();
    var formData = new FormData();
    formData.append("content_type", 8);
    formData.append("object_id", jsonData?.result.id);
    formData.append("attachment_file", image);
    formData.append("creator", jsonData?.result.id);
    const requestOptions1 = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const content_type = 'employee_user';
    const id = jsonData?.result.id;
    fetch(`${process.env.REACT_APP_API_URL}/static_data/attachments/delete/${content_type}/${id}/`, requestOptions1)
      .then((response) => {
        if (response.ok) {
          
        } else {
          console.error('Failed to delete folder');
        }
      })
      .catch((error) => console.error('Error deleting folder:', error));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/static_data/attachment/upload/`, requestOptions)
      .then((response) => {
        setIsButtonHidden(true);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const content_type = 'employee_user';
        const id = jsonData?.result.id;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/static_data/attachments/${content_type}/${id}/`);
        const baseUrl = 'https://vms-attachmnts.s3.ap-south-1.amazonaws.com/';
        setImageUrl(`${baseUrl}${response.data[0]?.file_name}`);
        setAttachments(response.data[0]?.file_name)
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };
    fetchData();
  }, [isButtonHidden]);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  useEffect(() => {
    if (isButtonHidden) {
      hiddenFileInput.current.value = "";
    }
    setIsMounted(true);
  }, [isButtonHidden]);
  const name = jsonData?.result.full_name;
  const sort_name = jsonData?.result.full_name
    .split(" ")
    .map(word => word.charAt(0).toUpperCase())
    .join("");
  return (
    <div className="userprofile-page overflow-hidden">
      <div className="sidebar-userprofile">
        <Sidebar set_map={set_map} />
      </div>
      <div className="user-profile">
        <div className="container">
          <div className="">
            <div onClick={handleClick} style={{ cursor: "pointer" }}>
              <input
                id="image-upload-input"
                type="file"
                onChange={handleImageChange}
                accept="jpg,jpeg,png"
                ref={hiddenFileInput}
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>
        <div className="bg-[#292256] rounded-l-full ml-[3.5rem] mt-[48px] mb-2">
          <h1 className="text-2xl text-white font-semibold pl-6 py-2">Personal Info</h1>
        </div>
        <div className="mx-[5.0rem]">{renderContent()}</div>
      </div>
    </div >
  );
};
export default UserProfile;