import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./Sidebar.css";
import { useSelector } from 'react-redux';
import logo from "../assets/Technman_Logo.png";
import { FaSignOutAlt } from 'react-icons/fa';
import axios from "axios";
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../actions/action';
import DashboardImg from "../assets/Dashboard.svg"
import GlobalDashboardImg from "../assets/Global Dashboard.svg"
import VmsLogs from "../assets/VMS Logs.svg"
import CandidateTableImg from "../assets/Candidate Table.svg"
import AddCandidateImg from "../assets/Add Candidate.svg"
import requirementsImg from "../assets/Requirements.svg"
import resumeImg from "../assets/Resume.svg"
import resumeAnalyzerImg from "../assets/Resume_Analyzer.svg"
import logoutIcon from "../assets/Logout.svg"
import testImg from "../assets/Sidebar_Test.svg"
import screeningImg from "../assets/Sidebar_Screening.svg"
import interviewImg from "../assets/Sidebar_Interview.svg"
const Sidebar = (props) => {
  const set_map = props.set_map;
  const [showDashboard, setShowDashboard] = useState(set_map["showDashboard"]);
  const [showCandidate, setShowCandidate] = useState(set_map["showCandidate"]);
  const [showMarketing, setShowMarketing] = useState(set_map["showMarketing"]);
  const [showResume, setShowResume] = useState(set_map["showResume"]);
  const [openCandidateTable, setOpenCandidateTable] = useState(
    set_map["openCandidateTable"]
  );
  const [openAddCandidate,setOpenAddCandidate]=useState(
    set_map["openAddCandidate"]
  )
  const [openSubmission, setOpenSubmission] = useState(
    set_map["openSubmission"]
  );
  const [openTest, setOpenTest] = useState(set_map["openTest"]);
  const [openScreening, setOpenScreening] = useState(set_map["openScreening"]);
  const [openInterview, setOpenInterview] = useState(set_map["openInterview"]);
  const [openRequirements, setOpenRequirements] = useState(
    set_map["openRequirements"]
  );
  const [openResume, setOpenResume] = useState(set_map["openResume"]);
  const [openMy, setOpenMy] = useState(set_map["openMy"]);
  const [openGlobal, setOpenGlobal] = useState(set_map["openGlobal"]);
  const [openLogs, setOpenLogs] = useState(set_map["openLogs"]);
  const [openAdmin, setOpenAdmin] = useState(set_map["openAdmin"]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const [isClicked, setIsClicked] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const jsonData = useSelector((state) => state.user);
  const name = jsonData?.result.full_name;
  const sort_name = jsonData?.result.full_name
    .split(" ")
    .map(word => word.charAt(0).toUpperCase())
    .join("");

  const toggleDashboard = () => {
    setShowDashboard(!showDashboard);
    setShowCandidate(false);
    setShowMarketing(false);
    setShowResume(false);
  };

  const toggleCandidate = () => {
    setShowCandidate(!showCandidate);
    setShowDashboard(false);
    setShowMarketing(false);
    setShowResume(false);
  };

  const toggleMarketing = () => {
    setShowMarketing(!showMarketing);
    setShowDashboard(false);
    setShowCandidate(false);
    setShowResume(false);
  };

  const toggleResume = () => {
    setShowResume(!showResume);
    setShowDashboard(false);
    setShowCandidate(false);
    setShowMarketing(false);
  };

  const handleMy = () => {
    setOpenMy(true);
    setOpenGlobal(false);
    const queryParam = {
      search_profile: "my"
    }
    history.push({
      pathname: "/dashboard",
      search: `?${new URLSearchParams(queryParam).toString()}`
    });
  }

  const handleResume = () => {
    history.push("/DocumentUpload");
  };

  const handleGlobal = () => {
    setOpenMy(false);
    setOpenGlobal(true);
    const queryParam = {
      search_profile: "global"
    }
    history.push({
      pathname: "/dashboard",
      search: `?${new URLSearchParams(queryParam).toString()}`
    });
  }

  const handleLogs = () => {
    if (jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role))) {
      history.push("/dashboard/logs");
    }
  }

  const handleAdminDashboard = () => {
    if (jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role))) {
      history.push("/dashboard/admin");
    }
  }

  const handleCandidateTable = () => {
    history.push("/detailscandidate");
  };

  const handleAddCandidate = () => {
    history.push('/detailscandidate/addcandidate');
  }

  const handleSubmission = () => {
    history.push("/submissions");
  };

  const handleInterview = () => {
    history.push("/interviews");
  };

  const handleTest = () => {
    history.push("/tests");
  };
  const handleScreening = () => {
    history.push("/screening");
  };

  const handleRequirements = () => {
    history.push("/requirements");
  };

  const handleClick = () => {
    history.push("/userprofile");

  };

  const handleLogout = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}/employee/logout/`, {
      headers: {
        Authorization: `Token ${localStorage.getItem('access_token')}`,
      },
    }).then((response) => {
      dispatch(logoutUser());
      localStorage.removeItem('access_token');
      localStorage.removeItem('token_expiry');
      history.push("/login");
    }).catch((error) => {
      console.error('session logout failed:', error);
    });
  }

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="hamburger" onClick={toggleSidebar}>
        ☰
      </div>
      <div className="logo-container">
        <span className="span-logo-container">
          <img src={logo} alt="VMS Technman Logo" className="logo" />
        </span>
      </div>
      <ul>
        <li>
          <a
            href="#" onClick={toggleDashboard} className="d-flex gap-2"
          >
            {/* <i className="fa fa-dashboard"></i>  */}
            <img src={DashboardImg} alt="dashboardImg" height="15px" width="15px" />
            Dashboard
            {showDashboard ? (<i class="fa fa-chevron-down" aria-hidden="true"></i>
            ) : (<i class="fa fa-chevron-right" aria-hidden="true"></i>
            )}
          </a>
          {showDashboard && (
            <ul>
              {/* {!jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role)) && (
                <li>
                  <a
                    href="#"
                    onClick={handleMy}
                    className={
                      openMy ? "clicked-nav" : "unclicked-nav"
                    }
                  >
                    <i className="fa fa-user"></i> My
                  </a>
                </li>
              )} */}
              <li>
                <a
                  href="#"
                  onClick={handleGlobal}
                  className={`d-flex gap-2 ${openGlobal ? "clicked-nav" : "unclicked-nav"
                    }`}
                >
                  <img src={GlobalDashboardImg} alt="img" height="15px" width="15px" /> Global
                </a>
              </li>
              {jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role)) && (
                <li>
                  <a
                    href="#"
                    onClick={handleLogs}
                    className={`d-flex gap-2 ${openLogs ? "clicked-nav" : "unclicked-nav"
                      }`}
                  >
                    {/* <i className="fa fa-user"></i>  */}
                    <img src={VmsLogs} alt="" height="15px" width="15px" />
                    Vms Logs
                  </a>
                </li>
              )}
            </ul>
          )}
        </li>
        <li>
          <a href="#" onClick={toggleCandidate} className="d-flex gap-2">
            <i className="fa fa-users"></i> Candidate
            {
              showCandidate ?(<i class="fa fa-chevron-down" aria-hidden="true"></i>
            ) : (<i class="fa fa-chevron-right" aria-hidden="true"></i>
            )
            }
          </a>
          {showCandidate && (
            <ul>
              <li>
                <a
                  href="#"
                  onClick={handleCandidateTable}
                  className={`d-flex gap-2 ${openCandidateTable ? "clicked-nav" : "unclicked-nav"
                    }`}
                >
                  <img src={CandidateTableImg} alt="" height="15px" width="15px" />
                  Candidate Table
                </a>
              </li>
              <li>
                {console.log(jsonData?.result?.roles)}
              {jsonData?.result?.roles.some(role=>["admin","superadmin","recruiter"].includes(role)) &&<a href="#" 
                  onClick={handleAddCandidate} 
                  className={`d-flex gap-2 ${openAddCandidate ? "clicked-nav" : "unclicked-nav"}`}
                  disabled={!jsonData?.result.roles.some(role => ["admin", "recruiter", "superadmin"].includes(role))}
                >
                  <img src={CandidateTableImg} alt="" height="15px" width="15px" />
                  Add Candidate
                </a>}
              </li>
            </ul>
          )}
        </li>
        <li>
          <a href="#" onClick={toggleMarketing} className="d-flex gap-2">
            <i className="fa fa-bullhorn"></i> Marketing
            {showMarketing ? (<i class="fa fa-chevron-down" aria-hidden="true"></i>
            ) : (<i class="fa fa-chevron-right" aria-hidden="true"></i>
            )}
          </a>
          {showMarketing && (
            <ul>
                <li>
                <a
                  href="#"
                  onClick={handleRequirements}
                  className={`d-flex gap-2 ${openRequirements ? "clicked-nav" : "unclicked-nav"}`}
                >
                  {/* <i className="fa fa-list-alt"></i>  */}
                  <img src={requirementsImg} alt="" height="15px" width="15px" />
                  Requirements
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={handleSubmission}
                  className={`d-flex gap-2 ${openSubmission ? "clicked-nav" : "unclicked-nav"}`}
                >
                  <i className="fa fa-file"></i> 
                  <span>
                    Submission
                    </span>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={handleScreening}
                  className={`d-flex gap-2 ${openScreening ? "clicked-nav" : "unclicked-nav"}`}
                >
                  <img src={screeningImg} height="15px" width="15px" alt="" />
                   Screening
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={handleTest}
                  className={`d-flex gap-2 ${openTest ? "clicked-nav" : "unclicked-nav"}`}
                >
                  <img src={testImg} alt="" height="15px" width="15px" />
                   Test
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={handleInterview}
                  className={`d-flex gap-2 ${openInterview ? "clicked-nav" : "unclicked-nav"}`}
                >
                  <img src={interviewImg} alt="" height="15px" width="15px" />
                  Interview
                </a>
              </li>

              
            </ul>
          )}
        </li>

        <li>
          <a href="#" onClick={toggleResume} className="d-flex gap-2 align-items-center">
            {/* <i className="fa fa-table"></i>  */}
            <img src={resumeImg} alt="" height="15px" width="15px"
            />
            Resume
            {showResume ? (<i class="fa fa-chevron-down" aria-hidden="true"></i>
            ) : (<i class="fa fa-chevron-right" aria-hidden="true"></i>
            )}
          </a>
          {showResume && (
            <ul>
              <li>
                <a
                  href="#"
                  onClick={handleResume}
                  className={` d-flex gap-2 ${openResume ? "clicked-nav" : "unclicked-nav"}`}
                >
                  {/* <i className="fa fa-file"></i>  */}
                  <img src={resumeAnalyzerImg} alt=""
                    height="15px" width="15px"
                  />
                  Resume Analyzer
                </a>
              </li>
            </ul>
          )}
        </li>

        <hr className="mt-2" />
        <li>
          <div className="profile-container">
            <div
              className={`circle-button $isClicked ? "clicked" : ""}`}
              onClick={handleClick}
            >
              {sort_name && sort_name[0]}
            </div>
            <span className="profile-name">{name}</span>
          </div>
          <div className="profile-container"
          >
            {/* <FaSignOutAlt
              style={{
                color: 'red',
                fontSize: '22px',
                marginRight: '5px',
                transform: 'rotate(-180deg)',
                cursor: 'pointer',
              }}
              onClick={handleLogout}
            /> */}
            <img src={logoutIcon} alt="Logout Icon"    style={{cursor:"pointer"}}   onClick={handleLogout}/>
            <span        style={{cursor:"pointer"}}   onClick={handleLogout}>
              Logout</span>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
