import React, { useState, useEffect } from 'react';
import './UpdateCandidate.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import 'react-datepicker/dist/react-datepicker.css';
import Sidebar from '../sidebar/Sidebar';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { FlashMessage } from '../flashmessage/FlashMessage';
import useSession from '../session/useSession';

function UpdateCandidatePage() {
  useSession();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams?.get('id');
  const profileId = searchParams?.get('profileId');
  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const history = useHistory();
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const [defaultVisa, setdefaultVisa] = useState('');
  const [defaultRTG, setDefaultRTG] = useState('');
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [marketingDateError, setMarketingDateError] = useState("");
  const [nameError, setNameError] = useState('');
  const [ssnError, setssnError] = useState('');
  const [RTGError, setRTGError] = useState('');
  const [genderError, setgenderError] = useState('');
  const [marketerError, setmarketerError] = useState('');
  const [dobError, setDobError] = useState('');
  const [locationError, setlocationError] = useState('');
  const [preferredlocationError, setpreferredlocationError] = useState('');
  const [RateValueError, setRateValueError] = useState('');
  const [StatusError, setStatusError] = useState('');
  const [visaError, setvisaError] = useState('');
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [PrimaryMarketerList, setPrimaryMarketerList] = useState([]);
  const [RecruiterList, setRecruiterList] = useState([]);
  const [Teams, setTeams] = useState([]);
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
  const set_map = {
    showDashboard: false, showCandidate: true, showMarketing: false, showResume: false, openMy: false, openGlobal: false, openAdmin: false,
    openLogs: false, openCandidateTable: true, openSubmission: false, openInterview: false, openTest: false, openRequirements: false
  };
  const visa_choices = [
    { id: 'US_Citizen', value: "US Citizen" },
    { id: 'green_card', value: "Green Card" },
    { id: 'h1b', value: "H1B" },
    { id: 'opt', value: "OPT" },
    { id: 'opt_stem', value: "OPT Stem" },
    { id: 'cpt', value: "CPT" },
    { id: 'gc_ead', value: "GC EAD" },
    { id: 'h4_ead', value: "H4 EAD" },
  ]
  const [formData, setFormData] = useState({
    is_original: '',
    title: '',
    name: '',
    email: '',
    visa_type: '',
    phone_no: '',
    dob: '',
    gender: '',
    address: '',
    education: '',
    location: '',
    ssn: '',
    job_domain: '',
    skills: '',
    in_pool: '',
    marketing_start: null,
    marketing_end: null,
    visa_start: null,
    visa_end: null,
    primary_marketer: null,
    recruiter: null,
    rtg: '',
    rateType: '',
    rate: '',
    preferred_location: [],
    teamAssigned: '',
    zip_code: '',
    comments: '',
    documents: [],
    status: '',
    customVisa: '',
    bench_status: '',

  });
  const status_choices = [
    { id: "in_marketing", value: "In Marketing" },
    { id: "on_project", value: "On Project" },
    { id: "training", value: "Training" },
    { id: "left_us", value: "Left Us" },
    {id: "marketing_stop", value: "Stop Marketing"},
  ]
  const rate_choices = [
    { id: "$/hr", value: "$/hr" },
    { id: "$/annum", value: "$/annum" },
    { id: "percentage", value: "percentage" }
  ]
  const gender_choices = [
    { id: "male", value: "Male" },
    { id: "female", value: "Female" },
    { id: "other", value: "Other" },
  ]
  const bench_choices = [
    { id: "dev_bench", value: "DEV Bench" },
    { id: "ba_bench", value: "BA Bench" },
  ]
  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000);
  };
  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidName = (name) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    return nameRegex.test(name);
  }
  const handleDobChange = (e) => {
    const selectedDate = e.target.value;
    setFormData({ ...formData, dob: selectedDate });

    if (!isValidDate(selectedDate)) {
      setDobError('Please enter a valid date of birth.');
    } else if (isFutureDate(selectedDate)) {
      setDobError('Date of birth cannot be in the future.');
    } else {
      setDobError('');
    }
  };
  const handleMarketerChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      primary_marketer: parseInt(value),
    }));
  }
  const handleRecruiterChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      recruiter: parseInt(value),
    }));
  }
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'marketing_end') {
      const marketingStart = formData.marketing_start;
      const marketingEnd = value;
      if (marketingStart && marketingEnd && marketingEnd < marketingStart) {
        setMarketingDateError("Marketing end Date should be after start date");
      } else {
        setMarketingDateError("");
      }
    }
  };
  const isValidDate = (date) => {

    return !isNaN(Date.parse(date));
  };

  const isFutureDate = (date) => {
    const dobDate = new Date(date);
    const currentDate = new Date();
    return dobDate > currentDate;
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const dateFields = ['dob', 'visa_start', 'visa_end', 'marketing_start', 'marketing_end'];
    dateFields.forEach((field) => {
      if (formData[field] === '') {
        formData[field] = null;
      }
    });
    let isValid = true;
    if (formData.name.trim() === '') {
      isValid = false;
      setNameError('*required');
    }

    else if (!isValidName(formData.name)) {
      isValid = false;
      setNameError('Name should be charaters');

    } else {
      setNameError('');
    }

    const enteredPhoneNumber = formData.phone_no;
    setPhoneNumber(enteredPhoneNumber);
    if (enteredPhoneNumber === '') {
      isValid = false;
      setPhoneError('*required');
    } else if (!isValidPhoneNumber(enteredPhoneNumber)) {
      isValid = false;
      setPhoneError("Invalid phone number.");
    }
    else {
      setPhoneError("");
    }

    const enteredEmail = formData.email;
    setEmail(enteredEmail);

    if (enteredEmail === '') {
      isValid = false;
      setEmailError('*required')
    }
    else if (!isValidEmail(enteredEmail)) {
      isValid = false;
      setEmailError("Invalid email address.");
    } else {
      setEmailError("");
    }

    const digitRegex = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$|^\d{4}$/;
    if (formData.ssn === '') {
      isValid = true;
    }

    else if (!digitRegex.test(formData.ssn)) {
      isValid = false;
      setssnError('Enter Valid SSN');

    } else {
      setssnError('');
    }

    if (formData.gender === '') {
      isValid = false;
      setgenderError("*required");
    }

    if (formData.primary_marketer === '') {
      isValid = false;
      setmarketerError("*required");
    }

    if (!isValidDate(formData.dob)) {
      isValid = false;
      setDobError('Please enter a valid date of birth.');
    } else if (isFutureDate(formData.dob)) {
      isValid = false;
      setDobError('Date of birth cannot be in the future.');
    } else {
      setDobError('');
    }

    if (formData.location === '') {
      isValid = false;
      setlocationError('*required')
    }
    if (formData.preferred_location === '') {
      isValid = false;
      setpreferredlocationError('*required')
    }
    if (formData.rateType === '') {
      isValid = false;
      setRateValueError('*required')
    }
    if (formData.status === '') {
      isValid = false;
      setStatusError('*required')
    }
    if (formData.visaType === '') {
      isValid = false;
      setvisaError('*required')
    }
    if (marketingDateError) {
      isValid = false;
    }
    if (formData.marketing_end) {

    }
    if (isValid) {
      const apiUrlCandidate = `${process.env.REACT_APP_API_URL}/candidate/candidate/`;
      const profile_id = profileId;

      const apiUrlProfile = `${process.env.REACT_APP_API_URL}/candidate/candidate_profile/`;
      axios
        .put(apiUrlCandidate, {
          // file_resume:formData.file_resume,
          id: id,
          email: email,
          name: formData.name,
          email: formData.email,
          phone_no: formData.phone_no,
          rtg: formData.rtg,
          rate: formData.rate,
          preferred_location: formData.preferred_location,
          skills: formData.skills,
          zip_code: formData.zip_code,
          comments: formData.comments,
          status: formData.status,
          gender: formData.gender,
          address: formData.address,
          ssn: formData.ssn,
          job_domain: formData.job_domain,
          skills: formData.skills,
          in_pool: formData.in_pool,
          marketing_start: formData.marketing_start,
          marketing_end: formData.marketing_end,
          rateType: formData.rateType,
          primary_marketer: formData.primary_marketer,
          recruiter: formData.recruiter,
          bench_status:formData.bench_status,

        }, {
          headers: {
            Authorization: `Token ${jsonData?.result.token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(candidateResponse => {
          const visaType = formData.visa_type === 'custom' ? formData.customVisa : formData.visa_type;
          axios
            .put(apiUrlProfile, {

              id: profile_id,
              visa_type: visaType,
              visa_start: formData.visa_start,
              visa_end: formData.visa_end,
              education: formData.education,
              location: formData.location,
              dob: formData.dob,
              is_original: formData.is_original,
              title: formData.title,

            }, {
              headers: {
                Authorization: `Token ${jsonData?.result.token}`,
                'Content-Type': 'application/json',
              },
            })
            .then(profileResponse => {
              displayFlashMessage('Consultant Updated Successfully', 'success');
              setTimeout(() => {
                history.push(`/detailscandidate/profilecandidate/updatecandidate/profilecandidate/${id}`);
              }, 1000);
            })
            .catch(profileError => {
              console.error('Error updating candidate profile:', profileError);
            });
        })
        .catch(candidateError => {
          if (candidateError.response && candidateError.response.status === 400) {
            displayFlashMessage(candidateError.response.data.result, 'error');
          } else {
            console.error('Error updating candidate:', candidateError);
          }

        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("name",name);
    console.log("value",value);
    setFormData({ ...formData, [name]: value });
  };
  console.log("name",formData.bench_status);
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    if (name === 'status' && value === 'in_marketing') {
      setShowAdditionalFilters(true)
    }
    else {
      setShowAdditionalFilters(false)
    }
    setdefaultVisa(value)
    setFormData({ ...formData, [name]: value });
    if (name === 'visa_type' && value !== 'custom') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        customVisa: '',
      }));
    }

  };

  useEffect(() => {
    setIsMounted(true);
  }, []);
  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlUsers = `${process.env.REACT_APP_API_URL}/employee/all_user_list/`;
      axios.get(apiUrlUsers, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        setPrimaryMarketerList(response.data.results.marketers);
        setRecruiterList(response.data.results.recruiters);
        setTeams(response.data.results.teams);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [history, jsonData, isMounted]);


  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/candidate/candidate/`;
      axios.get(apiUrl, {
        params: { consultant_id: id },
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        },
      })
        .then(candidateResponse => {
          const candidateData = candidateResponse.data.results;
          setFormData({
            ...formData,
            email: candidateData.email || '',
            phone_no: candidateData.phone_no || '',
            rtg: candidateData.rtg || '',
            skills: candidateData.skills || '',
            preferred_location: candidateData.preferred_location || '',
            status: candidateData.status || '',
            zip_code: candidateData.zip_code || '',
            comments: candidateData.comments || '',
            rateType: candidateData.rate_type || '',
            rate: candidateData.rate || '',
            name: candidateData.name,
            gender: candidateData.gender || '',
            address: candidateData.address || '',
            ssn: candidateData.ssn || '',
            job_domain: candidateData.job_domain || '',
            skills: candidateData.skills || '',
            in_pool: candidateData.in_pool || '',
            marketing_start: candidateData.marketing_start || null,
            marketing_end: candidateData.marketing_end || null,
            primary_marketer: candidateData.primary_marketer.id || '',
            recruiter: candidateData.recruiter.id || '',
            visa_start: candidateData.visa_start || null,
            visa_end: candidateData.visa_end || null,
            bench_status: candidateData.bench_status|| '',

          });
           if(candidateData.bench_status !== '')
           {
            setShowAdditionalFilters(true);
           }
          const apiUrlConsultantProfile = `${process.env.REACT_APP_API_URL}/candidate/candidate_profile/`;
          axios.get(apiUrlConsultantProfile, {
            params: { profile_id: profileId },
            headers: {
              Authorization: `Token ${jsonData?.result.token}`,
            },
          })
            .then(consultantProfileResponse => {
              const candidateProfile = consultantProfileResponse.data.result;
              const isPredefinedVisa = visa_choices.some(
                (visa) => visa.id === candidateProfile.visa_type
              );
              setdefaultVisa(
                isPredefinedVisa ? candidateProfile.visa_type : 'custom'
              );
              setFormData((prevFormData) => ({
                ...prevFormData,
                customVisa: isPredefinedVisa ? '' : candidateProfile.visa_type,
              }));
              setFormData(prevFormData => ({
                ...prevFormData,
                visa_type: candidateProfile.visa_type,
                education: candidateProfile.education,
                location: candidateProfile.location,
                dob: candidateProfile.dob,
                is_original: candidateProfile.is_original,
                title: candidateProfile.title,
                visa_start: candidateProfile.visa_start,
                visa_end: candidateProfile.visa_end,
              }));

            })
            .catch(error => {
              console.error('Error fetching consultant profile data:', error);
            });
        })
        .catch(error => {
          console.error('Error fetching candidate data:', error);
        });
    }
  }, [id, isMounted, jsonData]);
 


  return (
    <div className="updatecandidate-page bg-neutral-900 text-white">
      <div className="sidebar-updatecandidate">
        <Sidebar set_map={set_map} />
      </div>
      <div className="update-candidate-container">
        {flashMessage && (
          <FlashMessage message={flashMessage} type={flashMessageType} />
        )}
        <div>
        <div className="text-white justify-center items-start px-3 py-2 mt-4 text-2xl font-bold tracking-widest leading-9 uppercase whitespace-nowrap color rounded-l-[43px] text-zinc-100 max-md:px-5 ml-5">
          Update Candidate Page
        </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="abc">
            <div className="grid-container">
              <div className="grid-item">
                <label htmlFor="name">Full Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                {nameError && (
                  <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                    {nameError}
                  </div>
                )}
              </div>

              <div className="grid-item">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {emailError && (
                  <div
                    style={{ color: "red", fontSize: "14px" }}
                    className="error-message"
                  >
                    {emailError}
                  </div>
                )}
              </div>
              <div className="grid-item">
                <label htmlFor="phone_no">Phone:</label>
                <input
                  type="text"
                  id="phone_no"
                  name="phone_no"
                  value={formData.phone_no}
                  onChange={handleChange}
                  required
                />
                {phoneError && (
                  <div
                    style={{ color: "red", fontSize: "14px" }}
                    className="error-message"
                  >
                    {phoneError}
                  </div>
                )}
              </div>
              <div className="grid-item">
                <label htmlFor="dob">Date of Birth:</label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  value={formData.dob}
                  onChange={handleDobChange}
                  required
                />
                {dobError && (
                  <div style={{ color: "red", fontSize: "14px" }} className="error-message">
                    {dobError}
                  </div>
                )}
              </div>
              <div className="grid-item">
                <label htmlFor="gender">Gender:</label>
                <select
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleSelectChange}
                  required
                >
                  <option value="">Select Gender</option>
                  {gender_choices.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.value}
                    </option>
                  ))}
                </select>
                {genderError && (
                  <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                    {genderError}
                  </div>
                )}
              </div>
              <div className="grid-item">
                <label htmlFor="address">Address:</label>
                <textarea
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>
              <div className="grid-item">
                <label htmlFor="education">Education Details:</label>
                <textarea
                  type="text"
                  id="education"
                  name="education"
                  value={formData.education}
                  onChange={handleChange}
                />
              </div>
              <div className="grid-item">
                <label htmlFor="visa_type">Visa:</label>
                <select
                  id="visa_type"
                  name="visa_type"
                  value={defaultVisa}
                  onChange={handleSelectChange}
                >
                  <option value="">Select Visa</option>
                  {visa_choices.map((visa) => (
                    <option key={visa.id} value={visa.id}>
                      {visa.value}
                    </option>
                  ))}
                  <option value="custom">Other (Type your own)</option>
                </select>
                {visaError && (
                  <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                    {visaError}
                  </div>
                )}
              </div>
              {formData.visa_type === 'custom' && (
                <div className="form-group">
                  <label>Custom Visa:</label>
                  <input
                    type="text"
                    name="customVisa"
                    value={formData.customVisa}
                    onChange={handleSelectChange}
                    placeholder="Type your custom visa"
                  />
                </div>
              )}
              <div className="grid-item">
                <label htmlFor="visa_start">Visa Start:</label>
                <input
                  type="date"
                  id="visa_start"
                  name="visa_start"
                  value={formData.visa_start}
                  onChange={handleDateChange}

                />
              </div>
              <div className="grid-item">
                <label htmlFor="visa_end">Visa End:</label>
                <input
                  type="date"
                  id="visa_end"
                  name="visa_end"
                  value={formData.visa_end}
                  onChange={handleDateChange}

                />
              </div>
              <div className="grid-item">
                <label htmlFor="location">Current Location:</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  required
                />
                {locationError && (
                  <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                    {locationError}
                  </div>
                )}
              </div>
              <div className="grid-item">
                <label htmlFor="preferred_location">Preferred Location:</label>
                <input
                  type="text"
                  id="preferred_location"
                  name="preferred_location"
                  value={formData.preferred_location}
                  onChange={handleChange}
                  required
                />
                {preferredlocationError && (
                  <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                    {preferredlocationError}
                  </div>
                )}
              </div>

              <div className="grid-item">
                <label htmlFor="rtg">RTG:</label>
                <select
                  id="rtg"
                  name="rtg"
                  value={formData.rtg}
                  onChange={handleSelectChange}
                >
                  <option value="">Select RTG</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>

              </div>
              <  div className="grid-item">
                <label htmlFor="ssn">SSN:</label>
                <input
                  type="text"
                  id="ssn"
                  name="ssn"
                  value={formData.ssn}
                  onChange={handleChange}
                />
                {ssnError && (
                  <div
                    style={{ color: "red", fontSize: "14px" }}
                    className="error-message"
                  >
                    {ssnError}
                  </div>
                )}
              </div>
              <div className="grid-item">
                <label htmlFor="job_domain">Job Domain:</label>
                <input
                  type="text"
                  id="job_domain"
                  name="job_domain"
                  value={formData.job_domain}
                  onChange={handleChange}
                />
              </div>
              <div className="grid-item">
                <label htmlFor="skills">Preferred Tech Stack:</label>
                <input
                  type="text"
                  id="skills"
                  name="skills"
                  value={formData.skills}
                  onChange={handleChange}
                />
              </div>
              <div className="grid-item">
                <label htmlFor="primary_marketer">Marketer:</label>
                <select
                  id="primary_marketer"
                  name="primary_marketer"
                  value={formData.primary_marketer}
                  onChange={handleMarketerChange}
                  required
                >
                  <option value={null}>Select a marketer</option>
                  {PrimaryMarketerList.map((marketer) => (
                    <option value={marketer.id}>
                      {marketer.full_name}
                    </option>
                  ))}
                </select>
                {marketerError && (
                  <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                    {marketerError}
                  </div>
                )}
              </div>
              <div className="grid-item">
                <label htmlFor="in_pool">In Pool:</label>
                <select
                  id="in_pool"
                  name="in_pool"
                  value={formData.in_pool}
                  onChange={handleSelectChange}
                >
                  <option value="" >Select Pool</option>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>

                </select>
              </div>
              <div className="grid-item">
                <label htmlFor="marketing_start">Marketing Start:</label>
                <input
                  type="date"
                  id="marketing_start"
                  name="marketing_start"
                  value={formData.marketing_start}
                  onChange={handleDateChange}

                />
              </div>
              <div className="grid-item">
                <label htmlFor="marketing_end">Marketing End:</label>
                <input
                  type="date"
                  id="marketing_end"
                  name="marketing_end"
                  value={formData.marketing_end}
                  onChange={handleDateChange}
                />
                {marketingDateError && (
                  <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                    {marketingDateError}
                  </div>
                )}
              </div>
              <div className="grid-item">
                <label htmlFor="recruiter">Recruiter:</label>
                <select
                  id="recruiter"
                  name="recruiter"
                  value={formData.recruiter}
                  onChange={handleRecruiterChange}
                >
                  <option value={null} >Select a recruiter</option>
                  {RecruiterList.map((recruiter) => (
                    <option value={recruiter.id}>
                      {recruiter.full_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid-item">
                <label htmlFor="rateType">Rate Type:</label>
                <select
                  id="rateType"
                  name="rateType"
                  value={formData.rateType}
                  onChange={handleSelectChange}
                  required
                >
                  <option value="">Select Rate Type</option>
                  {rate_choices.map((rate) => (
                    <option key={rate.id} value={rate.id}>
                      {rate.value}
                    </option>
                  ))}
                </select>
                {RateValueError && (
                  <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                    {RateValueError}
                  </div>
                )}
              </div>
              {formData.rateType && (
                <div className="grid-item">
                  <label htmlFor="rate">Rate Value:</label>
                  <input
                    type="number"
                    id="rate"
                    name="rate"
                    value={formData.rate}
                    onChange={handleChange}
                    required
                  />
                </div>
              )}

              <div className="grid-item">
                <label htmlFor="status">Status:</label>
                <select
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleSelectChange}
                >
                  <option value="">Select Candidate Status</option>
                  {status_choices.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.value}
                    </option>
                  ))}
                </select>
                {StatusError && (
                  <div style={{ color: 'red', fontSize: '14px' }} className="error-message">
                    {StatusError}
                  </div>
                )}
              </div>
              {showAdditionalFilters && formData.status === 'in_marketing' && (
              <div>
                <div className="grid-item">
                  <label htmlFor="bench_status">Bench Status:</label>
                  <select
                    id="bench_status"
                    name="bench_status"
                    value={formData.bench_status}
                    onChange={handleChange}
                  >
                    <option value="">Select Bench Status</option>
                    {bench_choices.map((bench) => (
                      <option key={bench.id} value={bench.id}>
                        {bench.value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}


              <div className="grid-item">
                <label htmlFor="comments">Comments:</label>
                <input
                  type="text"
                  id="comments"
                  name="comments"
                  value={formData.comments}
                  onChange={handleChange}
                />
              </div>
              <div className="grid-item">
                <label htmlFor="zip_code">Zip Code:</label>
                <input
                  type="text"
                  id="zip_code"
                  name="zip_code"
                  value={formData.zip_code}
                  onChange={handleChange}
                />
              </div>

            </div>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );

}


export default UpdateCandidatePage;
