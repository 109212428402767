import React from "react";
import "./LeadUpdation.css";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { FlashMessage } from "../../flashmessage/FlashMessage";
import Sidebar from "../../sidebar/Sidebar";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useSession from "../../session/useSession";

export const LeadUpdation = () => {
  useSession();
  const { lead_id } = useParams();
  const countryList = ["USA", "India", "Russia"];
  const requirementTypeList = [
    { id: "C2C", value: "C2C" },
    { id: "W2", value: "W2" },
    { id: "full_time", value: "Full time" }
  ]
  const [company, setCompany] = useState([]);
  const [description, setDescription] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [requirementType, setRequirementType] = useState(null);
  const [skills, setSkills] = useState(null);
  const [job_title, setJob_title] = useState(null);
  const [location, setLocation] = useState(null);
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const jsonData = useSelector((state) => state.user);
  const history = useHistory();
  const [leadData, setleadData] = useState([])
  const [selectedRequirementsType, setSelectedRequirementsType] = useState('');
  const [selectedVendor, setselectedVendor] = useState('');
  const [selectedCountry, setselectedCountry] = useState('');

  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: false,
    openRequirements: true,
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/marketing/company/`;
      if (jsonData?.result.token) {
        axios
          .get(apiUrl, {
            headers: {
              Authorization: `Token ${jsonData?.result.token}`,
            },
          })
          .then((response) => {
            setCompany(response.data.results);
          })
          .catch((error) => console.error("Error fetching company data:", error));
      }
    }
  }, [jsonData, isMounted]);

  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/marketing/lead/`;
      if (jsonData?.result.token) {
        axios
          .get(apiUrl, {
            params: {
              lead_id: lead_id,

            },
            headers: {
              Authorization: `Token ${jsonData?.result.token}`,
            },
          })
          .then((response) => {
            const leadResult = response.data.results[0];
            setleadData(response.data.results);
            setDescription(response.data.results[0].job_desc)
            setSelectedCompanyId(response.data.results[0].company_id)
            setSelectedRequirementsType(response.data.results[0].requirements_type)
            setSkills(response.data.results[0].skill)
            setLocation(response.data.results[0].location)
            setJob_title(response.data.results[0].job_title)
            setselectedCountry(response.data.results[0].country)
            axios
              .get(`${process.env.REACT_APP_API_URL}/marketing/company/${response.data.results[0].company_id}`, {
                headers: {
                  Authorization: `Token ${jsonData?.result.token}`,
                },
              })
              .then((response) => {
                setselectedVendor(response.data.result);
              })
              .catch((error) => console.error("Error fetching company data:", error));
            })
            .catch((error) => console.error("Error fetching lead data:", error));
          }
    
    }
    }, [jsonData, isMounted]);

  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000);
  };

  const handleSubmit = () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/marketing/lead/${lead_id}/`;
    axios.put(apiUrl, {
      job_desc: description,
      country: selectedCountry,
      location: location,
      job_title: job_title,
      skill: skills,
      requirement_type: selectedRequirementsType,
      vendor_company: selectedCompanyId,
    },
      {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        },
      }).then(response => {
        displayFlashMessage('Lead Updated Successfully', 'success');
        setTimeout(() => {
          history.push(`/LeadUpdation/requirements`);
        }, 1000);
      }).catch(error => {
        displayFlashMessage(error.response.data.result, 'error');
      })

  }

  const handleDescriptionChange = (e) => {
    const selectedDescription = e.target.value;
    setDescription(selectedDescription);
  }

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setselectedCountry(selectedCountry)
  }

  const handleCompanyChange = (e) => {
    const parsedValue = JSON.parse(e.target.value);
    const val = parsedValue.name;
    setSelectedCompanyId(parsedValue.id);
    setselectedVendor(parsedValue);

  };
  const handleRequirementChange = (e) => {
    const selectedRequirement = e.target.value;
    setRequirementType(selectedRequirement);
    setSelectedRequirementsType(selectedRequirement);
  };

  const handleSkillsChange = (e) => {
    const selectedSkills = e.target.value;
    setSkills(selectedSkills);
  }

  const handleJobTitleChange = (e) => {
    const selectedJobTitle = e.target.value;
    setJob_title(selectedJobTitle)
  }

  const handleLocationChange = (e) => {
    const selectedLocation = e.target.value;
    setLocation(selectedLocation);
  }

  return (
    <div className="create-lead-page bg-neutral-900 text-white">
      <div className="sidebar-create-lead">
        <Sidebar set_map={set_map} />
      </div>
      <div className="w-full px-4 py-4">
      <header className="flex flex-col font-bold tracking-widest uppercase bg-neutral-900 pl-5 w-full">
                <h1 className="z-10 justify-center items-start px-4 py-2 bg-indigo-950 rounded-[43px] max-md:px-5 max-md:max-w-full text-white mt-4">
                  Requirements
                </h1>
              </header>
        <div class="container mt-5">
          {flashMessage && (
            <FlashMessage message={flashMessage} type={flashMessageType} />
          )}
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <label for="exampleFormControlTextarea1" class="form-label">
                Job Description
              </label>
              <textarea
                class="form-control Textarea bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                id="exampleFormControlTextarea1"
                rows="3"
                required
                value={description}
                onChange={handleDescriptionChange}
              ></textarea>
            </div>
            <div class="col-md-3 col-xs-12">
              <div className="d-flex flex-column">
                <div>
                  <label for="exampleFormControlSelect" class="form-label ">
                    Country
                  </label>
                  <select
                    class="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    aria-label="Default select example"
                    onChange={handleCountryChange}
                    value={selectedCountry}
                  >
                    <option value={""} disabled>Select Country</option>
                    {countryList.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                </div>
                <div className="mt-3">
                  <label for="exampleFormControlSelect" class="form-label ">
                    Job Location
                  </label>
                  <input
                    type="text"
                    class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    id="exampleFormControlInput1"
                    required
                    value={location}
                    onChange={handleLocationChange}
                  />
                </div>
                <div className="mt-3">
                  <label for="exampleFormControlSelect" class="form-label ">
                    Job Title
                  </label>
                  <input
                    type="text"
                    class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    id="exampleFormControlInput1"
                    required
                    value={job_title}
                    onChange={handleJobTitleChange}
                  />
                </div>
              </div>
            </div>
            <div class="col-md-3 col-xs-12">
              <div className="">
                <label for="exampleFormControlSelect" class="form-label ">
                  Skills
                </label>
                <input
                  type="text"
                  class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                  id="exampleFormControlInput1"
                  required
                  value={skills}
                  onChange={handleSkillsChange}
                />
              </div>
              <div className="mt-3">
                <label for="exampleFormControlSelect" class="form-label ">
                  Requirement Type
                </label>
                <select class="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white" aria-label="Default select example" onChange={handleRequirementChange} value={selectedRequirementsType}>
                  <option value={""} disabled> Select Requirements Type</option>
                  {requirementTypeList.map((item) => (
                    <option value={item.id}>{item.value}</option>
                  ))}
                </select>
              </div>
              <div className="mt-3">
                <label for="exampleFormControlSelect" class="form-label ">
                  Vendor Company
                </label>
                <select class="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white" aria-label="Default select example" value={JSON.stringify(selectedVendor)} onChange={handleCompanyChange}>
                  <option value={""} disabled>Select Vendor Company</option>
                  {company.map((item) => (
                    <option value={JSON.stringify(item)}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-5 d-flex justify-content-between gap-4 px-3">
          <button
            type="button p-2"
            // class="btn btn-success BUTTON1"
            className="leadButtons flex gap-1 px-3 py-2.5 text-white rounded-xl bg-[#292256]"
            onClick={handleSubmit}
            disabled={
              !description || !selectedCountry || !location || !job_title
              || !skills || !selectedRequirementsType || !selectedCompanyId
            }
          >
            Update
          </button>

        </div>
      </div>
    </div>
  );
};
