import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import Table from "react-bootstrap/Table";
import axios from 'axios';
import "./Logs.css";
import Sidebar from "../sidebar/Sidebar";
import useSession from "../session/useSession";
import SearchIcon from "../assets/SearchIcon.svg"
import RightArrow from "../assets/RightArrow.svg"
import LeftArrow from "../assets/LeftArrow.svg"

export const Logs = () => {
  useSession();
  const [isMounted, setIsMounted] = useState(false);
  const jsonData = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [endIndex, setEndIndex] = useState(0);
  const [logs, setLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 10;

  const set_map = {
    showDashboard: true,
    showCandidate: false,
    showMarketing: false,
    showResume: false,
    openMy: false, 
    openGlobal: false,
    openLogs: true,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: false,
    openRequirements: false,
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if(isMounted) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/vms_logs/`;
      if (jsonData?.result.token) {
        axios
          .get(apiUrl, {
            params: {
              page : currentPage,
              page_size : itemsPerPage,
              search: searchTerm
            },
            headers: {
              Authorization: `Token ${jsonData?.result.token}`,
            },
          })
          .then((response) => {
            setLogs(response.data.results); 
            setEndIndex(response.data.total_count);
          })
          .catch((error) => console.error("Error fetching logs data:", error));
      }
    }
  }, [jsonData, currentPage, isMounted, searchTerm]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <div className="logs-page bg-neutral-900">
      <div className="sidebar-logs">
        <Sidebar set_map={set_map} />
      </div>
      <div className="container bg-neutral-900">
        <header className="flex flex-col font-bold tracking-widest uppercase bg-neutral-900 text-zinc-100 max-md:max-w-full pl-5">
                <h1 className="z-10 justify-center items-start px-4 py-2 bg-[#292256] rounded-[43px] max-md:px-5 max-md:max-w-full text-white mt-4">
                  VMS Logs
                </h1>
              </header>
        
        <div className="flex flex-col justify-center text-fuchsia-50 text-opacity-50 bg-neutral-900">
        <div className="searchbar flex justify-center items-start bg-indigo-950 rounded-[43px] border
        align-items-center
        ">
          <img
            loading="lazy"
            src={SearchIcon}
            alt="Search icon"
            className="aspect-square ml-4"
          />
          
          <input
                  type="text"
                  placeholder="Search by Name"
                  className="bg-transparent border-none mb-2"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value)
                    setCurrentPage(1)
                  }}
          />
      </div>
      <div>

      </div>
    </div>
        <div className="logstable bg-neutral-900 font-bold ">
        <Table borderless="1" className="text-white bg-opacity-95 borderprob">
          <thead className="bg-indigo-500">
            <tr>
              <th>Employee Name</th>
              <th>Action Type</th>
              <th>Model Name</th>
              <th>Object Id</th>
              <th>Action Time</th>
            </tr>
          </thead>
          <tbody className="bg-indigo-950">
            {logs.map((item, i) => (
              <tr key={i}>
                <td>{item.user.full_name}</td>
                <td>{item.action_type}</td>
                <td>{item.model_name}</td>
                <td>{item.object_id}</td>        
                <td>{item.created}</td>       
              </tr>
            ))}
          </tbody>
        </Table>
        </div>
        
        {/* <div className="logs_pagination">
          <button className="paginationbuttons"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
          <img
          src={LeftArrow}
          alt="<"
          className="h-5"/>
          </button>
          <span className="text-white spanss"><b>{currentPage}</b></span>
          <button className="paginationbuttons"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={endIndex <= (itemsPerPage * (currentPage-1)) + logs.length}
          >
          <img
          src={RightArrow}
          alt=">"
          className="h-5"
        />
          </button>
        </div> */}
        <div className="flex flex-row justify-end items-center space-x-2 mb-4">
              <button
                className="bg-[#171111] text-white hover:bg-[#171111] disabled:bg-[#171111] text-xl"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
            <img
              src={LeftArrow}
              alt=">"
              className="h-5"
            />              
        </button>
              <span>
                <p className="text-white text-2xl pt-2">{currentPage}</p>
              </span>
              <button
                className="bg-[#171111] hover:bg-[#171111] disabled:bg-[#171111] text-white text-xl"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={endIndex <= (itemsPerPage * (currentPage - 1)) + logs.length}
              >
              <img
                  src={RightArrow}
                  alt=">"
                  className="h-5"/>
         </button>
            </div>
      </div>
    </div>
    
  );
  
};
export default Logs;

