import React, { useState } from 'react';
import Sidebar from '../sidebar/Sidebar';
import axios from 'axios';
import './DocumentUpload.css';
import { useSelector } from 'react-redux';
const DocumentUpload = () => {
  const [resume, setResume] = useState(null);
  const [job_description, setJobDescription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [analyseReady, setAnalyseReady] = useState(false);
  const [score, setScore] = useState(null);
  const jsonData = useSelector((state) => state.user);
  const [missingKeywords, setMissingKeywords] = useState([]);

  const handleResumeChange = (event) => {
    setResume(event.target.files[0]);
  };

  const handleJobDescriptionChange = (event) => {
    setJobDescription(event.target.files[0]);
  };


  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: false,
    showResume: true,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: false,
    openRequirements: false,
    openResume:true,
  };

  const handleUpload = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('resume', resume);
      formData.append('job_description', job_description);
      formData.append('id', jsonData.result.id);

      console.log(jsonData)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/resume_analyzer/process_resume/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${jsonData?.result.token}`,
          },
        }
      );

      const { data } = response;
      setScore(data.score);
      setMissingKeywords(data.missing); 
      setAnalyseReady(true);
    } catch (error) {
      console.error('Error uploading documents:', error.message);
    } finally {
      setLoading(false);
    }
   
  };
 

  

  
  return (
    <div>
      <div className='sidebar-documetUpload'>
        <Sidebar set_map={set_map} />
      </div>
        <div className='resumeAnalyzerHeading'>
      <h1 className='documentHeading'> Resume Analyzer</h1>
      </div>
      <div className='add-documetUpload-container'>
        <h1 className='color-white'>
          Upload Files
        </h1>
       <h5 className='StyleUploadDocument'>Resume</h5>
        <input type="file" onChange={handleResumeChange} label_visibility="hidden" accept=".pdf,.docx"/><br></br>
        <p className="mt-1">
        <span style={{color:'red'}}>*</span>Only PDF and DOCX files are supported
            </p>
       <br></br> <h5 className='StyleUploadDocument'>Job description</h5>
        <input type="file" onChange={handleJobDescriptionChange} label_visibility="hidden" accept=".pdf,.docx" />
        <p className="mt-1">
        <span style={{color:'red'}}>*</span>Only PDF and DOCX files are supported
            </p>
        <br>
        
        </br><button onClick={handleUpload} disabled={loading}>
          Upload Documents
        </button>

        {loading && <p>Loading...</p>}
        {analyseReady && (
          <div>
            <p>Your analysis is ready on the screen!</p>
            <p>Score: {score} %</p>
            <p style={{color:'red'}}>Missing Keywords: {missingKeywords.join(', ')}</p>
          </div>
        )}
      </div>
    </div>
  );

        };
export default DocumentUpload;
