import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import './UpdateScreening.css';
import Sidebar from '../sidebar/Sidebar';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlashMessage } from '../flashmessage/FlashMessage';
import useSession from "../session/useSession";
import { faL } from '@fortawesome/free-solid-svg-icons';
const UpdateScreening = () => {
  useSession();
  const { id } = useParams();
  const history = useHistory();
  const test_choices = [
    { id: "vendor", value: "Vendor" },
    { id: "account_manager", value: "Account Manager" },
    { id: "client_hiring", value: "Client Hiring" },
    { id: "Technical", value: "Technical" },

  ]
  const screening_status_choice = [
    { id: "failed", value: "Failed" },
    { id: "passed", value: "Passed" },
    { id: "didn't_happen", value: "Didn't Happen" },
    { id: "scheduled", value: "Scheduled" },
  ]

  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openScreening: false,
    openRequirements: false,
  };

  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const [guestValue, setGuestValue] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [screeningType, setScreeningType] = useState('');
  const [calling_details, setCallDetails] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [additional_details, setAdditionalDetails] = useState('');
  const [status, setstatus] = useState('');
  const [GuestList, setGuestList] = useState([]);
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const [feedback_remark, setFeedbackRemark] = useState('');
  useEffect(() => {
    setIsMounted(true);
  }, []);
  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000);
  };
  const [validationErrors, setValidationErrors] = useState({
    screeningType: '',
    job_title: '',
    guest: '',
    start_time: '',
    end_time: '',
    calling_details: '',
    additional_details: '',
    status: '',

  });
  const validateForm = () => {
    const errors = {
      screeningType: '',
      jobTitle: '',
      start_time: '',
      end_time: '',
      status: '',
    };
    if (!screeningType) {
      errors.screeningType = 'Screening Type is required';
    }
    if (!jobTitle) {
      errors.jobTitle = 'Feedback Status is required';
    }
    if (!startTime) {
      errors.start_time = 'Start Time is required';
    }
    if (!endTime) {
      errors.end_time = 'End Time is required';
    } else if (endTime <= startTime) {
      errors.end_time = 'End Time must be after Start Time';
    }

    if (!status) {
      errors.status = 'Screening Status is required';
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => !error);
  };

  const handleGuestChange = (e) => {
    const { options } = e.target;
    const selectedGuestIds = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => parseInt(option.value));

    setGuestValue(selectedGuestIds);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'screening_type':
        setScreeningType(value);
        break;
      case 'job_title':
        setJobTitle(value);
        break;
      case 'calling_details':
        setCallDetails(value);
        break;
      case 'additional_details':
        setAdditionalDetails(value);
        break;
      case 'status':
        setstatus(value);
        break;
      case 'feedback_remark':
        setFeedbackRemark(value);
        break;
      default:
        break;
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;

    if (name === 'start_time' || name === 'end_time') {
      parsedValue = value ? new Date(value + 'Z') : null;
    }

    switch (name) {
      case 'start_time':
        setStartTime(parsedValue);
        break;
      case 'end_time':
        setEndTime(parsedValue);
        break;
      default:
        break;
    }
  };


  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlUsers = `${process.env.REACT_APP_API_URL}/employee/all_user_list/`;

      axios.get(apiUrlUsers, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        setGuestList(response.data.results.marketers);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [history, jsonData, isMounted]);

  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlUsers = `${process.env.REACT_APP_API_URL}/marketing/screenings/${id}`;
      axios.get(apiUrlUsers, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        const testData = response.data;
        console.log(testData)
        const selectedGuestIds = testData.guest;
        setGuestValue(selectedGuestIds);
        setScreeningType(testData.screening_type);
        setCallDetails(testData.calling_details);
        setAdditionalDetails(testData.additional_details);
        setStartTime(new Date(testData.start_time));
        setEndTime(new Date(testData.end_time));
        setstatus(testData.status);
        setJobTitle(testData.job_title);
        setFeedbackRemark(testData.feedback_remark);
        console.log(startTime)
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
      

    }
  }, [history, jsonData, isMounted]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const apiUrlUpdateScreening = `${process.env.REACT_APP_API_URL}/marketing/screenings/${id}/`;
    const updateData = {
      id: id,
      screening_type: screeningType,
      guest: guestValue[0],
      status: status,
      start_time: startTime,
      end_time: endTime,
      additional_details: additional_details,
      calling_details: calling_details,
      feedback_remark: feedback_remark,
      job_title: jobTitle,
    };
    try {
      const response = await axios.put(apiUrlUpdateScreening, updateData, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        },
      });
      displayFlashMessage('Screening Updated Successfully', 'success');
      setTimeout(() => {
        history.push(`/screening`);
      }, 1000);
    } catch (error) {
      console.log(updateData)
      displayFlashMessage('Screening Update Failed', 'error');
      console.error('Error updating Screening:', error);
    }
  };


  return (
    <div className="add-test-page">
      <div>
        <Sidebar set_map={set_map} />
      </div>
      <div className="test-form-container  bg-[#171111] text-white">
        {flashMessage && (
          <FlashMessage message={flashMessage} type={flashMessageType} />
        )}
        <div className="bg-[#292256] rounded-l-full ml-14 mt-3">
          <h1 className="text-2xl text-white font-semibold pl-6 py-2">Edit Screening Details</h1>
        </div>
        <form onSubmit={handleUpdate}>
          <div className="grid grid-cols-2 mx-20 my-10 gap-5">
            <div className="form-group">
            <label>Screening Type:</label>
              <select placeholder='Screening Type' name="screening_type" value={screeningType} onChange={handleChange} >
                <option value="">Select Screening Type</option>
                {test_choices.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.screeningType}</div>
            </div>
            <div className="form-group" >
            <label>Job Title:</label>
              <textarea
                placeholder='Job title'
                name="job_title"
                value={jobTitle}
                onChange={handleChange}
                rows={1}
              />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.job_title}</div>
            </div>
            <div className="form-group">
            <label>Guest:</label>
              <select
                placeholder='Guest'
                id="guest"
                name="guest"
                value={guestValue}
                onChange={handleGuestChange}
              >
                <option value="" disabled>Select a guest</option>
                {GuestList.map((guest) => (
                  <option key={guest.id} value={guest.id}>
                    {guest.full_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
            <label>Start Time:</label>
              <input placeholder='Start Time' type="datetime-local" name="start_time" value={startTime instanceof Date ? startTime.toISOString().slice(0, 16) : ''} onChange={handleDateChange} />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.start_time}</div>
            </div>
            <div className="form-group">
            <label>End Time:</label>
              <input placeholder='End Time' type="datetime-local" name="end_time" value={endTime instanceof Date ? endTime.toISOString().slice(0, 16) : ''} onChange={handleDateChange} />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.end_time}</div>
            </div>
            <div className="form-group">
            <label>Calling Details:</label>
              <textarea
                placeholder='Calling Details'
                name="calling_details"
                value={calling_details}
                onChange={handleChange}
                rows={1}
              />
            </div>
            <div className="form-group">
            <label>Additional Details:</label>
              <textarea
                placeholder='Additional Details'
                name="additional_details"
                value={additional_details}
                rows={1}
                onChange={handleChange}
              />
            </div>
            <div className="form-group" >
            <label>Screening Status:</label>
              <select placeholder='Status' name="status" value={status} onChange={handleChange}>
                <option value="">Select Screening Status</option>
                {screening_status_choice.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.status}</div>
            </div>
            <div className="form-group">
            <label>Remarks:</label>
              <textarea
                placeholder='Remarks'
                name="feedback_remark"
                value={feedback_remark}
                onChange={handleChange}
                rows={1}
              />
            </div>
          </div>
          <button type="submit" className='ml-20 mb-4 bg-[#b4a4f5] text-slate-800 font-medium hover:bg-[#6c48fd] hover:text-white'>Update</button>
        </form>
      </div>
    </div>

  );

};
export default UpdateScreening;