import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import './Consultantfilter.css';

const ConsultantFilter = ({ onSelect, onClearSearchTerm, onConsultantChange }) => {
  const [consultants, setConsultants] = useState([]);
  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const jsonData = useSelector((state) => state.user);

  useEffect(() => {
    console.log(selectedConsultant)

    const apiUrlConsultants = `${process.env.REACT_APP_API_URL}/candidate/candidate/`;
    axios
      .get(apiUrlConsultants, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }

      }).then((response) => {
        setConsultants(response.data.results);
      })
      .catch((error) => {
        console.error('Error fetching consultant data:', error);
      });

  }, [selectedConsultant]);
  const handleChange = (selectedOption) => {
    setSelectedConsultant(selectedOption);
    onSelect(selectedOption?.value);
    onConsultantChange();
  };
  const handleClear = () => {
    setSelectedConsultant(null);
    onSelect(null);
    onClearSearchTerm();
    onConsultantChange();
  };

  const filteredConsultants =
    Array.isArray(consultants) &&
    consultants.filter((consultant) =>
      consultant.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const options = filteredConsultants
    ? filteredConsultants.map((consultant) => ({
      value: consultant.id,
      label: consultant.name,
    }))
    : [];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
      backgroundColor: "#171111",
      color: "#FFFFFF",
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#171111' : 'inherit',
      color: state.isFocused ? '#FFFFFF' : '#FFFFFF',
      ':hover': {
        backgroundColor: '#171111',
        color: '#FFFFFF',
      },
      cursor: 'pointer',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#FFFFFF',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#FFFFFF',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#171111",
    }),
  };

  return (
    <Select
      value={selectedConsultant}
      onChange={handleChange}
      options={options}
      isSearchable
      onInputChange={(inputValue) => setSearchTerm(inputValue)}
      placeholder="Search for a consultant"
      className="my-4 ml-5 placeholder-white w-[180px] text-white"
      styles={customStyles}
    />
  );
};

export default ConsultantFilter;
