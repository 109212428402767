import React from "react";
import PropTypes from "prop-types";
import './FlashMessage.css';

export const FlashMessage = ({ message, type }) => {
  const messageClass = type === 'error' ? 'flash-message error' : 'flash-message success';

  return (
    <div className={messageClass}>
      {message}
    </div>
  );
};

FlashMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['error', 'success']).isRequired,
};
