import React, { useEffect, useState } from 'react'
import {
    Card,
    CardBody,
    CardHeader,
    Typography,
  } from "@material-tailwind/react";
  import Chart from "react-apexcharts";
import "./DashBoard.css"
  //   import { Square3Stack3DIcon } from "@heroicons/react/24/outline";

const Charts = ({dashBoardType,chartData,type}) => {
    const [adminData,setAdminData]=useState([]);
    const [adminLabels,setAdminLabels]=useState([]);
    const [dbLabels,setDbLabels]=useState([]);
    const [dbData,setDbData]=useState([]);
    useEffect(()=>{
      if(type=="Admin"){
        chartData?.map((e)=>{
          
          setAdminLabels((prev)=>([...prev,e?.label]))
          setAdminData((prev)=>([...prev,e?.count]));
        })
      }
      else{
        console.log(chartData);
        chartData?.map((e)=>{
          setDbData((prev)=>([...prev,e?.percentage ]));
          setDbLabels((prev)=>([...prev,e?.label]))
        })
      }
    },[]);
      const chartConfig = {
        type: "bar",
        height: 320,
        series: [
          {
            name: "Number",
            data: type === "Admin" ? adminData : dbData,
            // colors: ["#F66442", "#FF739B", "#EF446A", "#557BF1", "#88EFA7"],
          },
        ],
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          title: {
            show: "",
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#F66442", "#FF739B", "#EF446A","#EF446A","#EF446A" ],
          plotOptions: {
            bar: {
              columnWidth: "40%",
              borderRadius: 2,
            },
          },
          xaxis: {
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            labels: {
              style: {
                colors: "#FFFFFF",
                fontSize: "12px",
                fontFamily: "inherit",
                fontWeight: 400,
              },
            },
            categories: type==="Admin" ? adminLabels : dbLabels,
          },
          yaxis: {
            labels: {
              style: {
                colors: "#FFFFFF",
                fontSize: "12px",
                fontFamily: "inherit",
                fontWeight: 400,
              },
            },
          },
          grid: {
            show: true,
            borderColor: "#dddddd",
            strokeDashArray: 5,
            yaxis:{
              lines:{
                show:true,
              }
            },
            xaxis: {
              lines: {
                show: false,
              },
            },

            padding: {
              top: 5,
              right: 20,
            },
          },
          fill: {
            opacity: 0.8,
          },
          tooltip: {
            theme: "dark",
          },
        },
      };
   
      
       
        return (
          <Card className='mt-0'>
            {/* <CardHeader
              floated={false}
              shadow={false}
              // color="transparent"
              className={` ${dashBoardType} flex flex-col gap-4 rounded-none md:flex-row md:items-center`}
            >
            </CardHeader> */}
            <CardBody className={`px-2 pb-0 ${dashBoardType} `}>
            Marketing Perfomance
              <Chart {...chartConfig} colors={["#F66442","#FF739B","#EF446A","#557BF1","#88EFA7"]} />
            </CardBody>
          </Card>
        );
      
}

export default Charts

