import React, { useEffect, useState } from 'react'
import Cake from '../assets/Birthday_cake_lcon.png'

const BirthdayPopup = ({name,age,birthdays}) => {
    const [cookieValue, setCookieValue] = useState(null);

    const disappearPopup=()=>{
        const expires = new Date();
        expires.setTime(expires.getTime() + 10 * 60 * 60 * 1000);
        document.cookie = `popup=false;expires=${expires.toUTCString()};path=/`;
        setCookieValue(false);
    }

    useEffect(()=>{
        const getCookie = (name) => {
            const cookieString = document.cookie;
            const cookies = cookieString.split(';');
            for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i].trim();
              if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
              }
            }
            return true;
          };
      
          const value = getCookie('popup');
          console.log(value);
          setCookieValue(value);
    },[]);

  return (
    <>
    {cookieValue===true && <div className="flex flex-col px-3.5 py-4 text-2xl font-bold text-white rounded-2xl border border-purple-50 border-solid backdrop-blur-[3.0999999046325684px] max-w-[557px]">
      <div className="flex gap-5 pr-1.5 whitespace-nowrap text-white max-md:flex-wrap max-md:max-w-full justify-between ">
        <div>Birthday</div>
        <button className='btn-close  btn-close-white' aria-label="Close"
        type="button"
        onClick={disappearPopup}
        style={{color:"white"}}>
        </button>
      </div>
      {birthdays?.map((e)=>{  
      return (<div className="flex gap-2 justify-between p-2 mt-5 rounded-lg shadow-lg bg-indigo-950 max-md:flex-wrap">
        <img
          loading="lazy"
          src={Cake}
          className="shrink-0 w-12 aspect-square"
        />
        <div className="my-auto max-md:max-w-full">
          {e?.name}<span className="text-white "> is celebrating their </span>{e?.age}
          <span className="text-white "> Birthday </span>
        </div>
      </div>)
      })}
    </div>}
    </>
  )
}

export default BirthdayPopup