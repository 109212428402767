import { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/esm/Button";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import "./marketing.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { AddVendorModal } from "../add-vendor-modal";
import Sidebar from "../../sidebar/Sidebar";
import axios from "axios";
import Select from 'react-select';
import { FlashMessage } from "../../flashmessage/FlashMessage";
import useSession from "../../session/useSession";

export const MarketingDetails = () => {
  useSession();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lead_id = searchParams?.get('lead_id');
  const company_id = searchParams?.get('company_id');
  const consultant_profile_id = searchParams?.get('consultant_profile_id');
  console.log(consultant_profile_id)
  // const fileUrl = searchParams?.get('file');
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const jsonData = useSelector((state) => state.user);
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [vendors, setVendors] = useState([]);
  const employer = ["Technman Consulting"];
  const history = useHistory();
  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: false,
    openTest: false,
    openRequirements: true,
  };
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [rate, setRate] = useState("");
  const [client, setClient] = useState("");
  const [docs, setDocs] = useState("");
  const [comments, setComments] = useState("");
  const [selectedEmployer, SetSelectedEmployer] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  // const [docs, setdocs] = useState("");
  const [submit, setsubmit] = useState(false);
  const [file, setFile] = useState(null);
  const [proofOfSubmission, setProofOfSubmission] = useState(null);
  const isDocumentShared=useRef(0);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  const handleProofOfSubmissionChange = (event) => {
    const selectedFile = event.target.files[0];
    setProofOfSubmission(selectedFile);
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted && !modalShow && jsonData) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/marketing/vendor/${parseInt(company_id)}`;
      axios.get(apiUrl, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        },
      }).then((response) => {
        setVendors(response.data.results);
      }).catch((error) => console.error("Error fetching company data:", error));
    }
  }, [jsonData, isMounted, modalShow, company_id]);


  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000);
  };

  const handlePrevious = () => {
    const queryParam = {
      company_id: company_id,
      lead_id: lead_id,
    }
    history.push({
      pathname: "/requirements/consultant-details",
      search: `?${new URLSearchParams(queryParam).toString()}`
    });
  };

  const handleDone = () => {
    if(!submit){
    setsubmit(true);

    const apiUrl = `${process.env.REACT_APP_API_URL}/marketing/submission/`;
    const formData = new FormData();
    formData.append('file_resume', file);
    formData.append('file_other',proofOfSubmission);
    formData.append('vendor_company', company_id);
    formData.append('documents_shared',docs)
    formData.append('vendor_contact', selectedVendorId);
    formData.append('lead', lead_id);
    const trimmedRate = rate.trim();
    const rateValue = trimmedRate !== '' && !isNaN(parseFloat(trimmedRate))
      ? parseFloat(trimmedRate)
      : 0;
    formData.append('rate', rateValue);
    formData.append('client', client);
    formData.append('employer', selectedEmployer);
    formData.append('consultant', consultant_profile_id);
    formData.append('marketing_email', email);
    formData.append('marketing_phone', phoneNumber);
    formData.append('documents_shared',docs);
    formData.append('comments',comments);
    if(isDocumentShared.current.checked=="false")
    formData.append("document_shared",0);
  else
  formData.append("document_shared",1);
    axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Token ${jsonData?.result.token}`,
      },
    }).then((response) => {
      displayFlashMessage('Submission Added Successfully', 'success');
      setTimeout(() => {
        history.push("/requirements");
      }, 1000);
    })
      .catch((error) => {
        displayFlashMessage(error.response.data.result, 'error');
      });
    }
  };


  const handleVendorChange = (selectedOption) => {
    setSelectedVendorId(selectedOption?.id);
  }

  const handleRateChange = (e) => {
    const selectedValue = e.target.value;
    setRate(selectedValue);
  }
  const handleDocsChange = (e) => {
    const selectedValue = e.target.value;
    setDocs(selectedValue);
  }

  const handleCommentsChange = (e) => {
    const selectedValue = e.target.value;
    setComments(selectedValue);
  }

  const handleClientChange = (e) => {
    const selectedValue = e.target.value;
    setClient(selectedValue);
  }

  const handleEmployerChange = (e) => {
    const selectedValue = e.target.value;
    SetSelectedEmployer(selectedValue);
  }

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);

    if (!isValidEmail(enteredEmail)) {
      setEmailError("Invalid email address.");
    } else {
      setEmailError("");
    }
  };

  const handlePhoneChange = (e) => {
    const enteredPhoneNumber = e.target.value;
    const cleanedPhoneNumber = enteredPhoneNumber.replace(/\D/g, '');
    const formattedPhoneNumber = formatPhoneNumber(cleanedPhoneNumber);
  
    setPhoneNumber(formattedPhoneNumber);
  
    if (!isValidPhoneNumber(cleanedPhoneNumber)) {
      setPhoneError("Invalid phone number.");
    } else {
      setPhoneError("");
    }
  };
  
  const formatPhoneNumber = (phoneNumber) => {
    const country = phoneNumber.slice(0, 3);
    const firstPart = phoneNumber.slice(3, 6);
    const secondPart = phoneNumber.slice(6, 10);
  
    return `(${country})-${firstPart}-${secondPart}`;
  };
  
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  return (
    <div className="marketing-details-page bg-neutral-900 text-white">
      <div className="sidebar-marketing-details">
        <Sidebar set_map={set_map} />
      </div>
      <div>
      <div className="bg-[#292256] rounded-l-full ml-5 mt-10">
          <h1 className="text-2xl text-white font-semibold pl-6 py-2">ADD Requirements</h1>
      </div>
      <div
          className="mt-5 d-flex justify-content-center align-items-center flex-wrap"
          style={{ fontFamily: "Signika Negative" }}
        >
          <div className="flex-col">
            <div className="header-greens flex justify-center items-center rounded-full h-[50px] w-[50px] max-md:px-5 bg-emerald-700 stroke-[3px] border-white border-solid border-[1px]">1</div>
            <span className="mx-2">Create Lead</span>
          </div>
          <hr style={{ width: "370px", height:"3px", background:"#50C878", marginBottom:"23px", marginLeft:"-26px", marginRight:"-20px"}} />
          <div className="flex-col">
            <div className="header-greens flex justify-center items-center rounded-full h-[50px] w-[50px] max-md:px-5 bg-emerald-700  stroke-[3px] border-white border-solid border-[1px]">2</div>
            <span className="Span mx-2 w-[70px]">Consultant Details</span>
          </div>
          <hr style={{ width: "375px", height:"3px", background:"#50C878", marginBottom:"23px", marginLeft:"-56px", marginRight:"-20px"}} />
          <div className="flex-col">
            <div className="header-greens flex justify-center items-center rounded-full h-[50px] w-[50px] max-md:px-5 bg-emerald-700 stroke-[3px] border-white border-solid border-[1px]">3</div>
            <span className="Span mx-2 w-[70px]">Marketing Details</span>
          </div>
        </div>

        <div class="container mt-5">
          <div class="d-flex row justify-content-around">
            <div class="col-md-4 col-xs-12">
              <div className="d-flex flex-column">
                <div>
                  <label for="exampleFormControlSelect" class="form-label ">
                    Vendor Contact:
                  </label>
                  <div className="d-flex gap-4">
                    <Select
                      onChange={handleVendorChange}
                      options={vendors}
                      isSearchable={true}
                      placeholder="Search a Vendor"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      styles={{
                        control: (provided) => ({
                            ...provided,
                            backgroundColor: '#171111',
                            color: 'white',
                            margin: 0,
                            padding: 0,
                            border: '1px solid #171111',
                        }),
                        option: (provided) => ({
                            ...provided,
                            backgroundColor: '#171111',
                            color: 'white',
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            color: 'white',
                        }),
                    }}
                    />
                    {/* <select
                    className="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    aria-label="Default select example"
                    id="consultantSelect"
                    onChange={handleVendorChange}
                    >
                    <option value="" selected>Select a consultant</option>
                    {vendors.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select> */}
                    <Button
                      onClick={() => setModalShow(true)}
                      style={{ fontSize: "12px", height: "40px", padding: "0" }}
                      disabled={!jsonData?.result.roles.some(role => ["admin", "leads", "superadmin", "marketer"].includes(role))}
                    >
                      <FontAwesomeIcon icon={faPlus} /> &nbsp; New Vendor
                    </Button>
                    <AddVendorModal
                      company_id={company_id}
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label for="exampleFormControlSelect" class="form-label ">
                    Rate
                  </label>
                  <input
                    type="number"
                    class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    id="exampleFormControlInput1"
                    onChange={handleRateChange}
                  />
                </div>
                <div className="mt-3">
                  <label for="exampleFormControlSelect" class="form-label ">
                    Documents Shared
                  </label>
                  <input
                    type="text"
                    class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    id="exampleFormControlInput1"
                    onChange={handleDocsChange}
                  />
                </div>
                <div className="mt-3">
                  <label for="exampleFormControlSelect" class="form-label ">
                    Client
                  </label>
                  <input
                    type="text"
                    class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    id="exampleFormControlInput1"
                    onChange={handleClientChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xs-12 Section">
              <div>
                <label for="exampleFormControlSelect" class="form-label ">
                  Marketing Email:
                </label>
                <input
                  type="email"
                  class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                  id="exampleFormControlInput1"
                  required
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && (
                  <div
                    style={{ color: "red", fontSize: "14px" }}
                    className="error-message"
                  >
                    {emailError}
                  </div>
                )}
              </div>
              <div className="mt-3">
                <label for="exampleFormControlSelect" class="form-label ">
                  Marketing Phone Number:
                </label>
                <input
                  type="text"
                  class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                  id="exampleFormControlInput1"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  placeholder="(XXX)-XXX-XXXX"
                />
                {phoneError && (
                  <div
                    style={{ color: "red", fontSize: "14px" }}
                    className="error-message"
                  >
                    {phoneError}
                  </div>
                )}
              </div>
              <div className="mt-3">
                <label for="exampleFormControlSelect" class="form-label ">
                  Employer
                </label>
                <select class="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white" aria-label="Default select example" onChange={handleEmployerChange}>
                  <option value="" selected>Select Employer</option>
                  {employer.map((item, i) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="mt-3">
                  <label for="exampleFormControlSelect" class="form-label ">
                    Comments
                  </label>
                  <input
                    type="text"
                    class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    id="exampleFormControlInput1"
                    onChange={handleCommentsChange}
                  />
                </div>
              {flashMessage && (
                <FlashMessage message={flashMessage} type={flashMessageType} />
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 mx-5 d-flex justify-content-between gap-5">


          <button
            type="button"
            className="flex gap-1 px-3 py-2.5 text-white rounded-xl bg-[#292256] h-10"
            style={{ marginLeft: "30px" }}
            onClick={handlePrevious}
            disabled={
              !company_id || !lead_id
            }
          >
            Previous
          </button>
          <div class="mt-0">
            <label for="formFile" class="form-label">
            <span style={{color:'red'}}>*</span>Attachments:
            </label>
            <input class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white" type="file" id="formFile" accept=".pdf, .png, .jpeg, .jpg, .doc, .docx" onChange={handleFileChange} required/>
            <p className="mt-3">
              Only PDF, PNG, JPEG and DOCS files are supported
            </p>
          </div>
          <div class="mt-0">
            <label for="formFile" class="form-label" >
            <span style={{color:'red'}}>*</span>Proof of Submission:
            </label>
            <input class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white" type="file" id="formFile" accept=".pdf, .png, .jpeg, .jpg, .doc, .docx" onChange={handleProofOfSubmissionChange} required />
            <p className="mt-3">
              Only PDF, PNG, JPEG and DOCS files are supported
            </p>
          </div>
          <button
            type="button"
            className="flex gap-1 px-3 py-2.5 text-white rounded-xl bg-[#292256] h-10"
            style={{ marginRight: "30px" }}
            onClick={handleDone}
            disabled={
              !company_id || !selectedVendorId || !lead_id 
              || !selectedEmployer || !consultant_profile_id
              || !email || !phoneNumber || !file 
            }
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};
